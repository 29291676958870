import {makeStyles} from "@material-ui/core/styles";

export const BecomeATinkererStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: "#fff2e1",
        height: "580px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

    },
    Heading: {
        fontFamily: 'Montserrat-Bold',
        fontSize: " 52px",
        fontWeight: " bold",
        paddingTop: "171px",
        marginBottom: "14px",
        color: "#11246f",

    },
    Info: {
        fontFamily: 'Montserrat-Medium',
        fontSize: "24px",
        fontWeight: " 500",
        textAlign: "center",
        margin: "auto",
        width: "60%",
        marginBottom: "10px"

    },
    Image: {
        justifyContent: "center",
        marginTop: "24px",
        width: "100%",
        display: "flex",
    },
    heroImageBlur: {
        height: "478px",
        width: "75%",
        position: "absolute",
        zIndex: "-1",
        margin: "20px 0 0 29px",
        opacity: "0.5",
        borderRadius: "14px",
        filter: "blur(13px)",
    },
    heroImage: {
        display: "block",
        height: "478px",
        borderRadius: "14px",
        width: "75%",
    },

    ImageWeb: {
        display: "flex",
        justifyContent: "center",
        width:"100%",
        [theme.breakpoints.only('sm')]: {
            display: "none",
        },
        [theme.breakpoints.only('xs')]: {
            display: "none",
        },
    },
    ImageTab: {
        display: "none",
        [theme.breakpoints.only('sm')]: {
            display: "block",
        },
        [theme.breakpoints.only('xs')]: {
            display: "none",
        },
    },
    ImageMobile: {
        display: "none",
        [theme.breakpoints.only('sm')]: {
            display: "none",
        },
        [theme.breakpoints.only('xs')]: {
            display: "block",
        },
    },

    [theme.breakpoints.only('sm')]: {
        main: {
            marginBottom: "0px",
        },
        Heading: {
            fontSize: "30px",
        },
        Image: {
            marginTop: "47px",
        },
        Info: {
            fontSize: "18px",
            width: "80%",
            margin: "0",
        },
        heroImageBlur: {
            height: "416px",
            width: "702px",
        },
        heroImage: {
            height: "416px",
            width: "702px",
        },
    },
    [theme.breakpoints.only('xs')]: {
        main: {
            height: "353px",
        },
        Heading: {
            fontSize: "25px",
            paddingTop: "90px",
            marginBottom: "6px",
        },
        Info: {
            fontSize: "15px",
            width: "90%",
            margin: "2%",
            marginBottom: "17px",
        },
        heroImageBlur: {
            height: "236px",
            width: "300px",
        },
        heroImage: {
            height: "226px",
            width: "340px",
        },
    }

}))

export const whyChooseTinkerStyles = makeStyles((theme) => ({
    Main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30%",
        marginBottom: "50px"
    },
    Heading: {
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: 'Montserrat-Bold',
        color: "#11246f",
        marginBottom: "6.5%",
        display: "flex",
    },
    Cards: {
        display: "flex",
        width: "85%",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
    },
    Card1: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "341.6px",
        height: "326.4px",
        marginTop: "2%",
        marginBottom: "10%",
        backgroundColor: "#fff",
        borderRadius: " 11.4px",
        boxShadow: "0 5.7px 34.2px 0 rgba(38, 45, 118, 0.08)",
    },
    Icons: {
        position: "relative",
        bottom: "44px",
    },
    Line: {
        width: "160px",
        height: "2px",
        flexGrow: "0",
        backgroundColor: "#fff2e1",
    },
    Title: {
        fontSize: "25px",
        marginBottom: "2.5%",
        fontWeight: "bold",
        fontFamily: 'Montserrat-Bold',
        color: "#11246f",
        position: "relative",
        bottom: "21px",
        width: "78%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "61px"
    },
    Info: {
        textAlign: "center",
        fontSize: "18px",
        fontWeight: "500",
        width: "83%",
        lineHeight: "1.64",
        fontFamily: 'Montserrat-Medium',
        color: "#000",
        position: "relative",
        top: "33px",
    },
    Icon: {
        width: "76px",
        height: "76px",
    },
    IconArrow: {
        width: "40px",
        height: "40px",
        position: "relative",
        bottom: "16px",
        right: "9px",
    },
    [theme.breakpoints.only('sm')]: {
        Main: {
            marginTop: "39%",
        },
        Heading: {
            fontSize: "25px",
            marginBottom: "91px",
        },
        Cards: {
            width: "100%",
        },
        Card1: {
            width: "329.9px",
            height: "315.2px",
        },
        Icon: {
            width: "73.3px",
            height: "73.3px",
        },
        Title: {
            fontSize: "24.1px",
        },
        IconArrow: {
            bottom: "22px",
            right: "6px",
        },
    },
    [theme.breakpoints.only('xs')]: {
        Main: {
            marginTop: "54%",
            marginBottom: "0",
        },
        Heading: {
            flexWrap: "wrap",
            width: "90%",
            fontSize: "18px",
            alignItems: "baseline",
            justifyContent: "center",
            marginBottom: "47px",
        },
        Cards: {
            width: "100%",
        },
        Card1: {
            width: "256px",
            height: "244.8px",
            marginBottom: "18%",
        },

        Icons: {
            bottom: "30px",
        },
        Icon: {
            width: "57px",
            height: "57px",
        },
        Title: {
            fontSize: "18px",
            width: "50%",
            margin: "0",
        },
        Info: {
            fontSize: "13px",
            top: "20px"
        },
        Line: {
            width: "120px",
            height: "1.5px",
            margin: "0",
            position: "relative",
            top: "-8px"
        },
        IconArrow: {
            bottom: "5px",
            right: "1px",
            width: "25px",
            height: "25px",

        },
    }
}))


export const InternshipProgramsStyles = makeStyles((theme) => ({
    InternshipMain: {
        display: "flex",
        flexDirection: "row",
        height: "286px",
        width: "85%",
        margin: "90px 0 200px",
    },
    paragraph: {
        margin: "1%",
        display: "flex",
        flexDirection: "column",
    },
    InternshipMainReverse: {
        flexDirection: "row-reverse",
        width: "100%",
        padding: "0",
        margin: "100px 0 150px",
    },
    InternshipProgramsText: {
        display: "flex",
        flexDirection: "column",
        fontFamily: 'Montserrat-Medium',
        margin: "auto",
        marginBottom: "10%",
    },
    InternshipProgramsTextReverse: {
        height: "fit-content",
        fontFamily: 'Montserrat-Medium',
        margin: "auto",
        marginRight: "50px",
        marginBottom: "5%",

    },

    InternshipProgramsVideo: {
        '& .react-3d-carousel': {
            height: "234px",
            width: " 822px",
        },

        '& .slider-left > div': {
            border: "none !important",

        },
        '& .slider-right > div': {
            border: "none !important",
        },
        '& .slider-single-content': {
            borderRadius: "15.2px",
            boxShadow: "0 3px 8.3px 0 rgba(0, 0, 0, 0.15)",
            [theme.breakpoints.only('xs')]: {
                height: "182px",
                left: "-6px !important",
                width: "337px !important",
            },
        },
        '& .slider-single-content > img ': {
            borderRadius: "15.2px",
            cursor: "pointer",
            [theme.breakpoints.only('xs')]: {
                height: "182px",
                width: "337px !important",
            },
        },
        '& .preactive': {
            [theme.breakpoints.only('xs')]: {
                display: "none",
            },
        },
        '& .proactive': {
            [theme.breakpoints.only('xs')]: {
                display: "none",
            },
        },
        '& .active': {
            [theme.breakpoints.only('xs')]: {
                left: "-42px !important",
            },
        },
        '& .slider-left': {
            padding: "1px 44px !important",

        },
        '& .slider-right': {
            left: "100% !important",
            padding: "1px 36px !important",
        },
    },
    InternshipTitle: {
        fontSize: "30px",
        fontWeight: "bold;",
        fontFamily: 'Montserrat-Bold',
        lineHeight: "1.51",
        textAlign: "left",
        marginBottom: "7px",
        color: "#11246f",
    },
    InternshipText: {
        width: " 485px",
        height: " 125px",
        marginBottom: "0",
        fontFamily: 'Montserrat-Regular',
        fontSize: "18px",
        textAlign: "left",
        fontWeight: "500",
        lineHeight: "1.8",
        color: " #000",
    },
    InternshipTextReverse: {
        width: " 485px",
        height: " 155px",
        marginBottom: "0",
        textAlign: "left",
        fontFamily: 'Montserrat-Regular',
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "1.51",
        color: " #000",
    },

    Icon: {
        width: "60px",
        height: "60px",
        objectFit: "contain",
        flexDirection: "column",
        marginRight: "20px",
    },
    iTinkerSummerText: {
        textDecoration: "underline",
        fontFamily: 'Montserrat-Medium',
        fontSize: "18px",
        fontWeight: "500",
        color: "#252641",
    },
    PlayButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        marginTop: "14%"
    },
    [theme.breakpoints.only('sm')]: {
        InternshipMain: {
            justifyContent: "center",
            width: "100%",
            padding: "0",
            display: "block",
            marginBottom: "400px",
        },
        InternshipMainReverse: {
            width: "100%",
            padding: "0",
            display: "block",
            marginTop: "70px",
            marginBottom: "400px",
        },
        InternshipProgramsText: {
            marginLeft: "24px",
            marginRight: "19px",
            marginBottom: "4%",
            height: "180px"
        },
        InternshipProgramsTextReverse: {
            marginLeft: "24px",
            marginRight: "19px",
            marginBottom: "0",
        },
        InternshipProgramsVideo: {
            width: "100%",
            marginTop: "70px",
            marginLeft: "0px",
            '& .react-3d-carousel': {
                height: "261px",
                width: " 768px",
            },

        },
        InternshipTitle: {
            fontSize: "25px",
            marginBottom: "7%",
        },
        InternshipText: {
            fontSize: "18px",
            width: " 95%",
            textAlign: "left"
        },
        InternshipTextReverse: {
            fontSize: "18px",
            width: " 95%",
            textAlign: "left",
            height: "124px",
        },
        Icon: {
            width: "52px",
            height: "52px",
            marginRight: "14px",
            marginTop: "10px",
        },
        iTinkerSummerText: {
            fontSize: "18px",
        },
        PlayButton: {
            marginLeft: "26px",
            marginTop: "2px",
        },
        paragraph: {
            width: "100%",
        }
    },
    [theme.breakpoints.only('xs')]: {
        InternshipMain: {
            padding: "0",
            display: "block",
            marginTop: "10px",
            marginBottom: "160px",
            justifyContent: "center",
            width: "100%",

        },
        InternshipMainReverse: {
            padding: "0",
            display: "block",
            height: "500px",
            margin: "0",
            marginBottom: "-47px",
            justifyContent: "center",
            width: "100%",
        },
        InternshipProgramsText: {
            marginLeft: "24px",
            marginRight: "19px",
            marginBottom: "3%",
            height: "200px"
        },
        InternshipProgramsTextReverse: {
            marginLeft: "24px",
            marginRight: "19px",
            marginBottom: "-6px",
        },
        InternshipProgramsVideo: {
            width: "100%",
            marginTop: "40px",
            marginLeft: "0px",
            display: 'flex',
            justifyContent: 'center',
            '& .react-3d-carousel': {
                height: "261px",
                width: " 374px",
            },
        },
        InternshipTitle: {
            fontSize: "18px",
            marginBottom: "20px",
        },
        InternshipText: {
            fontSize: "13px",
            width: " 100%",
            textAlign: "left"
        },
        InternshipTextReverse: {
            fontSize: "13px",
            width: " 100%",
            textAlign: "left"
        },
        Icon: {
            width: "46px",
            height: "46px",
            marginRight: "14px",
            marginTop: "10px",
        },
        iTinkerSummerText: {
            fontSize: "13px",
        },
        PlayButton: {
            marginLeft: "25px",
            marginTop: "0px",
        },
        paragraph: {
            width: "100%",
        }
    },

}))

export const WhyInternshipStyles = makeStyles((theme) => ({

    main: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: "150px",
        marginLeft: "-21px"
    },
    mainReverse: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "center",
        marginBottom: "150px",
    },
    Images: {
        textAlign: "center",
        width: "40%",
    },
    ImagesReverse: {
        width: "40%",
    },
    Text: {
        width: "72%",
        marginTop: "75px",
        marginLeft: "25px"
    },
    TextWhyInternship: {
        width: "72%",
        marginTop: "75px",
        marginLeft: "25px"
    },
    TextReverse: {
        width: "80%",
        margin: "75px 0 0 30px",
    },
    SubHeading: {
        fontFamily: 'Montserrat-Bold',
        fontSize: "25px",
        marginRight: "6px",
        fontWeight: "bold",
        lineHeight: 1.51,
        color: "#11246f",
    },
    ListText: {
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: 1.8,
        color: "##000",
        fontFamily: 'Montserrat-Regular',
        paddingLeft: "0px !important",
        paddingRight: "71px !important"
    },
    InternshipImage: {
        width: "374px",
        height: "174px",
        borderRadius: "5px",
        marginBottom: "-11px"
    },
    [theme.breakpoints.only('sm')]: {
        main: {
            flexDirection: "column-reverse",
            marginBottom: "70px",
            marginLeft: "0"
        },
        mainReverse: {
            flexDirection: "column-reverse",
            marginBottom: "70px",
        },
        Images: {
            width: "100%",
        },
        ImagesReverse: {
            width: "100%",
            textAlign: "center",
        },
        Text: {
            width: "100%",
            marginLeft: "0px",
            marginTop: "102px"
        },
        TextWhyInternship: {
            width: "100%",
            marginLeft: "0px",
            marginTop: "102px"
        },
        TextReverse: {
            width: "100%",
            marginLeft: "0px",
        },
        SubHeading: {
            fontSize: "22px",
            marginLeft: "32px",
        },
        ListText: {
            fontSize: "18px",
        },
        listItem: {
            width: "88%",
            display: "flex",
            marginLeft: "4%",
            padding: "0",
            paddingLeft: "22px"
        },
        InternshipImage: {
            marginRight: "-13px",
        },
    },
    [theme.breakpoints.only('xs')]: {
        main: {
            flexDirection: "column-reverse",
            marginBottom: "70px",
            marginLeft: "0",
        },
        mainReverse: {
            flexDirection: "column-reverse",
            marginBottom: "40px",
        },
        Images: {
            width: "100%",
        },
        ImagesReverse: {
            width: "100%",
            textAlign: "center",
        },
        Text: {
            width: "90%",
            marginLeft: "24px",
            marginTop: "33%"
        },
        TextWhyInternship: {
            width: "90%",
            marginLeft: "24px",
            marginTop: "90px"
        },
        TextReverse: {
            width: "90%",
            marginLeft: "24px",
        },
        SubHeading: {
            fontSize: "18px",
        },
        ListText: {
            fontSize: "13px",
            padding: "0 !important",
            textAlign: "justify !important"
        },
        listItem: {
            width: "88%",
            display: "flex",
            marginLeft: "4%",
        },
        InternshipImage: {
            width: "338px",
            height: "157.5px",
            marginBottom: "-16px",
        },
    },
}))


