import { makeStyles } from '@material-ui/core/styles';
import {Constants} from "../../../utils/Constants";

export const styles = makeStyles((theme) => ({
  main: {
    backgroundImage: `url(${Constants.image_cdn}/itinker-assets/GetStarted/homebgImg.jpg)`,
    height: '79vh',
    padding: '60px 40px 60px 40px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      backgroundSize: 'cover',
      padding: '60px 20px 60px 20px',
    },
  },
  section: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mainText: {
    fontSize: 43,
    marginTop: '25%',
    fontFamily: 'OpenSansBold',
    color: theme.palette.other.white,
    [theme.breakpoints.down('xs')]: {
      fontSize: 23,
      marginTop: '10%',
      textAlign: 'center',
    },
    [theme.breakpoints.between('sm','md')]: {
      fontSize: 31,
      textAlign: 'center',
      marginTop: '9%'
    }
  },
  subText: {
    fontSize: 18,
    marginTop: '-4%',
    letterSpacing: '5px',
    color: theme.palette.other.white,
    padding: '40px 0 20px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      letterSpacing: '3px',
      marginTop: '-8%',
      textAlign: 'center',

    },
    [theme.breakpoints.between('sm','md')]:{
      textAlign: 'center',
      marginTop: '-3%'
    },
  },
  flex: {
    display: 'flex',
    marginTop: '10px',
    marginLeft: '-8%',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: -9,
      marginLeft: '16%'
      
    },
    [theme.breakpoints.between('sm','md')]: {
      flexDirection: 'row',
      marginLeft: '0%',
      gap: 5,
      marginTop: '3%'
    }
  },
  leftSection: {
    marginTop:'-8%',
    // '& img': {
    //   width: 629,
    //   position: 'relative',
    //   left: -178,
    // },
  },
  centerSection: {
    textAlign: 'center',
    alignSelf: 'center',
  },
  rightSection: {
    // '& img': {
    //   width: 409,
    //   position: 'relative',
    //   bottom: -10,
    //   right: -89,
    // },
  },
  apple: {
    width: 20,
  },
  mainPhone: {
    width: 520,
    height: 450,
    position: 'relative',
    zIndex: 2,
    marginTop: '15%',
    [theme.breakpoints.down('xs')]: {
      left: 15,
      width: 311,
      height: 256,
      marginTop: '5%'
    },
    [theme.breakpoints.between('sm','md')]: {
      marginTop: '3%',
      marginLeft: '17%'
    },
  },
  backLeftPhone: {
    width: 200,
    height: 400,
    position: 'relative',
    zIndex: 1,
    left: 141,
    bottom: 20,
    [theme.breakpoints.down('sm')]: {
      left: 27,
      width: 175,
      bottom: 3,
      height: 358,
    },
  },
  backRightPhone: {
    width: 200,
    height: 400,
    position: 'relative',
    zIndex: 1,
    right: 145,
    bottom: 20,
    [theme.breakpoints.down('sm')]: {
      left: 145,
      width: 181,
      bottom: 367,
      height: 360,
    },
  },
  rightSubDiv: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 60,
      height: 425,
    },
  },
}));
