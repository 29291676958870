export const styles = (theme) => ({
  main: {
    height: '100%',
    padding: '40px 40px 40px 40px',
    position: 'relative',
    backgroundColor: theme.palette.other.offWhite,
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px 40px 20px',
    },
  },
  panel: {
    width: '200px !important',
    margin: 30,
    '& img': {
      width: 180,
      height: 400,
      marginTop: 30,
      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: 60,
      },
    },
  },
  activePanel: {
    marginTop: 30,
    '& img': {
      width: 217,
      height: 473,
      marginLeft: 7,

      [theme.breakpoints.between('sm', 'md')]: {
        marginLeft: 224,
        marginTop: 50,
      },
    },
  },
  section: {
    margin: '20px 10px 30px 10px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  panel1: {
    width: '220px !important',
    marginLeft: 16,
    '& img': {
      width: 14,
    },
  },
  subNav: {
    margin: '0 auto',
    width: 150,
  },
  topHead: {
    textAlign: 'center',
    '& h4': {
      color: '#000000',
      fontFamily: 'OpenSansBold',
      fontSize: 35,
    },
  },
  panelImg: {
    width: 70,
    zIndex: 3,
  },
  sliderSection: {
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  sliderText: {
    fontSize: 14,
    color: theme.palette.secondary.paraFontColor,
    fontWeight: 'bold',
  },
  sliderSubText: {
    color: theme.palette.secondary.paraFontColor,
  },
  text: {
    marginTop: 20,
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    height: 473,
  },
  innerContent: {
    padding: 22,
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  quotes: {
    width: 60,
    height: 60,
  },
  bottomSection: {
    backgroundColor: theme.palette.other.lightestGrey,
    display: 'flex',
    padding: 20,
    justifyContent: 'space-between',
  },
  frame: {
    position: 'absolute',
    height: 500,
    top: 178,
    left: '41%',
    zIndex: 3,
    [theme.breakpoints.down('sm')]: {
      top: 208,
      left: '16%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      left: '36%',
    },
  },
});
