import React from "react";

export const widthValues = ['xs', 'sm', 'md', 'lg', 'xl'];

export const SCREEN_WIDTHS = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
};

export const exploreBtnWidth = (width) => {
    switch (width) {
        case SCREEN_WIDTHS.XS:
            return '0 0 0 313px';
        case SCREEN_WIDTHS.SM:
        case SCREEN_WIDTHS.MD:
        case SCREEN_WIDTHS.LG:
            return '0 0 0 86px';
        default:
            return '0 0 0 80px';
    }
};

export const REQUEST_METHOD = {GET: 'GET', POST: 'POST', PUT: 'PUT', DELETE: 'DELETE'};

export const HEADER_TYPES = {
    UNAUTHENTICATED: "un_authenticated",
    AUTHENTICATED: "authenticated",
    FOR_AUTHENTICATION: "for_authentication"
}


export const GetCurrencySymbolForCountry = (country) => {
    switch (country) {
        case "IN":
            return <>₹</>
        case "US":
            return <>$</>
        default :
            return <>€</>
    }
}

export const GetSymbolForCurrency = (currency) => {
    switch (currency) {
        case "INR":
            return <>₹</>
        case "EUR":
            return <>€</>
        case "USD":
            return <>$</>
        default :
            return <>€</>
    }
}

export const getPricingByLocation = (courses, ipData) => {
    courses.forEach((course) => {
        let priceModelByLocation = course.pricing.find((priceData) => priceData.country === ipData.country);
        if (!priceModelByLocation) {
            priceModelByLocation = course.pricing.find((priceData) => priceData.country === 'DEFAULT');
        }
        course.price = priceModelByLocation.price;
    })
}

export const getPricingByUserCurrency = (courses, userCurrency) => {
    courses.forEach((course) => {
        let priceModel = course.pricing.find((priceData) => priceData.currency === userCurrency);
        if (!priceModel) {
            priceModel = course.pricing.find((priceData) => priceData.country === 'DEFAULT');
        }
        course.price = priceModel.price;
    })
}