import {styles} from "./styles";
import React, {useContext} from "react";
import {AuthContext} from "../../utils/AuthContext";
import {Typography} from "@material-ui/core";
import Imgix from "react-imgix";
import {useMediaQuery, useTheme} from "@mui/material";

const content = "iTinker is a Germany based tech-ed company which has a global highly experienced team from countries like India, Germany, Finland, Hungary, USA and more with proficiency in more than 14 industries. We are working on making the school children future ready by giving them hands-on learning experience on future technologies like Artificial Intelligence, Internet of Things, 3D printing and modeling, Augmented Reality, Virtual Reality and many others which are going to takeover the world in the coming years."

export function FooterWebView() {

    const classes = styles();
    const mediaTheme = useTheme();
    const showText = useMediaQuery(mediaTheme.breakpoints.down('sm'));
    const tabView = useMediaQuery(mediaTheme.breakpoints.between('sm', 'md'));
    let auth = useContext(AuthContext);
    return (
        <div className={classes.webMain}>

            <div className={classes.logo}>
                <Typography className={classes.title} variant="h5">
                    iTinker
                </Typography>
            </div>
            <div className={classes.bigContent}>{content}</div>
            <div className={classes.extra}>
                <div className={classes.column}>
                    <Typography className={`${classes.heading} ${classes.infoHead}`}>
                        Company
                    </Typography>
                    <a id="footer_about"
                       onClick={() => (window.open('/about', '_self'))}
                       className={classes.footerLink}
                       rel="noreferrer"
                    >
                        <Typography className={classes.subText}>
                            About us
                        </Typography>
                    </a>

                    <a id="footer_terms"
                       onClick={() => (window.open('/terms', '_self'))}
                       className={classes.footerLink}
                       rel="noreferrer"
                    >
                        <Typography className={classes.subText}>
                            Terms of Service
                        </Typography>

                    </a>
                    <a id="footer_terms"
                       onClick={() => (window.open('/privacy', '_self'))}
                       className={classes.footerLink}
                       rel="noreferrer"
                    >
                        <Typography className={classes.subText}>
                            Privacy
                        </Typography>

                    </a>
                </div>
                <div className={classes.column}>

                    <Typography className={`${classes.heading} ${classes.infoHead}`}>
                        Get In Touch
                    </Typography>

                    <a id="footer_jobs"
                       onClick={() => (window.open('/contact', '_self'))}
                       className={classes.footerLink}
                       rel="noreferrer"
                    >
                        <Typography className={classes.subText}>Contact Us</Typography>
                    </a>
                    <a id="footer_jobs"
                       onClick={() => (window.open('/Careers', '_self'))}
                       className={classes.footerLink}
                       rel="noreferrer"
                    >
                        <Typography className={classes.subText}>Careers</Typography>
                    </a>
                </div>
                <div className={classes.column}>
                    <Typography className={`${classes.heading} ${classes.followSpace}`}>
                        Follow us
                    </Typography>
                    {
                        !tabView
                            ?

                            <div className={classes.mobileSocialIcons}>
                                <a id="footer_instagram"
                                   href="https://www.instagram.com/itinker.io/?hl=en"
                                   target="_blank" rel="noreferrer"
                                   className={classes.socialIcon}
                                >
                                    {/*<img src={instagram} className={classes.imgSetting1}/>*/}
                                    <Imgix src="/itinker-assets/Footer/instagram.png"
                                           className={classes.imgSettings1}/>
                                    {
                                        showText
                                            ?
                                            <></>
                                            :
                                            <p className={classes.socialIconText}>Instagram</p>
                                    }
                                </a>

                                <a id="footer_fb"
                                   href="https://www.facebook.com/itinker.io"
                                   target="_blank" rel="noreferrer"
                                   className={classes.socialIcon}
                                >
                                    <Imgix src="/itinker-assets/Footer/facebook.png"
                                           className={classes.imgSettings2}/>
                                    {
                                        showText
                                            ?
                                            <></>
                                            :
                                            <p className={classes.socialIconText}>Facebook</p>
                                    }
                                </a>

                                <a id="footer_linkedin"
                                   href="https://www.linkedin.com/company/itinker/"
                                   target="_blank" rel="noreferrer"
                                   className={classes.socialIcon}
                                >
                                    <Imgix src="/itinker-assets/Footer/linkedin.png"
                                           className={classes.imgSettings3}/>
                                    {
                                        showText
                                            ?
                                            <></>
                                            :
                                            <p className={classes.socialIconText}>Linkedin</p>
                                    }
                                </a>

                                <a id="footer_twitter"
                                   href="https://twitter.com/itinker_io"
                                   target="_blank" rel="noreferrer"
                                   className={classes.socialIcon}
                                >
                                    <Imgix src="/itinker-assets/Footer/twitter.png"
                                           className={classes.imgSettings4}/>
                                    {
                                        showText
                                            ?
                                            <></>
                                            :
                                            <p className={classes.socialIconText}>Twitter</p>
                                    }
                                </a></div>

                            :
                            <>
                                <a id="footer_instagram"
                                   href="https://www.instagram.com/itinker.io/?hl=en"
                                   target="_blank" rel="noreferrer"
                                   className={classes.socialIcon}
                                >
                                    <Imgix src="/itinker-assets/Footer/instagram.png" className={classes.imgSettings1}/>
                                    {
                                        showText
                                            ?
                                            <></>
                                            :
                                            <p className={classes.socialIconText}>Instagram</p>
                                    }
                                </a>

                                <a id="footer_fb"
                                   href="https://www.facebook.com/itinker.io"
                                   target="_blank" rel="noreferrer"
                                   className={classes.socialIcon}
                                >
                                    <Imgix src="/itinker-assets/Footer/facebook.png" className={classes.imgSettings2}/>
                                    {
                                        showText
                                            ?
                                            <></>
                                            :
                                            <p className={classes.socialIconText}>Facebook</p>
                                    }
                                </a>

                                <a id="footer_linkedin"
                                   href="https://www.linkedin.com/company/itinker/"
                                   target="_blank" rel="noreferrer"
                                   className={classes.socialIcon}
                                >
                                    <Imgix src="/itinker-assets/Footer/linkedin.png" className={classes.imgSettings3}/>
                                    {
                                        showText
                                            ?
                                            <></>
                                            :
                                            <p className={classes.socialIconText}>Linkedin</p>
                                    }
                                </a>

                                <a id="footer_twitter"
                                   href="https://twitter.com/itinker_io"
                                   target="_blank" rel="noreferrer"
                                   className={classes.socialIcon}
                                >
                                    <Imgix src="/itinker-assets/Footer/twitter.png" className={classes.imgSettings4}/>
                                    {
                                        showText
                                            ?
                                            <></>
                                            :
                                            <p className={classes.socialIconText}>Twitter</p>
                                    }
                                </a>
                            </>
                    }


                </div>
                <div className={classes.column}>
                    <a id="footer_download_ios"
                       href="https://apps.apple.com/tr/app/itinker/id1528019507"
                       target="_blank" rel="noreferrer"
                       className={classes.storeIcon}
                    >
                        <Imgix src="/itinker-assets/Footer/iosdownload.png" className={classes.imgSettings}/>
                    </a>
                    <a id="footer_download_android"
                       href="https://play.google.com/store/apps/details?id=com.itinker_app"
                       target="_blank" rel="noreferrer"
                       className={classes.storeIcon}

                    >
                        <Imgix src="/itinker-assets/Footer/androiddownload.png" className={classes.imgSettings}/>
                    </a>
                </div>
            </div>

        </div>
    )
}
