import {OurVision as useStyles} from "./styles";
import {Typography} from "@material-ui/core";
import React from "react";

function OurVision(props) {
    const extraStyle = props.extraStyles;
    const classes = useStyles();
    return (
        <div
            className={!props.arrayDataOfOurVisionAndMission.taViewRotated ? `${classes.ourVisionBackground} ${extraStyle}` : `${classes.ourVisionBackground} ${extraStyle} ${classes.ourMission}`}>
            <div className={classes.ourVision}>
                <Typography className={classes.ourVisionHeading}>
                    {props.arrayDataOfOurVisionAndMission.heading}
                </Typography>
                <Typography className={classes.ourVisionDescription}>
                    {props.arrayDataOfOurVisionAndMission.description}
                </Typography>
            </div>
            <div className={props.colored ? classes.ImageSectionOfOurVisionColored : classes.ImageSectionOfOurVision}>
                <img src={props.arrayDataOfOurVisionAndMission.OurVisionImage1} className={classes.ourVisionImage1}/>
                <img src={props.arrayDataOfOurVisionAndMission.OurVisionImage2} className={classes.ourVisionImage2}/>
                <img src={props.arrayDataOfOurVisionAndMission.OurVisionImage3} className={classes.ourVisionImage3}/>
            </div>

        </div>
    )
}


export default OurVision;