import {WhyInternshipStyles as useStyles} from "./styles";
import {List, ListItem, useMediaQuery, useTheme} from "@mui/material";

const WhyInternship = (props) => {
    const classes = useStyles();
    const mediaTheme = useTheme();
    const tabletView = useMediaQuery(mediaTheme.breakpoints.only('sm'));
    return (
        <div
            className={props.Data.reverse ? classes.mainReverse : classes.main}>
            <div className={props.Data.reverse ? classes.ImagesReverse : classes.Images}>
                <img className={classes.InternshipImage} src={props.Data.image2}></img>
                <img className={classes.InternshipImage} src={props.Data.image3}></img>
                {!tabletView && <img className={classes.InternshipImage} src={props.Data.image4}></img>}
            </div>
            <div className={props.Data.reverse ? classes.TextReverse : classes.TextWhyInternship}>
                <span className={classes.SubHeading}>
                   {props.Data.heading1}
                    <span style={{color: "#ff8501"}}>{props.Data.heading2}</span>
                </span>
                <div className={classes.listItem}>
                    <List sx={{
                        listStyleType: 'disc',
                        pl: 1,
                        '& .MuiListItem-root': {
                            display: 'list-item',
                        },
                    }}>
                        <ListItem className={classes.ListText}> {props.Data.point1}</ListItem>
                        <ListItem className={classes.ListText}> {props.Data.point2}</ListItem>
                        <ListItem className={classes.ListText}>{props.Data.point3}</ListItem>
                    </List>
                </div>
            </div>
        </div>
    )
}
export default WhyInternship;