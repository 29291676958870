import {BroughtToYouByStyles as useStyles} from './styles';
import arrow from "../../assets/Jobs/16.png";
import companyLogoLaptop from "../../assets/AboutUs/web navbar 2.png";
import companyLogoIpad from "../../assets/AboutUs/iPad Navbar 2.png";
import companyLogoMobile from "../../assets/AboutUs/mobile_navbar 2.png";


function BroughtToYouBy() {
    const classes = useStyles();
    return (
            <div className={classes.numberMain}>
                <div className={classes.Heading}>
                    <div className={classes.SubHeading}>Brought to you by the people of</div>
                    <img className={classes.IconArrow} src={arrow}/>
                </div>
                <img className={classes.companyImageLaptop} src={companyLogoLaptop}/>
                <img className={classes.companyImageIpad} src={companyLogoIpad}/>
                <img className={classes.companyImageMobile} src={companyLogoMobile}/>
            </div>

    )
}

export default BroughtToYouBy;