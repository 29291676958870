import React from 'react';

// Creating the context object and passing the default values.
export const AuthContext = React.createContext({
    user: null, setUser: (user) => {
    }
});

export const CourseContext = React.createContext({
    courses: [], setCourses: (courses) => {
    }
});