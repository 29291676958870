import {styles as useStyles} from "./styles";
import BecomeATinkerer from "../../components/BeATinkerer/BecomeATinkerer";
import WhyChooseITinker from "../../components/BeATinkerer/WhyChooseITinker";
import InternshipPrograms from "../../components/BeATinkerer/InternshipPrograms";
import WhyInternship from "../../components/BeATinkerer/WhyInternship";
import internshipImageStudent from "../../assets/BeATinkerer/image 2-1.png";
import internshipImageTeacher from "../../assets/BeATinkerer/image 3-1.png";
import internshipImage from "../../assets/BeATinkerer/image 5-1.png";
import learningImage from "../../assets/BeATinkerer/image 2.png";
import student from "../../assets/BeATinkerer/image 3.png";
import learningAndExploreImage from "../../assets/BeATinkerer/image 5.png";
import groupImage from "../../assets/BeATinkerer/image 2-2.png";
import profileImage from "../../assets/BeATinkerer/image 3-2.png";
import openBoatImage from "../../assets/BeATinkerer/image 5-2.png";
import InternshipProgramsImage1 from "../../assets/BeATinkerer/youtubeThumbnails/1.png";
import InternshipProgramsImage2 from "../../assets/BeATinkerer/youtubeThumbnails/2.png";
import InternshipProgramsImage3 from "../../assets/BeATinkerer/youtubeThumbnails/3.png";
import CourseAndWorkshopImage1 from "../../assets/BeATinkerer/youtubeThumbnails/4.png";
import CourseAndWorkshopImage2 from "../../assets/BeATinkerer/youtubeThumbnails/5.png";
import CourseAndWorkshopImage3 from "../../assets/BeATinkerer/youtubeThumbnails/6.png";
import CompetitionsAndEventsImage1 from "../../assets/BeATinkerer/youtubeThumbnails/7.png";
import CompetitionsAndEventsImage2 from "../../assets/BeATinkerer/youtubeThumbnails/8.png";
import CompetitionsAndEventsImage3 from "../../assets/BeATinkerer/youtubeThumbnails/9.png";
import {useState, useEffect} from "react";
import {Box, Modal, useMediaQuery, useTheme} from "@mui/material";

const style = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
};

const BeATinkerer = () => {
    const classes = useStyles();
    const mediaTheme = useTheme();
    const iFrameSize = useMediaQuery(mediaTheme.breakpoints.between('xs', 'sm'));
    useEffect(() => {
        window.scrollTo(0, 0);
        // @ts-ignore
        document.querySelector('body').scrollTo(0, 0)
    }, []);

    const [open, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const handleOpen = (source) => {
        setOpen(true);
        setVideoUrl(source);
    };
    const handleClose = () => setOpen(false);

    let WhyInternshipStudent = {
        reverse: false,
        image2: internshipImageStudent,
        image3: internshipImageTeacher,
        image4: internshipImage,
        heading1: "Get Trained ",
        heading2: "@iTinker!",
        point1: "You will be a part of orientation where you will learn about culture, as well as roles, benefits and responsibilities of a particular field from the people working in the field.",
        point2: "You will experience the Division-specific training that is  designed to help you succeed and learn about the necessary industry skills.",
        point3: "You will receive the opportunity to work on real responsibilities alongside fellow interns and our people."

    };
    let LearnAndExplore = {
        reverse: true,
        image2: learningImage,
        image3: student,
        image4: learningAndExploreImage,
        heading1: "Learn & Explore with ",
        heading2: "iTinker!",
        point1: "You will be well taught in our courses which will encourage critical thinking, creativity and problem-solving skills through programming and technology.",
        point2: "You will participate in Technology and Programming workshops and build hands-on tech projects and experience your ideas coming to life.",
        point3: "You will have the opportunity to expand your creativity by discussing ideas with your peers as well as industry professionals."
    };
    let WhyInternshipPrograms = {
        reverse: false,
        image2: groupImage,
        image3: profileImage,
        image4: openBoatImage,
        heading1: "Showcase Your Skills With ",
        heading2: "iTinker!",
        point1: " You will get a platform to showcase your talent and apply your skills.You will get to test your ideas to see how viable they are, in a risk-free environment.",
        point2: "You will be able to collect and incorporate feedback from peers and professionals.You will connect with creative minds and expand your network. ",
        point3: "You will be rewarded for the efforts you put in your innovative creations."
    };
    let internshipPrograms = {
        reverse: false,
        heading: "Internship Programs",
        Info1: " iTinker’s internship programs offer you to experience the real world corporate lifestyle.",
        Info2: "You get to work on live projects and have one to one conversations, interactions with the industry experts.",
        LinkName: "iTinker Summer Internship 2022",
        playListLink: "https://www.youtube.com/playlist?list=PL2wiBgLb7UUnxzaS7uH2d3hxx3A6_YU6I",
        slides: [<img
            onClick={() => handleOpen("https://www.youtube.com/embed/Fqes7tqrChE")}
            src={InternshipProgramsImage2}
            alt="Internship Programs"/>, <img
            onClick={() => handleOpen("https://www.youtube.com/embed/DrV_8Rsyz64")}
            src={InternshipProgramsImage1}
            alt="Internship Programs"/>,

            <img
                onClick={() => handleOpen("https://www.youtube.com/embed/-nPURM0QYdg")}
                src={InternshipProgramsImage3}
                alt="Internship Programs"/>,],
    };
    let coursesAndWorkshops = {
        reverse: true,
        heading: "Courses & Workshops",
        Info1: "iTinker with the help of its well designed courses and tech workshops reduces the digital gap by encouraging computational thinking, stimulating creativity and bringing you closer to programming and technology, not only as consumers but also as creators.",
        LinkName: "Mobile App Designing Workshop",
        playListLink: "https://www.youtube.com/watch?v=9uOBQ6nxoc0",
        slides: [<img
            onClick={() => handleOpen("https://www.youtube.com/embed/9uOBQ6nxoc0")}
            src={CourseAndWorkshopImage1}
            alt="Courses & Workshops"/>, <img
            onClick={() => handleOpen("https://www.youtube.com/embed/i__B1oFmmqs")}
            src={CourseAndWorkshopImage2}
            alt="Courses & Workshops"/>,

            <img
                onClick={() => handleOpen("https://www.youtube.com/embed/WwYFZzIWZ1A")}
                src={CourseAndWorkshopImage3}
                alt="Courses & Workshops"/>,],
    };
    let competitionsAndEvents = {
        reverse: false,
        heading: "Competitions & Events",
        Info1: "iTinker brings talented young minds from all over the country together to share their innovative ideas and compete with each other.",
        Info2: "You get to collaborate and apply your learnings in real-time while analyzing different competitive strategies.",
        LinkName: "TinkerWorld 1.0",
        playListLink: "https://www.youtube.com/watch?v=HBk546MvLYM",
        slides: [<img
            onClick={() => handleOpen("https://www.youtube.com/embed/HBk546MvLYM")}
            src={CompetitionsAndEventsImage1}
            alt="Competitions & Events"/>, <img
            onClick={() => handleOpen("https://www.youtube.com/embed/mal6GjGjQjM")}
            src={CompetitionsAndEventsImage2}
            alt="Competitions & Events"/>, <img
            onClick={() => handleOpen("https://www.youtube.com/embed/onKn6z63530")}
            src={CompetitionsAndEventsImage3}
            alt="Competitions & Events"/>,],
    };

    return (<div className={classes.main}>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {!iFrameSize && <iframe width="1060" height="615" src={videoUrl}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                </iframe>}
                {iFrameSize && <iframe width="330" height="195" src={videoUrl}
                                       title="YouTube video player"
                                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                       allowFullScreen>
                </iframe>}
            </Box>
        </Modal>
        <div>
            <BecomeATinkerer/>
            <WhyChooseITinker/>
            <div className={classes.mainBe}>
                <InternshipPrograms Data={internshipPrograms}/>
                <WhyInternship Data={WhyInternshipStudent}/>
            </div>
            <div className={classes.subMain}>
                <InternshipPrograms Data={coursesAndWorkshops}/>
                <div className={classes.whyInternship}>
                    <WhyInternship Data={LearnAndExplore}/>
                </div>
            </div>
            <div className={classes.mainBe}>
                <InternshipPrograms Data={competitionsAndEvents}/>
                <WhyInternship Data={WhyInternshipPrograms}/>
            </div>
        </div>
    </div>)
}
export default BeATinkerer;

