import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  main: {
    overflow: 'hidden',
    minWidth:"375px",
  },
  backgroundDiv:{
    backgroundColor: "#fff2e1",
  },
  backgroundMission:{
    backgroundColor: "#fff",
  }
}));
