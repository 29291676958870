import React, {useEffect, useState} from 'react';
import Proptypes from 'prop-types';
import {withWidth} from '@material-ui/core';
import {styles as useStyles} from './styles';
import {SCREEN_WIDTHS, widthValues} from '../../../utils/common';
import {Api} from "../../../services/Api";
import {Constants} from "../../../utils/Constants";
import {VideoModelFromJson} from "../../../models/WebsiteStaticDataModels";

const BasicVideoContainer = ({width, margins = false}) => {
    const classes = useStyles();
    const [video, setVideo] = useState([]);

    useEffect(() => {
        Api(Constants.getVideos, {}, "").then((response) => {
            if (response !== null) {
                response.forEach((video) => {
                    let newVideo = VideoModelFromJson(video)
                    if (newVideo.id === "2")
                        setVideo(newVideo)

                })
            }
        })

    }, [])

    return (
        <div>
            <div className={margins ? classes.videoContainer : ''}>
                <iframe
                    style={{borderRadius: 12}}
                    width={
                        width === SCREEN_WIDTHS.XS || width === SCREEN_WIDTHS.SM
                            ? '250'

                            : '532'
                    }
                    height={
                        width === SCREEN_WIDTHS.XS || width === SCREEN_WIDTHS.SM
                            ? '150'
                            : '336'
                    }
                    src={video.source}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="iTinker"
                />
            </div>
        </div>
    );
};
BasicVideoContainer.propTypes = {
    width: Proptypes.oneOf(widthValues),
    margins: Proptypes.bool,
};
export default withWidth()(BasicVideoContainer);
