import {BeingATinkererStyles as useStyles} from "./styles";
import arrow from "../../assets/Jobs/16.png";
import growth from "../../assets/Jobs/BLUE ICON.png";
import Diversity from "../../assets/Jobs/pink.png";
import calendar from "../../assets/Jobs/orange.png";

function BeingATinkerer() {
    const classes = useStyles();
    return (
        <div className={classes.Main}>
            <div className={classes.Heading}>
                <div className={classes.SubHeading}>Being a Tinkerer has its &nbsp;</div>
                <div  className={classes.Perk} style={{color: "#ff8501"}}> Perks & Benefits</div>
                <img className={classes.IconArrow} src={arrow}/>
            </div>
            <div className={classes.Cards}>
                {
                    arrayOfWhyChoose.map((data) => (
                        <div className={classes.Card1}>
                            <div className={classes.Icons}><img className={classes.Icon} src={data.image}/></div>
                            <div className={classes.Title}>{data.title}</div>
                            <div className={classes.Line}></div>
                            <div className={classes.Info}> {data.info}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const arrayOfWhyChoose = [
    {
        image: growth,
        title: "Growth Culture",
        info: "Enhance your professional development along with the company’s growth."
    },
    {
        image: Diversity,
        title: "Diversity & Inclusion",
        info: "Diversity, Equity and Inclusion is not lip service.It is built into our culture."
    },
    {
        image: calendar,
        title: "Flexible Environment",
        info: "One on One meetings, fun activities help our employees maintain work-life balance.."
    },
]
export default BeingATinkerer;