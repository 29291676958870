import {HEADER_TYPES, REQUEST_METHOD} from "../utils/common";
import {Constants} from "../utils/Constants";
import Cookies from "universal-cookie";

/**
 * Function to call the apis on the service.
 * @param request : string of the request,
 * @param params :. parameters with the request
 * @param body : string of the request
 * @param extraParams
 * @returns {Promise<*>} : returns json of the response from the service.
 * @constructor
 */
export async function Api(request, params, body, extraParams = {}) {

    switch (request) {
        case Constants.checkAuth:
        case Constants.getUserData:
        case Constants.checkUser:
            return await createRequest(Constants.login_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.sendOTP:
        case Constants.addUser:
            return await createRequest(Constants.login_server, REQUEST_METHOD.POST, HEADER_TYPES.FOR_AUTHENTICATION)
        case Constants.login:
            return await createRequest(Constants.login_server, REQUEST_METHOD.GET, HEADER_TYPES.FOR_AUTHENTICATION)
        case Constants.updateUserData:
            return await createRequest(Constants.login_server, REQUEST_METHOD.PUT, HEADER_TYPES.AUTHENTICATED)
        case Constants.createOrder:
        case Constants.addSessionActivity:
        case Constants.addMeeting:
            return await createRequest(Constants.website_server, REQUEST_METHOD.POST, HEADER_TYPES.AUTHENTICATED)
        case Constants.updateOrderStatus:
            return await createRequest(Constants.app_server, REQUEST_METHOD.PUT, HEADER_TYPES.AUTHENTICATED)
        case Constants.getProductByReadableID:
        case Constants.getCoursesList:
        case Constants.getTutorCourseDetails:
        case Constants.getAnonTutorSessionsDetails:
            return await createRequest(Constants.website_server, REQUEST_METHOD.GET, HEADER_TYPES.UNAUTHENTICATED)
        case Constants.addCustomerLead:
        case Constants.addTechnovationLead:
            return await createRequest(Constants.website_server, REQUEST_METHOD.POST, HEADER_TYPES.UNAUTHENTICATED)
        case Constants.getUserGame:
        case Constants.getGameData:
        case Constants.getAchievements:
        case Constants.getUserOrders:
        case Constants.getProducts:
        case Constants.getUserWalletTransactions:
            return await createRequest(Constants.app_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.getStudentClassrooms:
        case Constants.getStudentClassCourses:
        case Constants.getTeacherClassCourses:
        case Constants.getTutorSessionsDetails:
        case Constants.getMultipleSessionStatuses:
        case Constants.getUserSessionActivities:
        case Constants.getUserCourseSessionActivities:
        case Constants.getTeacherDateMeetings:
        case Constants.getSessionStatus:
        case Constants.getCourseActivities:
            return await createRequest(Constants.website_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.getFrsAssessments:
            return await createRequest(Constants.frs_data_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.addWalletTransaction:
        case Constants.addNewUserData:
        case Constants.addInviteCodeUsage:
            return await createRequest(Constants.app_server, REQUEST_METHOD.POST, HEADER_TYPES.AUTHENTICATED)
        case Constants.isInviteCodeValid:
            return await createRequest(Constants.app_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.getMiscData:
            return await createRequest(Constants.app_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.getUidByUsername:
        case Constants.listUsersWithIds:
            return await createRequest(Constants.login_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.ipJson:
            return await createRequest(Constants.ip_api_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.updateSessionActivity:
        case Constants.updateSessionStatus:
            return await createRequest(Constants.website_server, REQUEST_METHOD.PUT, HEADER_TYPES.AUTHENTICATED)
        case Constants.getBlogs:
        case Constants.getTeamMembers:
        case Constants.getCompetitions:
        case Constants.getDownloadPageReviews:
        case Constants.getCoursePageFeatures:
        case Constants.getJobFunds:
        case Constants.getInternships:
        case Constants.getHomePageScores:
        case Constants.getVideos:
        case Constants.getHomePageReviews:
            return await createRequest(Constants.website_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        case Constants.getSchool:
            return await createRequest(Constants.app_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
        default:
            return await createRequest(Constants.login_server, REQUEST_METHOD.GET, HEADER_TYPES.AUTHENTICATED)
    }

    /**
     * Function to create a new request
     * @param host : string service to send the request. i.e. login_service or frs_data_service
     * @param method : string method of the request . i.e. GET, POST, PUT, DELETE
     * @param headerType : string type of header needed, i.e. authenticated, non-authenticated.
     * @returns {Promise<any>}
     */
    async function createRequest(host, method, headerType) {
        let url = getUrl(host, request);
        let requestInit = {
            method: method,
            mode: "cors",
            headers: await getHeaders(headerType),
        }
        switch (method) {
            case REQUEST_METHOD.DELETE:
            case REQUEST_METHOD.GET:
            default:
                break;
            case REQUEST_METHOD.PUT:
            case REQUEST_METHOD.POST:
                requestInit.body = body;
                break;
        }
        let response = await fetch(url, requestInit).catch(() => {
            return {status: 500}
        });
        if (response.status !== 200) {
            return "";
        }
        return await response.json();
    }

    /**
     *
     * @param host
     * @param request
     * @returns {string}
     */
    function getUrl(host, request) {
        let url = new URL(request, host);
        let searchParams = new URLSearchParams();
        for (let paramKey in params) {
            if (params.hasOwnProperty(paramKey)) {
                searchParams.set(paramKey, params[paramKey])
            }
        }
        return url + "?" + searchParams;
    }

    /**
     * Generates a unique OTP JWT Token to be sent to authentication service
     * @returns {Promise<*>}
     */
    async function generateForAuthenticationToken() {
        let jwt = require('jsonwebtoken');
        return await jwt.sign({
            "countrycode": extraParams['countrycode'],
            "phone": extraParams['phone'],
            "otp": extraParams['otp'] === undefined ? "" : extraParams['otp'],
            "ipaddress": extraParams['ipaddress'] === undefined ? "" : extraParams['ipaddress'],
            "source": "web",
            iat: 0
        }, Constants.otp_token_key, {algorithm: 'HS256'});
    }

    /**
     *
     * @param type
     * @returns {Headers}
     */
    async function getHeaders(type) {
        const cookies = new Cookies();
        let headers = new Headers();
        switch (type) {
            case HEADER_TYPES.UNAUTHENTICATED:
            default:
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                return headers;
            case HEADER_TYPES.AUTHENTICATED:
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                headers.append('Authorization', cookies.get(Constants.auth_token));
                return headers;
            case HEADER_TYPES.FOR_AUTHENTICATION:
                let forAuthKey = await generateForAuthenticationToken();
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                headers.append('Authorization', forAuthKey);
                return headers;
        }
    }
}
