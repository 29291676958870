import {styles as useStyles} from './styles';
import {Grid, Typography, withWidth} from '@material-ui/core';
import PropTypes from 'prop-types';
import {SCREEN_WIDTHS, widthValues} from "../../../utils/common";
import Imgix from "react-imgix";


const RightPhoneSection = ({title, text, imageSrc, history, width}) => {
    const classes = useStyles();
    const isSmallDevice = (width === SCREEN_WIDTHS.XS || width === SCREEN_WIDTHS.SM || width === SCREEN_WIDTHS.MD);

    return (
        <div className={classes.main}>
            {
                isSmallDevice ?
                    <>
                        <div>
                            <Imgix src={imageSrc} alt="phone" className={classes.phone}/>
                        </div>
                        <Typography className={classes.mainText}>{title}</Typography>
                        <Typography className={classes.descText}>{text}</Typography>
                    </>
                    :
                    <>
                        <div className={classes.section}>
                            <Grid container>
                                <Grid item sm={7} xs={12}>
                                    <Typography className={classes.mainText}>{title}</Typography>
                                    <Typography className={classes.descText}>{text}</Typography>
                                </Grid>
                                <Grid item sm={5} xs={12} className={classes.rightPanel}>
                                    <div>
                                        <Imgix src={imageSrc} alt="phone" className={classes.phone}/>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </>

            }
        </div>

    );
};
RightPhoneSection.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    width: PropTypes.oneOf(widthValues),
};
export default withWidth()(RightPhoneSection);


