import React from 'react';
import {styles as useStyles} from './styles';
import {Grid, Hidden, Typography, withWidth} from '@material-ui/core';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import {SCREEN_WIDTHS} from '../../../utils/common';
import {widthValues} from "../../../utils/common";
import PropTypes from "prop-types";
import {motion} from 'framer-motion/dist/framer-motion'
import Typewriter from "typewriter-effect";
import Imgix from "react-imgix";

const DownloadAppBanner = (params) => {
    const classes = useStyles();
    const {history, width} = params;
    const isSmallDevice = (width === SCREEN_WIDTHS.XS || width === SCREEN_WIDTHS.SM || width === SCREEN_WIDTHS.MD);


    return (
        <div className={classes.main}>
            {
                isSmallDevice ?
                    <>
                        <div>
                            <Imgix
                                src="/itinker-assets/Download/bannerPhone.png"
                                alt="phone2"
                                className={classes.mainPhone}
                            />
                        </div>
                        <Typography className={classes.mainText}>
                            Start your Future Readiness Journey Today
                        </Typography>
                        <Typography className={classes.subText}>
                        <Typewriter
                            options={{
                                strings: [' DOWNLOAD THE ITINKER APP TODAY'],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                        </Typography>
                        <div className={classes.flex}>
                            <PrimaryButton
                                text="Download on iOS"
                                preIcon
                                onClick={() =>
                                    window.open(
                                        'https://apps.apple.com/tr/app/itinker/id1528019507',
                                        '_blank'
                                    )
                                }
                            >
                                <Imgix
                                    src="/itinker-assets/Download/apple.png"
                                    alt="apple"
                                    width="20"
                                    className={classes.apple}
                                />
                            </PrimaryButton>
                            <PrimaryButton
                                text="Download on Android"
                                preIcon
                                onClick={() =>
                                    window.open(
                                        'https://play.google.com/store/apps/details?id=com.itinker_app',
                                        '_blank'
                                    )
                                }
                            >
                                <Imgix
                                    src="/itinker-assets/Download/android.png"
                                    alt="android"
                                    width="20"
                                    className={classes.android}
                                />
                            </PrimaryButton>
                        </div>
                    </>
                    :
                    <>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item sm={6} xs={12} className={classes.leftSection}>
                                <Typography className={classes.mainText}>
                                    Start your Future Readiness Journey Today
                                </Typography>
                                <div className={classes.section}>
                                   <Typography className={classes.subText}>
                                    <Typewriter
                                        options={{
                                            strings: [' DOWNLOAD THE ITINKER APP TODAY'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                                   </Typography>
                                    <div className={classes.flex}>
                                        <PrimaryButton
                                            text="Download on iOS"
                                            preIcon
                                            onClick={() =>
                                                window.open(
                                                    'https://apps.apple.com/tr/app/itinker/id1528019507',
                                                    '_blank'
                                                )
                                            }
                                        >
                                            <Imgix
                                                src="/itinker-assets/Download/apple.png"
                                                alt="apple"
                                                width="20"
                                                className={classes.apple}
                                            />
                                        </PrimaryButton>
                                        <PrimaryButton
                                            text="Download on Android"
                                            preIcon
                                            onClick={() =>
                                                window.open(
                                                    'https://play.google.com/store/apps/details?id=com.itinker_app',
                                                    '_blank'
                                                )
                                            }
                                        >
                                            <Imgix
                                                src="/itinker-assets/Download/android.png"
                                                alt="android"
                                                width="20"
                                                className={classes.android}
                                            />
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12} className={classes.rightSection}>
                                <Hidden only={[SCREEN_WIDTHS.XS]}>
                                    <div className={classes.rightSubDiv}>
                                        <motion.Imgix
                                            whileHover={{scale: 1.06}}
                                            src="/itinker-assets/Download/Phone.png"
                                            alt="phone2"
                                            className={classes.mainPhone}
                                        />
                                    </div>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </>
            }
        </div>
    );
};
DownloadAppBanner.propTypes = {
    width: PropTypes.oneOf(widthValues),
};
export default withWidth()(DownloadAppBanner);
