import {SingleYearStyles as useStyles} from "./styles";
import Image2019 from '../../assets/2019Image.png';
import Image2020 from '../../assets/2020Image.png';
import Image2021 from '../../assets/2021Image.png';
import Image2022 from '../../assets/2022Image.png';
import Icon2019 from '../../assets/2019Icon.png';
import Icon2020 from '../../assets/2020Icon.png';
import Icon2021 from '../../assets/2021Icon.png';
import Icon2022 from '../../assets/2022Icon.png';
import {Typography} from "@material-ui/core";
import orangeArrowImage from '../../assets/orangeArrowAboutUsPage.png';
import dottedLineImage from '../../assets/dottedLineAboutUsPage.png';
import {useState} from "react";
import {withWidth} from "@material-ui/core";
import {SCREEN_WIDTHS} from "../../utils/common";

function YearlyData(props) {
    const classes = useStyles();
    const {width} = props;
    const isMobileView = (width === SCREEN_WIDTHS.XS);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleOnMouseEnter = (index) => {
        setSelectedIndex(index);
    }
    return (
        <div className={classes.singleCard}>
            {/*{arrayOfYearlyData.map((yearData, index) => (*/}
            {/*    <>{*/}
            {/*        isMobileView ?*/}
            {/*            <div className={classes.arrayMappedDataClass} onClick={() => handleOnMouseEnter(index)}>*/}
            {/*                <SingleYear yearData={yearData} selectedIndex={selectedIndex} index={index}/>*/}
            {/*            </div>*/}
            {/*            :*/}

            {/*            <div className={classes.arrayMappedDataClass} onMouseEnter={() => handleOnMouseEnter(index)}>*/}
            {/*                <SingleYear yearData={yearData} selectedIndex={selectedIndex} index={index}/>*/}
            {/*            </div>*/}
            {/*    }</>*/}
            {/*))}*/}
        </div>
    )
}

const arrayOfYearlyData = [
    {
        id: 1,
        image: Image2019,
        year: Icon2019,
        arrowImage: orangeArrowImage,
        line: dottedLineImage,
        heading: "Humble Beginnings",
        description: "The ambitious co-founders embarked on the journey with a vision of skilling children with futuristic technologies and bringing their ideas to life, creating a safe and magical place called ‘iTinker’.",
    },
    {
        id: 2,
        image: Image2020,
        year: Icon2020,
        arrowImage: orangeArrowImage,
        line: dottedLineImage,
        heading: "Next Step Forward",
        description: "The ambitious co-founders embarked on the journey with a vision of skilling children with futuristic technologies and bringing their ideas to life, creating a safe and magical place called ‘iTinker’.",
    },
    {
        id: 3,
        image: Image2021,
        year: Icon2021,
        arrowImage: orangeArrowImage,
        line: dottedLineImage,
        heading: "Growing Together",
        description: "The ambitious co-founders embarked on the journey with a vision of skilling children with futuristic technologies and bringing their ideas to life, creating a safe and magical place called ‘iTinker’.",
    },
    {
        id: 4,
        image: Image2022,
        year: Icon2022,
        arrowImage: orangeArrowImage,
        line: dottedLineImage,
        heading: "Skilling The Next Billion",
        description: "The ambitious co-founders embarked on the journey with a vision of skilling children with futuristic technologies and bringing their ideas to life, creating a safe and magical place called ‘iTinker’.",
    },
]

const SingleYear = (params) => {
    const {yearData, selectedIndex, index} = params;
    const classes = useStyles();

    return (
        <div className={selectedIndex === index ? classes.cardSelected : classes.cardNotSelected}>
            <img src={yearData.image} alt={"ImageOfPeople"}
                 className={selectedIndex === index ? classes.image : classes.imageNotSelected}/>
            <div className={selectedIndex === index ? classes.iconImageBlock : classes.iconImageBlockNotSelected}>
                <img src={yearData.year} alt={"YearIconImage"}
                     className={selectedIndex === index ? classes.icon : classes.iconNotSelected}/>
                <img src={yearData.arrowImage} alt={"orangeArrowImage"}
                     className={selectedIndex === index ? classes.arrowSelected : classes.arrowNotSelected}/>
                <img src={yearData.line} alt={"dottedLineImage"}
                     className={selectedIndex === index ? classes.lineSelected : classes.lineNotSelected}/>
            </div>
            <div className={classes.info}>
                <Typography
                    className={selectedIndex === index ? classes.heading : classes.headingNotSelected}>{yearData.heading}</Typography>
                {selectedIndex === index &&
                    <Typography className={classes.description}>{yearData.description}</Typography>}
            </div>
        </div>
    )
}
export default withWidth()(YearlyData);
