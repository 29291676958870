import {makeStyles} from '@material-ui/core/styles';
import {createMuiTheme} from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1000,
        boxShadow: "none !important",
    },
    menuButton: {
        marginRight: "-14px",
        color: '#000000',
        fontFamily: 'Montserrat-Medium',
        [theme.breakpoints.only('sm')]: {
            '& .MuiSvgIcon-root': {
                fontSize: "2.0rem",
            }
        }

    },
    loggedInMenuBtn: {
        color: "rgb(255, 131, 0)",
    },
    toolbar: {
        alignItems: 'center',
        margin: '0px 102px 0px 102px',
        height: "100px",
        [theme.breakpoints.only('sm')]: {
            margin: '0',
            marginTop: 0,
            paddingTop: 0,
            height: "80px",
        },
        [theme.breakpoints.only('xs')]: {
            margin: '0',
            height: "80px",
        }
    },
    title: {
        flexGrow: 1,
        fontFamily: 'Milkshake',
        fontSize: 38,
        color: '#11246f',
        cursor: 'pointer',
        [theme.breakpoints.only('sm')]: {
            fontSize: 33,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "-1%",
            fontSize: 33,
        }
    },
    userName: {float: "left", margin: "auto", paddingRight: "10px", paddingTop: "5px"},
    menu: {
        display: 'flex',
        color: '#000000',
        fontFamily: 'Montserrat-Medium',

    },
    notifyIcon: {
        width: "30px",
        height: "30px",
        paddingLeft: "12px",
        paddingRight: "12px",
        marginTop: '2px',
        cursor: "pointer",
        [theme.breakpoints.down('xs')]: {
            width: '25px',
            height: '25px',
            marginTop: '3%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: 30,
            height: 30,
            marginTop: '1.5%',
        },
    },
    NotifyBox: {
        width: 900,
        height: 700,
    },
    userProfile: {
        width: "40px",
        height: "40px",
        cursor: "pointer",
    },
    mainAppbar: {
        backgroundColor: '#fff3e4' + '!important',
        boxShadow: 'none',

    },
    menuOption: {
        fontSize: 16,
        marginRight: 16,
        fontWeight: 500,
        color: '#000000',
        fontFamily: 'Montserrat-Medium',
        textTransform: 'capitalize',
        borderRadius: 30,
        '& a': {
            textDecoration: 'none',
            color: theme.palette.secondary.headFontColor,
        },
    },
    menuOptionDownload: {
        fontSize: 14,
        marginRight: 18,
        textTransform: 'capitalize',
        borderRadius: 30,
        width: 100,
        '& a': {
            textDecoration: 'none',
            color: theme.palette.secondary.headFontColor,
        },
    },
    hrLine: {
        borderLeft: "1px solid orange",
        height: 30,
        marginRight: 25,
    },
    downloadBtn: {
        width: 80,
        height: 30,
        fontSize: 16,
        marginTop: 8,
        backgroundColor: '#fff3e4',
        boxShadow: "none",
        borderRadius: 10,
        border: '1px solid',
        color: '#000',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            width: 100,
            margin: "5px auto",
            minHeight: "auto",
            borderRadius: "10px",
            textAlign: "center",
            padding: "9px 30px",
        },
    },
    icon: {
        width: "50px",
        height: "22px",
        marginBottom: '2%',
        marginLeft: '-4%',
    },
    listItem: {
        marginTop: '10%',
    }

}));

export const menuTheme = createMuiTheme({
    overrides: {
        MuiMenu: {
            paper: {
                left: 'unset !important',
                right: '16px',
            },
        },
    },
})

export const UserProfileDropDown = makeStyles((theme) => ({
    userProfile: {
        width: "35px",
        height: "35px",
        cursor: 'pointer',
        [theme.breakpoints.between('sm', 'md')]: {
            marginTop: '1.2%',
        }
    },
    expandMoreIconStyle: {
        marginTop: "-10px",
        display: "block",
        width: "100%",
    },
    icon: {
        width: 20,
        height: 20,
        marginRight: '8%',
    }
}));

export const TabNavbar = makeStyles((theme) => ({
    main: {},
    box: {
        width: "655px",
        [theme.breakpoints.only('xs')]: {
            width: "255px",
        }
    },
    icons: {
        height: "130px",
        width: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "flex-start",


    },
    icon: {
        marginTop: "33px",
        marginRight: "18px",

        [theme.breakpoints.only('xs')]: {
            width: "28px",
            marginTop: "38px",
            marginRight: "13px",
        }
    },
    text: {
        fontFamily: "Montserrat-Medium !important",
        fontSize: "22.5px",
        color: "#000",
        textTransform: "uppercase",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px",
        }
    },
    isActiveText: {
        fontFamily: "Montserrat-Medium !important",
        fontSize: "22.5px",
        color: "#f48c06",
        textTransform: "uppercase",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px",
        }
    },
    items: {
        paddingLeft: "29px",
        height: "100px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only('xs')]: {
            height: "82px",
        }
    },


}))

