import React, {useRef} from 'react';
import {styles as useStyles} from './styles';
import {motion} from 'framer-motion/dist/framer-motion'
import useIsVisible from "../../Home/is-visible";
import {Grid, Typography} from '@material-ui/core';
import Imgix from "react-imgix";

const ApplicationFeatures = () => {
    const classes = useStyles();
    const elemRef = useRef();
    const isVisible = useIsVisible(elemRef);

    const container = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                delayChildren: 0.5
            }
        }
    }

    return (
        <div className={classes.main} ref={elemRef}>
            {isVisible ?
                <Grid container>
                    <Grid item sm={12} className={classes.topSection}>
                        <Typography variant="h4" className={classes.mainText}>
                            Application Features
                        </Typography>
                        <div className={classes.subTextSection}>
                        </div>
                    </Grid>
                    <Grid container spacing={10} className={classes.innerSection}>
                        <Grid item sm={4} xs={12}>
                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate={{opacity: 1, scale: 1}}
                                transition={{easeInOut: "anticipate", duration: 1, delay: 1/6, damping: 1}}
                            >
                                <div className={classes.valueContainer}>
                                    <Imgix src="/itinker-assets/Download/Features/feature1.png" alt="value1" className={classes.sectionImg}/>
                                    <Typography variant="h6" className={classes.visionHeading}>
                                        50+ Courses on future Tech
                                    </Typography>
                                </div>
                            </motion.div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate={{opacity: 1, scale: 1}}
                                transition={{easeInOut: "anticipate", duration: 1, delay: 1/2, damping: 1}}
                            >
                            <div className={classes.valueContainer}>
                                <Imgix src="/itinker-assets/Download/Features/feature2.png" alt="value2" className={classes.sectionImg}/>
                                <Typography variant="h6" className={classes.visionHeading}>
                                    Industry Interface
                                </Typography>

                            </div>
                            </motion.div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate={{opacity: 1, scale: 1}}
                                transition={{easeInOut: "anticipate", duration: 1, delay:  2/2, damping: 1}}
                            >
                            <div className={classes.valueContainer}>
                                <Imgix src="/itinker-assets/Download/Features/feature3.png" alt="value3" className={classes.sectionImg}/>
                                <Typography variant="h6" className={classes.visionHeading}>
                                    Verified Students Only
                                </Typography>
                            </div>
                            </motion.div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate={{opacity: 1, scale: 1}}
                                transition={{easeInOut: "anticipate", duration: 1, delay: 3/2, damping: 1}}
                            >
                            <div className={classes.valueContainer}>
                                <Imgix src="/itinker-assets/Download/Features/feature4.png" alt="value4" className={classes.sectionImg}/>
                                <Typography variant="h6" className={classes.visionHeading}>
                                    Gamified Platform
                                </Typography>
                            </div>
                            </motion.div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate={{opacity: 1, scale: 1}}
                                transition={{easeInOut: "anticipate", duration: 1, delay: 4/2, damping: 1}}
                            >
                            <div className={classes.valueContainer}>
                                <Imgix src="/itinker-assets/Download/Features/feature5.png" alt="value4" className={classes.sectionImg}/>
                                <Typography variant="h6" className={classes.visionHeading}>
                                    Social Feed
                                </Typography>
                            </div>
                            </motion.div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate={{opacity: 1, scale: 1}}
                                transition={{easeInOut: "anticipate", duration: 1, delay: 5/2, damping: 1}}
                            >
                            <div className={classes.valueContainer}>
                                <Imgix src="/itinker-assets/Download/Features/feature6.png" alt="value4" className={classes.sectionImg}/>
                                <Typography variant="h6" className={classes.visionHeading}>
                                    E commerce
                                </Typography>
                            </div>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Grid>
                : <>
                </>}
        </div>

    );
};
ApplicationFeatures.propTypes = {};
export default ApplicationFeatures;
