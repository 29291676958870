import React, {Component} from 'react';
import Slider from 'react-slick';
import {Typography, withStyles, withWidth} from '@material-ui/core';
import {styles} from './styles';
import CustomArrow from './CustomArrow';
import {SCREEN_WIDTHS, widthValues} from '../../../utils/common';
import PropTypes from 'prop-types';
import Imgix from "react-imgix";

class MobileScreens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            active: 0,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    afterChange = (event) => {
        this.setState({active: event});
    };

    render() {
        const {classes, width} = this.props;
        const {active} = this.state;
        return (
            <div className={classes.main}>
                <div className={classes.section}>
                    <div className={classes.topHead}>
                        <Typography variant="h4">Application Screenshots</Typography>
                    </div>
                    <Imgix src="/itinker-assets/Download/Screenshots/frame.png" alt="frame" className={classes.frame}/>
                    <div className={classes.sliderSection}>
                        <Slider
                            className="center"
                            infinite={true}
                            speed={300}
                            slidesToShow={
                                width === SCREEN_WIDTHS.XS || width === SCREEN_WIDTHS.SM ? 1 : 5
                            }
                            slidesToScroll={1}
                            asNavFor={this.state.nav2}
                            ref={(slider) => (this.slider1 = slider)}
                            arrows
                            centerMode
                            autoplay
                            nextArrow={<CustomArrow/>}
                            prevArrow={<CustomArrow direction="left"/>}
                            afterChange={this.afterChange}
                        >
                            <div
                                className={active === 0 ? classes.activePanel : classes.panel}
                            >
                                <div className={classes.content}>
                                    <Imgix src="/itinker-assets/Download/Screenshots/screen1.png" alt="screen1" className={classes.screen}/>
                                </div>
                            </div>
                            <div
                                className={active === 1 ? classes.activePanel : classes.panel}
                            >
                                <div className={classes.content}>
                                    <Imgix src="/itinker-assets/Download/Screenshots/screen2.png" alt="screen2" className={classes.screen}/>
                                </div>
                            </div>
                            <div
                                className={active === 2 ? classes.activePanel : classes.panel}
                            >
                                <div className={classes.content}>
                                    <Imgix src="/itinker-assets/Download/Screenshots/screen3.png" alt="screen3" className={classes.screen}/>
                                </div>
                            </div>
                            <div
                                className={active === 3 ? classes.activePanel : classes.panel}
                            >
                                <div className={classes.content}>
                                    <Imgix src="/itinker-assets/Download/Screenshots/screen4.png" alt="screen4" className={classes.screen}/>
                                </div>
                            </div>
                            <div
                                className={active === 4 ? classes.activePanel : classes.panel}
                            >
                                <div className={classes.content}>
                                    <Imgix src="/itinker-assets/Download/Screenshots/screen5.png" alt="screen4" className={classes.screen}/>
                                </div>
                            </div>
                            <div
                                className={active === 5 ? classes.activePanel : classes.panel}
                            >
                                <div className={classes.content}>
                                    <img src="/itinker-assets/Download/Screenshots/screen6.png" alt="screen4" className={classes.screen}/>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                {/* <div className={classes.subNav}>
          <Slider
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            infinite={true}
            slidesToShow={3}
            nextArrow={<CustomArrow />}
            prevArrow={<CustomArrow direction="left" />}
            afterChange={this.afterChange}
            // autoplay
          >
            {[0, 1, 2, 3, 4, 5].map((item) => {
              return (
                <div className={classes.panel1} key={item}>
                  {active === item ? (
                    <img src={dot1} alt="dot" />
                  ) : (
                    <img src={dot2} alt="dot" />
                  )}
                </div>
              );
            })}
          </Slider>
        </div> */}
            </div>
        );
    }
}

MobileScreens.propTypes = {
    width: PropTypes.oneOf(widthValues),
};
export default withWidth()(
    withStyles(styles, {withTheme: true})(MobileScreens)
);
