import { styles as useStyles } from './styles';
import React from "react";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import {useHistory} from "react-router-dom";
import Imgix from "react-imgix";

const NoMatch = () => {
  const classes = useStyles();
    let history = useHistory();
  return (
   <>
       <div className={classes.main}>
           <Imgix
               src="/itinker-assets/NoMatch/404.png"
               alt="404" className={classes.mission}
           />
       </div>
       <div className={classes.subText}>
           Oops! Page Not Found
       </div>
       <PrimaryButton
           text="BACK TO HOME SCREEN"
           btnClass={classes.btn}
           postIcon
           onClick={() => history.push('/')}>
       </PrimaryButton>
   </>
  );
};

export default NoMatch;
