import { makeStyles } from '@material-ui/core/styles';
import {Constants} from "../../../utils/Constants";
export const styles = makeStyles((theme) => ({
  main: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 60,
    position: 'relative',
    zIndex: 1,
  },
  overlay: {
    position: 'absolute',
    zIndex: 20,
    display: 'flex',
    //backgroundColor: 'rgba(59, 112, 255, 0.6)',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
  },
  mainText: {
    fontSize: 42,
    color: theme.palette.other.white,
    fontFamily: 'OpenSansBold',
    width: '50vw',
    marginTop: '12vh',
    marginBottom: '1vh',
    [theme.breakpoints.down('xs')]: { 
      fontSize: 21, 
      marginTop: '34%', 
      fontFamily: 'OpenSansSemiBold',
      width: '300px'
     },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 37,
      width: 'auto',
      marginTop: '42%'
    },
  },
  subText: {
    fontSize: 22,
    color: theme.palette.other.white,
    width: '70vw',
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      width: '78vw',
      fontSize: 16, 
      textAlign: 'left',
      fontFamily: 'OpenSansRegular',
      marginTop: '6%'
    },
    [theme.breakpoints.between('sm','md')]: {
      width: '83vw',
      fontSize: 20, 
      textAlign: 'left',
      fontFamily: 'OpenSansRegular',
      
    }
  },
  back: {
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.between('sm', 'md')]: {
      height: '20vh'
    }
  },
  videoBack: {
    backgroundImage: `url(${Constants.image_cdn}/itinker-assets/Download/ApplicationVideobg.jpg)`,
    objectFit: 'cover',
    width: '100%',
    height: '100vh',
    zIndex: 1,
    position: 'relative',
    
  },
  blurSpace: {
    width: 350,
    backgroundColor: 'rgba(59, 112, 255, 0.9)',

    [theme.breakpoints.between('sm', 'md')]: {
      width: '100%',
      height: 200,
    },
  },
  blurSection: {
    width: 500,
    padding: '147px 40px 80px 80px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 40px 80px 40px',
      width: '88%',
    },
  },
  flex: {
    display: 'flex',
    marginTop: '10px',
    marginLeft: '-8%',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 10,
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: -104,
      marginLeft: 172
    }
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.fontColor,
    marginTop: '-2%',
    border:"none",
    width: 300,
    height: 45,
    borderRadius: 9,
    fontSize: 18,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.other.white,
    },
    zIndex: 2,
    [theme.breakpoints.down('xs')]:{
      width: 162, 
      height: 43,
      marginTop: '-32%',
      marginLeft: '-32%'
    },
    [theme.breakpoints.between('sm','md')]: {
      marginLeft: '-63%', 
      marginTop: '-110%'
    },
  },
  playSection: {
    textAlign: 'center',
    border: "none",
    color: theme.palette.other.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '30%',
    marginLeft: '-37%',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      margin: '0% 8%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '16% 8%',
    },
    '& p': { fontSize: 30, fontFamily: 'OpenSansBold' },
    '& img': {
      width: 75,
    },
  },
}));
