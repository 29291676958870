import React, {useEffect, useRef} from 'react';
import {styles as useStyles} from './styles';
import DownloadAppBanner from '../../components/DownloadApp/DownloadAppBanner/DownloadAppBanner';
import ApplicationFeatures from '../../components/DownloadApp/ApplicationFeatures/ApplicationFeatures';
import LeftPhoneSection from '../../components/DownloadApp/LeftPhoneSection/LeftPhoneSection';
import RightPhoneSection from '../../components/DownloadApp/RightPhoneSection/RightPhoneSection';
import ApplicationVideo from '../../components/DownloadApp/ApplicationVideo/ApplicationVideo';
import MobileScreens from '../../components/DownloadApp/MobileScreens/MobileScreens';
import Reviews from "../../components/DownloadApp/Reviews/Reviews";
import {Helmet} from "react-helmet";
import useIsVisible from "../../components/Home/is-visible";


const DownloadApp = () => {
    const classes = useStyles();
    const elemRef = useRef();
    const isVisible = useIsVisible(elemRef);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div className={classes.main} ref={elemRef}>
            <Helmet>
                <title>iTinker - Application</title>
            </Helmet>
            <DownloadAppBanner/>
            <ApplicationFeatures/>
            <ApplicationVideo/>
            <LeftPhoneSection
                title="50+ Courses on Future Technology"
                text="Told via the life and experiences of our main characters- Abhay,
                Bani, Chinmay, Dia and Era.Our courses our spread over 10+
                Levels, made in collaboration with top industry professionals."
                imageSrc="/itinker-assets/Download/phone7.png"
            />
            <RightPhoneSection
                title="Industry Interface"
                text="Students progressing through the curriculum are provided several
              collaboration opportunities with the industry via projects,
              Internships and Incubation."
                imageSrc="/itinker-assets/Download/Industry Interface.png"
                isVisible={isVisible}
            />
            <LeftPhoneSection
                title="Verified Students Only"
                text="Every registration to the iTinker App is thoroughly verified by our
                account executives. Rest assured, your child will always be safe and comfortable
                with us. "
                imageSrc="/itinker-assets/Download/Verified Students Only.png"

            />
            <RightPhoneSection
                title="Gamified Platform"
                text="Throughout the iTinker journey, there are numerous points, Achievements, and
                Badges to keep your child motivated. Global Leaderboards keep them inspired to always be better. "
                imageSrc="/itinker-assets/Download/Gamified Platform.png"
                isVisible={isVisible}

            />
            <LeftPhoneSection
                title="Social Feed"
                text="In addition to the curriculum, social feed and dedicated groups provide children with ample
                 opportunities to learn from one another and share common interests. "
                imageSrc="/itinker-assets/Download/Social Feed.png"

            />
            <RightPhoneSection
                title="Guided Development Path"
                text="Periodic assessments on future readiness along with personalized progress maps help the children
                 to always know the next step in their journey."
                imageSrc="/itinker-assets/Download/Guided Development Path.png"
                isVisible={isVisible}



            />
            <MobileScreens/>
            <Reviews />
        </div>
    );
};

export default DownloadApp;
