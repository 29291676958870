import {CustomerLeadModel, CustomerLeadModelToJson} from "../models/UserModels";
import {Api} from "./Api";
import {Constants} from "../utils/Constants";

//add customers lead
export const addLead = async (name, email, phone, source, comments) => {
    let customer = new CustomerLeadModel();
    customer.name = name;
    customer.email = email;
    customer.phone = phone;
    customer.source = source;
    customer.status = "initiated";
    customer.comments = comments ? comments : "";
    return await Api(Constants.addCustomerLead, {}, CustomerLeadModelToJson(customer));
}

