import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  videoContainer: {
    border: `12px solid ${theme.palette.other.white}`,
    borderRadius: 20,
    width: 532,
    height: 336,
    marginTop: '10%',
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: 150,
    },
  },
  schoolVideoContainer: {
    border: `12px solid ${theme.palette.other.white}`,
    borderRadius: 20,
    width: 450,
    height: 250,
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: 150,
    },
  },
  homeVideoContainer: {
    border: `6px solid ${theme.palette.other.white}`,
    borderRadius: 20,
    marginLeft: '20',
    width: 550,
    height: 300,
    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: 160,
      marginTop: '8%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: '-18%',
      
    },
  },
}));
