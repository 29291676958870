import {makeStyles} from '@material-ui/core/styles';
import webHeaderImage from '../../../assets/homepage/HeaderImage.png';
import tabHeaderImage from '../../../assets/homepage/ipadViewHeader.png';
import mobileHeaderImage from '../../../assets/homepage/mobileViewHeader.png';

export const styles = makeStyles((theme) => ({
    main: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 680,
        },
        [theme.breakpoints.between('sm', '970')]: {
            height: 1000,
        }
    },
    bgImg: {
        backgroundSize: 'contain',
        width: '100%',
        height: "834.5px",
        overflow: "hidden",
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${webHeaderImage})`,
        [theme.breakpoints.down('sm')]: {
            height: 750,
            backgroundImage: `url(${mobileHeaderImage})`,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: 1070,
            backgroundImage: `url(${tabHeaderImage})`,
        },
    },
    container: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'flex-start',
        gap: "9.5rem",
        paddingTop: "4%",
        marginLeft: '6%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 0,
            position: 'relative',
            bottom: '10%',
            marginInline: '5%'
        },
        [theme.breakpoints.between('sm', '970')]: {
            alignItems: 'center',
            marginTop: '6%',
        }
    },
    title: {
        height: 150,
        fontSize: 42,
        lineHeight: 1.2,
        textAlign: 'left',
        fontStretch: "normal",
        letterSpacing: "normal",
        fontFamily: 'Montserrat-Medium',
        fontWeight: 500,
        color: theme.palette.other.black,
        [theme.breakpoints.down('sm')]: {
            height: 90,
            width: 304,
            fontSize: 25,
            fontWeight: 500,
            position: 'relative',
            letterSpacing: 'normal',

        },
        [theme.breakpoints.between('sm', '970')]: {
            textAlign: 'center',
            fontSize: 35,
            width: 504,
            height: 84,
        },
    },
    title2: {
        fontSize: 18,
        height: 22,
        marginTop: "2.5%",
        fontFamily: 'Montserrat-Regular',
        textAlign: 'left',
        letterSpacing: '2.52px',
        opacity: 0.8,
        color: theme.palette.other.black,
        [theme.breakpoints.down('sm')]: {
            width: 346,
            height: 16,
            fontSize: 13,
            letterSpacing: 1.82,
            color: 'rgba(0, 0, 0, 0.86)',
        },
        [theme.breakpoints.between('sm', '970')]: {
            fontSize: 18,
            textAlign: 'center',
            width: '100%',
            letterSpacing: 2.52,
            height: 22,
        },
    },
    leftContainer: {

        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginLeft: "3px",
    },
    flexBtn: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '11%',
        gap: 18,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        [theme.breakpoints.between('sm', '970')]: {
            width: 501,
            justifyContent: 'center'
        },
    },
    successPathText: {
        color: theme.palette.primary.starColor,
        fontWeight: "bold",
    },
    kidImageSection:
        {
            paddingTop: "19px",
        },
    lifeAtText: {
        textDecoration: "underline",
        fontSize: 18,
        color: "#252641",
        opacity: 0.8,
        fontFamily: 'Montserrat-Medium',
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            fontWeight: 500,
            color: "#252641",
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 13,
            height: 22,
        }
    },
    playBtn: {
        boxShadow: "1.5px 15px 45px 0 rgba(61, 155, 185, 0.1)",
        width: 60,
        height: 60,
        [theme.breakpoints.down('sm')]: {
            width: 46,
            height: 46,

        },
        [theme.breakpoints.down('sm', '970')]: {
            width: 62.1,
            height: 62.1
        },
        [theme.breakpoints.down('xs')]: {
            width: 46,
            height: 46,
        },

    },
    triangle: {
        position: "relative",
        width: 54,
        left: "88%",
        top: "12%",
        [theme.breakpoints.down('sm')]: {
            width: 34.3,
            height: 117.5,
            position: "relative",
            left: "15.5rem",
            top: "3.3rem",

        },
        [theme.breakpoints.between('sm', '970')]: {
            width: 46.7,
            height: 155.5,
            left: '26.5rem',
            top: '4.3rem'
        }

    },
    kidImage: {
        width: 595,
        height: 666,
        paddingTop: 15,
        [theme.breakpoints.down('sm')]: {
            width: 351.2,
            height: 383,
            paddingTop: '10%',
        },
        [theme.breakpoints.down('sm', '970')]: {
            width: 520.8,
            height: 560.8,
            paddingTop: 0,
        }
    }
}));
