import {makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
    main: {

        backgroundColor: theme.palette.other.offWhite,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            // width: '85vw',
        },
        [theme.breakpoints.up('sm')]:
            {
                // padding: '75px 55px 39px 60px',
            }
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        justifyItems: 'center',
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: 40,
        },
    },
    subText: {
        fontSize: 15,
        fontFamily: 'Montserrat-Regular',
        color: '#2d2e2e',
        textAlign: 'left',
        marginTop: 4,
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        height: 25,
    },
    subText1: {
        fontSize: 12,
        width: 700,
        fontFamily: 'Montserrat, sans-serif',
        color: '#1e2833',
        textAlign: 'justify',
        [theme.breakpoints.down('xs')]: {
            width: '70vw',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 12,
        },
    },
    subInfoText: {
        fontSize: 19,
        color: theme.palette.secondary.headFontColor,
        alignItems: 'left',
    },
    heading: {
        fontFamily: 'Montserrat-SemiBold',
        [theme.breakpoints.up('sm')]: {
            width: 122,
            fontSize: 15,
            color: '#2d2e2e',
        },
    },
    headingSpace: {
        fontSize: 21,
        color: theme.palette.other.blue,
        marginBottom: 15,
        fontFamily: 'Montserrat, sans-serif',
    },
    topSpace: {
        marginTop: 31,
        marginBottom: 10,
    },
    followSpace: {
        marginBottom: 10,
        fontSize: 15,
        fontFamily: 'Montserrat-SemiBold',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            textAlign: "center"
        },
        [theme.breakpoints.between('sm', 'md')]: {
            textAlign: 'left'
        }
    },
    inforList: {
        listStyle: 'none',
        paddingLeft: 0,
        marginBottom: 0,
        marginTop: 6,
    },
    infoSection: {
        display: 'flex',
        marginTop: 30,
    },
    infoHead: {
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            textAlign: "center"
        },
        [theme.breakpoints.between('sm', 'md')]: {
            textAlign: 'left'
        }
    },
    linkSection: {
        margin: '30px 0 0 130px',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    title: {
        flexGrow: 1,
        paddingTop: 2,
        height: 30,
        margin: '7px 0px 30px 8px',
        fontFamily: 'Milkshake',
        fontSize: 40,
        color: theme.palette.other.blue,

    },
    title1: {
        flexGrow: 1,
        paddingTop: 2,
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 35,
        color: theme.palette.other.blue,
    },
    downloadSection: {
        marginRight: 300,
    },
    quickInfo: {
        [theme.breakpoints.up('sm')]: {
            width: 500,
            marginRight: 55,
            paddingRight: 20,
        },
    },
    aboutInfo: {
        [theme.breakpoints.up('sm')]: {
            marginRight: 60,
            marginLeft: 50,
        },
    },
    followUsInfo: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 40,
            marginRight: 40,
            width: 300,
        },
    },
    appLinks: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '-20px',
            marginTop: '1%',
            marginRight: '2%',
        },
    },
    imgSettings: {
        width: "140px",
        height: "54.2px",
        [theme.breakpoints.down('sm')]: {
            height: '48px',
            width: '130px',
            marginLeft: '10px',
            flexDirection: "row",
            display: "inline-block",
        },
    },
    imgSettings1: {
        height: '15px' + '!important',
        width: '15px' + '!important',
        marginRight: 4,
        flexDirection: "row",
        display: "inline-block",
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: '5px',
            width: '130px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100px',
        },
    },
    imgSettings2: {
        height: '17px' + '!important',
        width: '9px' + '!important',
        marginRight: 10,
        flexDirection: "row",
        display: "inline-block",
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: '5px',
            width: '130px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100px',
        },
    },
    imgSettings3: {
        height: '14px' + '!important',
        width: '14px' + '!important',
        marginRight: 5,
        flexDirection: "row",
        display: "inline-block",
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: '5px',
            width: '130px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100px',
        },
    },
    storeIcon:{
      margin: "auto",
    },
    imgSettings4: {
        height: '15px' + '!important',
        width: '19px' + '!important',
        flexDirection: "row",
        display: "inline-block",
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: '5px',
            width: '130px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100px',
        },
    },
    copyright: {
        color: theme.palette.secondary.headFontColor,
        fontSize: 19,
        textAlign: 'center',
    },
    social: {
        gap: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        flexGrow: 0,
        paddingTop: 13,
        alignItems: "flex-start",
        '& img': {
            width: '15px', height: '12px'
        },
    },
    socialIcon: {
        cursor: "pointer",
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        textDecoration: "none",
        gap: 10,
        marginBlock: "2.5%",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            // display: 'inline'
        }, [theme.breakpoints.between('sm', 'md')]: {
            justifyContent: 'flex-start'
            // display: 'inline'
        }
    },
    mobileSocialIcons: {
        display: "flex",
        flexDirection: "column",
        width: "90%",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "row",
            width: '100%'
        }
    },
    footerLink: {
        cursor: 'pointer',
    },
    infoArea: {
        display: 'flex',
        [theme.breakpoints.between('sm', 'md')]: {
            marginTop: '4%'
        }
    },
    webMain: {
        display: 'flex',
        justifyContent: "space-around",
        flexDirection: "column",
        width: "81%",
        alignItems: "flex-start",
        margin: "auto",
        paddingBottom: '5%',
        gap: 40,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            alignItems: "center",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            alignItems: "flex-start",
        }

    },
    bigContent: {
        fontSize: 18,
        fontFamily: "Montserrat-Regular",
        lineHeight: 1.8,
        textAlign: "justify",
        letterSpacing: "normal",
        color: "#000",
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 15,
        }
    },
    extra: {
        display: "flex",
        width: '100%',
        flexDirection: "row",
        gap: 215,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 30,
            alignItems: 'center',
            justifyContent: "center"
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: "100%",
            gap: 38,
            flexDirection: 'row',
            alignItems: "flex-start"
        }
    },
    column: {
        display: "flex",
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: "50%",
            alignItems: 'center',
            justifyContent: 'center'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            alignItems: 'flex-start'
        }
    },
    socialIconText: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 16,
        color: '#000',
        margin: 4,
        textAlign: "left",
    },
    rightSection: {
        marginLeft: -80,
        marginRight: 140,
        [theme.breakpoints.up('md')]: {
            width: "30%",
            marginLeft: 0,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: 40,
        }
    },
    tabMain: {
        display: 'inline'
    },
    MobViewrow2: {
        marginTop: '6%',
    },
    MobViewrow3: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            marginTop: '6%',
        },
    },
    appBtn: {
        [theme.breakpoints.up('sm')]: {
            marginTop: '3%',
        },
    },
    leftSection: {
        marginLeft: -115,
        [theme.breakpoints.up('md')]: {
            width: "24%"
        }
    },
    [theme.breakpoints.down('sm')]: {
        MobMain: {
            width: "100vw",
            backgroundColor: "#f6f9ff",
        },
        title:
            {
                textAlign: "center",
                marginTop: 0,
                paddingTop: 30,
            },
        appButton: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50,
            rowGap: 10,
        },
        quickInfo: {
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 12,
            marginTop: 36,
            fontFamily: "Montserrat-Bold",
        },
        aboutInfo: {
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 12,
            marginTop: 57,
            fontFamily: "Montserrat-Bold",
        },
        quickInfoHeading:
            {
                color: "#2d2e2e",
                fontWeight: 600,
                fontSize: 15,
                lineHeight: "normal",
                letterSpacing: "normal",
                fontStyle: "normal",
                fontStretch: "normal",
            },
        subText:
            {
                color: "#2d2e2e",
                fontSize: 15,
                lineHeight: "normal",
                letterSpacing: "normal",
                fontStyle: "normal",
                fontStretch: "normal",
                textAlign: "left",
                justifyContent: "center",
                fontFamily: "Montserrat-Regular",
                [theme.breakpoints.down('sm')]: {
                    textAlign: "center",
                },
                [theme.breakpoints.between('sm', 'md')]: {
                    textAlign: 'left'
                }
            },
        MobViewRow3: {
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            columnGap: 4,
        },
        followUsInfo: {
            color: "#2d2e2e",
            fontWeight: 600,
            fontSize: 15,
            lineHeight: "normal",
            letterSpacing: "normal",
            fontStyle: "normal",
            fontStretch: "normal",
            marginTop: 59,
            fontFamily: "Montserrat-Bold",
        },
        social: {
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 15,
            marginBottom: 15,
        },
        imgSetting:
            {
                width: 140,
                height: 51,
            },
        socialIcon:
            {
                width: 12,
                height: 25,
            },
    },

}));

export const footerTabView = makeStyles((theme) => ({

    tabMain: {
        height: "375px",
        width: "100% ",
        padding: "0",
        backgroundColor: "#f6f9ff",

    },
    title: {
        height: "44px",
        fontFamily: 'Milkshake',
        fontSize: 40,
        color: theme.palette.other.blue,
    },

    subMain: {
        display: "flex",
        justifyContent: "space-between",
        padding: "71px 51px 0 54px",
    },
    heading: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
    },
    textSection: {
        marginBottom: "30.6px"
    },
    appLinks: {
        marginBottom: "4.2px",
    },
    infoHead: {
        fontFamily: "Montserrat-SemiBold",
        fontSize: "15px",
        fontWeight: "600",
        color: "#2d2e2e",
        marginBottom: "12.8px",
    },
    inForList: {
        listStyle: 'none',
        paddingLeft: 0,
        marginBottom: 0,
    },
    subText: {
        fontFamily: "Montserrat-Regular",
        fontSize: "15px",
        color: "#2d2e2e",
        marginBottom: "12.8px",

    },
    appBtn: {
        display: "flex",
        flexDirection: "column",
    },
    imgSettings: {
        width: "140px",
        height: "54.2px",
    },

    infoArea: {
        display: "flex",
        width: "280px",
        justifyContent: "space-between",

    },
    Icon: {
        width: "30px",
        height: "30px",
        marginRight: "11px",
    },
    IconsSocialMedia: {
        marginLeft: "442px",
    },
    followSpace: {
        fontFamily: "Montserrat-SemiBold",
        fontSize: "15px",
        fontWeight: "600",
        color: "#2d2e2e",
        marginBottom: "12.8px"
    }

}))

