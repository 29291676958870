import { makeStyles } from '@material-ui/core/styles';
export const styles = makeStyles((theme) => ({
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 60,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mainText: {
    fontSize: 38,
    color: theme.palette.secondary.headFontColor,
    fontFamily: 'OpenSansBold',
  },
  subText: {
    fontSize: 19,
    color: theme.palette.secondary.paraFontColor,
    marginTop: 30,
  },
  subTextSection: {
    textAlign: 'center',
    width: 700,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  topSection: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  innerSection: {
    padding: '70px 104px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '40px 40px',
    },
  },
  sectionImg: { width: 'auto', height: 188 },
  valueContainer: {
    padding: 30,
    width: 230,
    height: 170,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  visionHeading: {
    fontSize:   23,
    width: 300,
    fontFamily: 'OpenSansRegular',
    fontWeight: '600',
    marginLeft: -30,
    color: theme.palette.secondary.headFontColor,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%', 
      fontFamily: 'OpenSansSemiBold',
      fontSize: 21
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 18, 
      marginLeft: '-50px', 
      fontFamily: 'OpenSansSemiBold',
    }
  },
}));
