import dotenv from 'dotenv';
import firebase from "firebase";
import {WebsiteStrings} from "../utils/WebsiteStrings";
import {Constants} from "../utils/Constants";
import {Api} from "./Api";
import {UserModelFromJson, UserModelToJson} from "../models/UserModels";
import {CourseModelFromJSON} from "../models/CoursesModels";
import {IpAddressDataModelFromJSON} from "../models/IpAdressDataModels";

const publicIp = require('public-ip');


// set the environment
dotenv.config()
require('firebase/auth')

// initializing Website firebase with the config values of the app
export const WebsiteFirebaseSetup = firebase.initializeApp(process.env.NODE_ENV !== 'production' ? {
    apiKey: "AIzaSyBX2t0GDcxbVNvlRnxB6dCXq9lhcSZsE5c",
    authDomain: "itinker-website-official.firebaseapp.com",
    projectId: "itinker-website-official",
    storageBucket: "itinker-website-official.appspot.com",
    messagingSenderId: "1083971653825",
    appId: "1:1083971653825:web:88f724f6388cce27cd4440",
    measurementId: "G-JWDE3CDZ65"
} : {
    apiKey: "AIzaSyDuQzeX5G_6izBwPeDDBsLKWsSfL6TCcGo",
    authDomain: "itinkerwebsite-prod.firebaseapp.com",
    projectId: "itinkerwebsite-prod",
    storageBucket: "itinkerwebsite-prod.appspot.com",
    messagingSenderId: "944807292274",
    appId: "1:944807292274:web:4dfd1969057ccd932281eb",
    measurementId: "G-ZTD1BHDC0H"
});
// assigning firestore to a variable named db
const db = firebase.firestore();
const storage = firebase.storage();


export const NativeAppFirebaseSetup = firebase.initializeApp(process.env.NODE_ENV !== 'production' ? {
    apiKey: "AIzaSyA1G-ryJJqRD1VmvAWIaJxLFq6gfjazYuw",
    authDomain: "itinker-native-app-admin.firebaseapp.com",
    projectId: "itinker-native-app-admin",
    storageBucket: "itinker-native-app-admin.appspot.com",
    messagingSenderId: "576453475454",
    appId: "1:576453475454:web:9de5afc047c76229db2c2f",
    measurementId: "G-QCGC36BBWM"
} : {
    apiKey: "AIzaSyA8Y4dGcDwBx4XvTKR2DvII17x0bnm9utc",
    authDomain: "itinker-native-app-prod.firebaseapp.com",
    databaseURL: "https://itinker-native-app-prod-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "itinker-native-app-prod",
    storageBucket: "itinker-native-app-prod.appspot.com",
    messagingSenderId: "559303925588",
    appId: "1:559303925588:web:034e275c105fb833c7f2c1",
    measurementId: "G-0DXN4ZWML0"
}, 'native-app-firebase');


export const SignInToNativeAppFirebase = async () => {
    return await NativeAppFirebaseSetup.auth().signInAnonymously();
}
export const NativeAppStorage = () => {
    return NativeAppFirebaseSetup.storage();
}

//To check if current user (if any) is authenticated or not
export const CheckUserAuth = async () => {
    return await Api(Constants.checkAuth, {}, "");
}

//To send a given OTP to the user
export const SendOTP = async (countryCode, phone) => {
    let otp = generateOTPNumber();
    let res = await Api(Constants.sendOTP, {}, "", {
        "countrycode": countryCode.toString(),
        "phone": phone.toString(),
        "otp": otp.toString(),
    })
    let existingUser = false;
    let existingFRSUser = false
    let otpSent = false;
    if (res !== "" && res !== null) {
        existingUser = res.existinguser;
        existingFRSUser = res.existingsource;
        otpSent = res.otp_sent;
    }
    return [otp, existingUser, existingFRSUser, otpSent];
}

//To login an existing user to the application
export const LoginUser = async (countryCode, phone) => {
    const ipAddress = await publicIp.v4();
    return await Api(Constants.login, {}, "", {
        "countrycode": countryCode.toString(),
        "phone": phone.toString(),
        "ipaddress": ipAddress === undefined ? "" : ipAddress.toString(),
    })
}

// function which adds new user information to DB
export const AddUserData = async (countryCode, phone) => {
    const ipAddress = await publicIp.v4();
    return await Api(Constants.addUser, {}, "", {
        "countrycode": countryCode.toString(),
        "phone": phone.toString(),
        "ipaddress": ipAddress === undefined ? "" : ipAddress.toString(),
    })
}

// function which updates data of user in the collection Users
export const UpdateUserData = async (userData) => {
    let res = await Api(Constants.updateUserData, {}, UserModelToJson(userData));
    if (res !== "" && res !== null) {
        userData = new UserModelFromJson(res)
    }
    return userData;
}


/**
 * Generates a random six digit OTP number
 */
function generateOTPNumber() {
    return Math.floor(100000 + Math.random() * 900000);
}


// This function is to sign in a user anonymously to website
export const SignInToWebsite = async () => {
    return await WebsiteFirebaseSetup.auth().signInWithEmailAndPassword('shaurya@itinker.in', 'Sparsh@123');
    // return await WebsiteFirebaseSetup.auth().signInAnonymously();
}

// This function is to get image urls from firebase
export const GetImageDownloadUrl = async (url) => {
    return await storage.refFromURL(url).getDownloadURL();
}


// This function is to get Featured Courses data from firebase
export const GetFeaturedCourseList = async () => {
    const coursesList = await Api(Constants.getCoursesList, {}, "");
    let featuredCourses = [];
    coursesList.forEach(course => {
        featuredCourses.push(CourseModelFromJSON(course));
    });
    return featuredCourses;
}

export const GetIpAddressData = async () => {
    const data = await Api(Constants.ipJson, {}, "");
    if (!data)
        return new IpAddressDataModelFromJSON({country_code: "DE"})
    return IpAddressDataModelFromJSON(data);
}


// This function is to get data of reviews of courses from firebase
export const GetCourseReviews = async () => {
    const snapshot = await db.collection(WebsiteStrings.Course_Reviews).orderBy("id").get();
    let reviews = [];
    snapshot.forEach(doc => {
        reviews.push({
            id: doc.data().id,
            name: doc.data().name,
            role: doc.data().role,
            description: doc.data().description,
            image: doc.data().image,
        });
    });
    return reviews;
}


// This function is to get data of blog pointers of courses from firebase
export const GetBlogFromID = async (id) => {
    const snapshot = await db.collection(WebsiteStrings.BlogsList).where("id", "==", id).limit(1).get();
    let blog = [];
    snapshot.forEach(doc => {
        blog.push({
            id: doc.data().id,
            name: doc.data().name,
            coverImage: doc.data().coverImage,
            creator: doc.data().creator,
            description: doc.data().description,
            picture: doc.data().picture,
            quote: doc.data().quote,
            saves: doc.data().saves,
            views: doc.data().views,
            like: doc.data().like,
            comments: doc.data().comments,
            blogPointers: doc.data().blogPointers,
            blogDescription: doc.data().blogDescription,
            browseArticlesPicture: doc.data().browseArticlesPicture,
        });
    });
    return blog[0];
}


// function to upload user image when changes by the user in the folder named by their UID
// return true if upload successfully else false
export const uploadImage = async (imageInfo, uid) => {
    let imagePath = "";
    try {
        const storageRef = NativeAppFirebaseSetup.storage().ref();
        const directory = storageRef.child('users/' + uid + '/' + imageInfo.name);
        const metaData = {cacheControl: 'max-age=60', customMetadata: {'userId': uid}};// if uploaded successfully, set the imagePath to be the path of the image in the bucket
        await directory.put(imageInfo, metaData)
            .then(() => {
                console.log("image uploaded successfully");
                imagePath = "/" + directory._delegate._location.path_;
            })
            .catch((err) => {
                console.log("error in upload file ", err);
                return null;
            });
    } catch (error) {
        console.log("error while uploading image to the bucket : ", error);
        return null;
    }
    return imagePath;
}
