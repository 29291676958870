import {makeStyles} from '@material-ui/core/styles';

export const AboutBannerStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: "#fff2e1",
        height: "580px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

    },
    Heading: {
        fontFamily: 'Montserrat-Bold',
        fontSize: " 52px",
        fontWeight: " bold",
        paddingTop: "117px",
        marginBottom: "14px",
        color: "#11246f",

    },
    Info: {
        fontFamily: 'Montserrat-Medium',
        fontSize: "24px",
        fontWeight: " 500",
        textAlign: "center",
        margin: "auto",
        width: "60%",
        marginBottom: "30px"

    },
    Image: {
        justifyContent: "center",
        marginTop: "24px",
        width: "100%",
        display: "flex",
    },
    heroImageBlur: {
        height: "478px",
        width: "69%",
        position: "absolute",
        zIndex: "-1",
        margin: "20px 0 0 29px",
        opacity: "0.5",
        borderRadius: "14px",
        filter: "blur(13px)",
    },
    heroImage: {
        display: "block",
        height: "478px",
        borderRadius: "14px",
        width: "75%",
    },

    ImageWeb: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        [theme.breakpoints.only('sm')]: {
            display: "none",
        },
        [theme.breakpoints.only('xs')]: {
            display: "none",
        },
    },
    ImageTab: {
        display: "none",
        [theme.breakpoints.only('sm')]: {
            display: "block",
        },
        [theme.breakpoints.only('xs')]: {
            display: "none",
        },
    },
    ImageMobile: {
        display: "none",
        [theme.breakpoints.only('sm')]: {
            display: "none",
        },
        [theme.breakpoints.only('xs')]: {
            display: "block",
        },
    },

    [theme.breakpoints.only('sm')]: {
        main: {
            marginBottom: "0px",
        },
        Heading: {
            fontSize: "30px",
        },
        Image: {
            marginTop: "47px",
        },
        Info: {
            fontSize: "18px",
            width: "80%",
            margin: "0",
        },
        heroImageBlur: {
            height: "416px",
            width: "702px",
        },
        heroImage: {
            height: "416px",
            width: "702px",
        },
    },
    [theme.breakpoints.only('xs')]: {
        main: {
            height: "353px",
        },
        Heading: {
            fontSize: "25px",
            paddingTop: "90px",
            marginBottom: "6px",
        },
        Info: {
            fontSize: "15px",
            width: "90%",
            margin: "2%",
            marginBottom: "17px",
        },
        heroImageBlur: {
            height: "236px",
            width: "291px",
        },
        heroImage: {
            height: "236px",
            width: "340px",
        },
    }
}));

export const SingleYearStyles = makeStyles((theme) =>
    (
        {
            singleCard: {
                display: "flex",
                flexDirection: "column",
                marginTop: "30%",
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        marginBottom: "170px"
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        marginTop: "65%",
                        marginBottom: "100px"
                    },
            },
            cardSelected: {
                display: "flex",
                backgroundColor: "#f6f9ff",
                flexDirection: "row",
                height: "362px"
            },
            image: {
                width: 483,
                height: 362,
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        display: "none",
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        display: "none",
                    },
            },
            iconImageBlock:
                {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "87%",
                    [theme.breakpoints.only('xs')]:
                        {
                            width: "50%",
                        },
                },
            icon: {
                [theme.breakpoints.up('sm')]:
                    {
                        width: 250,
                        height: 180,
                        paddingLeft: 60,
                    },
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        width: 193,
                        height: 140,
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        width: 100,
                    },
            },
            arrowSelected: {
                width: "17px",
                height: 38,
                paddingLeft: 12,
                [theme.breakpoints.only('xs')]:
                    {
                        paddingLeft: 6,
                    },
            },
            lineSelected: {
                height: "372px",
                opacity: 1,
                color: "#000",
                marginLeft: "0",
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        height: "360px",
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        height: "360px",
                    },
            },
            info: {
                [theme.breakpoints.up('md')]:
                    {
                        display: "flex",
                        width: "90%",
                        flexDirection: "column",
                        textAlign: "center",
                        marginBlock: "79px",
                        marginRight: 44,
                    },
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        marginInline: "5%",
                        paddingBlock: "4%",
                        width: "100%",
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        marginBlock: "16%",
                        textAlign: "center",
                        width: "66%",
                    },
            },
            heading: {
                fontSize: 25,
                fontFamily: 'Montserrat-Bold',
                textAlign: "left",
                fontWeight: "bold",
                lineHeight: 1.51,
                color: "#11246f",
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        fontSize: "22px",
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        fontSize: 16,
                        color: "#11246f",
                    },
            },
            description: {
                fontSize: 18,
                fontWeight: 500,
                marginTop: "38px",
                textAlign: "left",
                lineHeight: 1.8,
                color: "#000",
                fontFamily: 'Montserrat-Regular',
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        marginTop: "0",

                    },
                [theme.breakpoints.only('xs')]:
                    {
                        fontSize: 14,
                        width: "210px",
                        marginTop: "15px",
                        fontWeight: "normal",
                    },
            },
            cardNotSelected: {
                flexDirection: "row",
                display: "flex",
                // opacity: 0.3,
                width: "100%",
            },
            imageNotSelected: {
                visibility: "collapse",
                width: "34%",
                opacity: 0.3,
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        display: "none",
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        display: "none",
                    },
            },
            iconImageBlockNotSelected:

                {
                    opacity: 0.3,
                    display: "flex",
                    [theme.breakpoints.only('xs')]:
                        {
                            width: "50%",
                        },
                },
            iconNotSelected: {
                width: "34%",
                height: "30%",
                opacity: 0.3,
                paddingBlock: "14%",
                paddingLeft: 205,
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        width: "41%",
                        height: " 46%",
                        paddingLeft: "101px",
                        paddingBlock: "17.5%",

                    },
                [theme.breakpoints.only('xs')]:
                    {
                        width: "55%",
                        height: "35%",
                        paddingLeft: "18%",
                        paddingTop: "51%",
                    },
            },
            arrowNotSelected: {
                opacity: 0.5,
                width: 17,
                height: 35,
                paddingTop: 99,
                paddingLeft: 13,
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        paddingTop: 121,
                        paddingLeft: 12.6,
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        paddingLeft: 7,
                        paddingTop: "54%",
                    },
            },
            lineNotSelected: {
                height: "362px",
                color: "#000",
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        height: "269px",
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        height: "200px",
                    },
            },
            headingNotSelected: {
                opacity: 0.3,
                fontSize: 25,
                fontFamily: 'Montserrat-Bold',
                textAlign: "left",
                fontWeight: "bold",
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        paddingBlock: "33%",
                        marginInline: "-47%",
                        fontSize: "18px"
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        fontSize: 16,
                        paddingTop: 32,
                    },
            },
        }
    ));
export const OurVision = makeStyles((theme) =>
    ({
        ourVisionBackground: {
            [theme.breakpoints.up("sm")]:
                {
                    height: 800,
                    display: "flex",
                    marginTop: 100,
                },
            [theme.breakpoints.between('sm', 'md')]:
                {
                    height: 503,
                    marginTop: 0,
                },
            [theme.breakpoints.only("xs")]:
                {
                    height: 594,
                    marginTop: 20,
                },
        },
        ImageSectionOfOurVisionColored: {
            backgroundColor: "#fff2e1",
        },
        ourVision:
            {
                [theme.breakpoints.up("md")]:
                    {
                        width: "44%",
                        margin: "228px 0px 251px 120px",
                    },
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        paddingLeft: "44px",
                        paddingTop: "63px",
                        width: "50%",
                        fontSize: 18,
                    },
                [theme.breakpoints.only("xs")]:
                    {
                        width: "90%",
                        paddingTop: 50,
                        marginInline: "5%",
                    },
            },
        ourVisionHeading:
            {
                color: "#11246f",
                fontWeight: "bold",
                fontSize: "30px",
                fontFamily: "Montserrat-Bold",
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        fontSize: 25,
                    },
                [theme.breakpoints.only("xs")]:
                    {
                        fontSize: "18px",
                    },
            },
        ourVisionDescription: {
            textAlign: "justify",
            marginTop: 40,
            fontSize: 22,
            fontWeight: 500,
            color: "#000",
            fontStretch: "normal",
            lineHeight: 1.8,
            fontFamily: "Montserrat-Medium",
            letterSpacing: "normal",
            [theme.breakpoints.between('sm', 'md')]:
                {
                    fontSize: 18,
                    width: "92%",
                    textAlign: "left",
                    lineHeight: 1.5,
                },
            [theme.breakpoints.only("xs")]:
                {
                    textAlign: "justify",
                    marginTop: 20,
                    fontSize: 13,
                    fontStretch: "normal",
                    lineHeight: 1.5,
                    fontWeight: 500,
                    color: "#000",
                    letterSpacing: "normal",
                },
        },
        ImageSectionOfOurVision:
            {
                width: "56%",
                position: "relative",
                left: "1%",
                borderRadius: 12,
                [theme.breakpoints.between('sm', 'md')]:
                    {
                        width: "50%",
                    },
                [theme.breakpoints.only('xs')]:
                    {
                        position: "relative",
                        width: 0,
                        borderRadius: 12,
                        marginInline: "5%",
                    },
            },
        ourVisionImage1: {
            position: "relative",
            height: "42.5%",
            width: "40%",
            top: 228,
            left: "20%",
            zIndex: 1,
            [theme.breakpoints.between("sm", "md")]:
                {
                    width: 173,
                    height: 202,
                    top: "8.5rem",
                    left: "3.2rem",
                },
            [theme.breakpoints.only("xs")]:
                {
                    width: 138,
                    height: 162,
                    top: "6.8rem",
                    left: "4rem",
                },
        },
        ourVisionImage2: {
            position: "relative",
            height: 223,
            width: 292,
            right: "2%",
            zIndex: 0,
            [theme.breakpoints.between("sm", "md")]:
                {
                    width: 173,
                    height: 132,
                    right: "11%",

                },
            [theme.breakpoints.only("xs")]:
                {
                    width: 138.8,
                    height: 106,
                    left: '8rem',
                    bottom: "7rem",
                },
        },
        ourVisionImage3: {
            position: "relative",
            width: 250,
            height: 240,
            top: "15%",
            zIndex: 1,
            left: "42%",
            [theme.breakpoints.between("sm", "md")]:
                {
                    width: 148,
                    height: 142,
                    top: "4.8rem",
                    left: "8.5rem",
                },
            [theme.breakpoints.only("xs")]:
                {
                    width: 119,
                    height: 114,
                    left: "8.5rem",
                    bottom: "4rem",
                },
        },
        ourMission: {
            [theme.breakpoints.between("sm", "md")]:
                {
                    flexDirection: "row-reverse",
                },
        }
    }));

export const OurValues = makeStyles((theme) =>
    ({
        OurValuesBackground: {
            [theme.breakpoints.up("md")]:
                {
                    backgroundColor: "#fff2e1",
                    height: 650,
                    padding: "8%",
                    display: "flex",
                    columnGap: 136,
                    paddingRight: "100px",
                },
            [theme.breakpoints.between("sm", 'md')]:
                {
                    display: "flex",
                    backgroundColor: "#fff2e1",
                    paddingInline: "8%",
                    columnGap: "11%",
                    height: "503px",
                },
            [theme.breakpoints.only("xs")]:
                {
                    backgroundColor: "#fff2e1",
                    height: 600,
                },
        },
        OurValues: {
            width: "86%",
            marginTop: "10%",
            [theme.breakpoints.between("sm", 'md')]:
                {
                    width: "100%",
                },
            [theme.breakpoints.only("xs")]:
                {
                    width: "90%",
                    paddingTop: "10%",
                    marginInline: "5%",
                },
        },
        OurValuesHeading: {
            color: "#11246f",
            fontSize: "30px",
            fontWeight: "bold",
            fontFamily: "Montserrat-Bold",
            [theme.breakpoints.between("sm", 'md')]:
                {
                    fontSize: "25px",
                },
            [theme.breakpoints.only("xs")]:
                {
                    fontSize: "18px",
                },
        },
        OurValuesDescription: {
            textAlign: "justify",
            marginTop: 40,
            fontSize: 22,
            fontStretch: "normal",
            lineHeight: 1.8,
            fontWeight: 500,
            color: "#000",
            letterSpacing: "normal",
            fontFamily: "Montserrat-Medium",
            [theme.breakpoints.between("sm", 'md')]:
                {
                    fontSize: 18,
                    color: "#000",
                    fontWeight: 500,
                    textAlign: "left",
                    lineHeight: 1.5,
                },
            [theme.breakpoints.only("xs")]:
                {
                    textAlign: "justify",
                    marginTop: 20,
                    fontSize: 14,
                    fontStretch: "normal",
                    lineHeight: 1.5,
                    fontWeight: 500,
                    color: "#000",
                    letterSpacing: "normal",
                },
        },
        OurValuesImageAndData:
            {
                display: "flex",
                columnGap: 20,
                marginTop: 80,
                marginBottom: -30,
                [theme.breakpoints.between("sm", 'md')]:
                    {
                        marginTop: 34,
                        marginBottom: 0,
                    },
                [theme.breakpoints.only("xs")]:
                    {
                        marginInline: "9%",
                        marginTop: "55px",
                    },
            },
        OurValueImages:
            {
                width: 76,
                height: 76,
                [theme.breakpoints.between("sm", 'md')]:
                    {
                        width: 56,
                        height: 56,
                    },
                [theme.breakpoints.only("xs")]:
                    {
                        width: 56,
                        height: 56,
                    },
            },
        OurFourValues:
            {
                textAlign: "left",
            },
        FourValuesHeading: {
            width: 197,
            height: 30,
            fontSize: 25,
            color: "#000",
            opacity: 0.9,
            fontWeight: "bold",
            fontFamily: "Montserrat-Bold",
            [theme.breakpoints.between("sm", 'md')]:
                {
                    fontSize: 15,
                },
            [theme.breakpoints.only("xs")]:
                {
                    fontSize: 14,
                    marginBottom: "-10px",
                },
        },
        FourValuesDescription: {
            [theme.breakpoints.up("md")]:
                {
                    width: "89%",
                    height: 36,
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#000",
                    marginTop: 10,
                    fontFamily: "Montserrat-Medium",
                    fontSize: 18,
                },
            [theme.breakpoints.between("sm", 'md')]:
                {
                    fontSize: 14,
                    width: "90%",
                },
            [theme.breakpoints.only("xs")]:
                {
                    width: "87%",
                    fontSize: 13,
                    fontFamily: "Montserrat-Medium",
                },
        },
    }));

export const MeetTheTinkers = makeStyles((theme) =>
    (
        {
            Title: {
                fontSize: 30,
                fontFamily: 'Montserrat-Bold',
                fontWeight: 800,
                textAlign: "center",
                lineHeight: "normal",
                margin: "210px 0px 30px 0px",
                [theme.breakpoints.only("xs")]:
                    {
                        fontSize: 18,
                        margin: "100px 0px 15px 0px",
                    },
            },
            MeetThe:
                {
                    color: "#11246f",
                },
            Tinkers:
                {
                    color: "#ff8501",
                    fontWeight: 600,
                },
            CardStyling:
                {
                    [theme.breakpoints.up("md")]:
                        {
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            columnGap: 42,
                            marginInlineStart: 112,
                        },
                    [theme.breakpoints.between('sm', 'md')]:
                        {
                            display: "flex",
                            flexWrap: "wrap",
                            columnGap: "6%",
                            justifyContent: "center",
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        },
                },
            MeetTheTinkerCard:
                {
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 40,
                    [theme.breakpoints.only("xs")]:
                        {
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        },
                },
            ImagesOfTinkersDiv:
                {
                    background: "#FF8501",
                    [theme.breakpoints.up("sm")]:
                        {
                            width: 265,
                            height: 300,
                        },
                    [theme.breakpoints.between('sm', 'md')]:
                        {
                            background: "#FF8501",
                            width: 331,
                            height: 374.7,
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            background: "#FF8501",
                            height: 350,
                        },
                },
            hoveredImageOfTinkersDiv:
                {
                    background: "#FF8501",
                    [theme.breakpoints.up("sm")]:
                        {
                            background: "#FF8501",
                            width: "265px",
                            height: 300,
                        },
                    [theme.breakpoints.between('sm', 'md')]:
                        {
                            background: "#FF8501",
                            width: 331,
                            height: 374.7,
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            width: "82.5%",
                            background: "#FF8501",
                            height: 350,
                        },
                },
            ImagesOfTinkersDivBlurred:
                {
                    [theme.breakpoints.up("sm")]: {
                        width: 265,
                        height: 300,
                        opacity: 0.5,
                        cursor: "pointer",
                        background: "#000000"
                    },
                    [theme.breakpoints.between('sm', 'md')]:
                        {
                            width: 331,
                            height: 375,
                            opacity: 0.5,
                            cursor: "pointer",
                            background: "#000000"
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            width: "100%",
                            height: 350,
                            opacity: 0.5,
                            background: "#000000"
                        },
                },
            bioDataDisplayed:
                {
                    position: "relative",
                    bottom: 280,
                    width: "85%",
                    fontWeight: "bold",
                    lineHeight: "normal",
                    color: "#fff",
                    fontSize: 14,
                    textAlign: "justify",
                    marginInline: "23px",
                    justifyContent: "center",
                    [theme.breakpoints.between("sm", 'md')]:
                        {
                            width: "85%",
                            height: 350,
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            width: "85%",
                            height: 350,
                        },
                },
            bioDataNotDisplayed:
                {
                    display: "none",
                },
            ImagesOfTinkers:
                {
                    // width: "100%",
                    height: "100%",
                    marginBottom: 10,
                },
            ImagesOfHoveredTinkers:
                {
                    // width: "100%",
                    height: "100%",
                    marginBottom: 10,
                    opacity: 0.5,
                },
            NameAndLinkedin:
                {
                    margin: "10px 0px",
                    height: 24,
                    fontFamily: 'Montserrat-SemiBold',
                    color: "#000",
                    fontWeight: 600,
                    textAlign: "left",
                    fontSize: 20,
                    display: "flex",
                    justifyContent: "space-between",
                    [theme.breakpoints.between('sm', 'md')]:
                        {
                            fontSize: 25,
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            width: "100%",
                            fontSize: 16,
                        },
                }, NameAndLinkedinHovered:
                {
                    margin: "10px 0px",
                    height: 24,
                    fontFamily: 'Montserrat-SemiBold',
                    color: "#000",
                    fontWeight: 600,
                    textAlign: "left",
                    fontSize: 20,
                    display: "flex",
                    justifyContent: "space-between",
                    [theme.breakpoints.between('sm', 'md')]:
                        {
                            fontSize: 25,
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            width: "82.5%",
                            fontSize: 16,
                        },
                },
            linkedinLink: {
                textDecoration: 'none',
            },
            TinkersName: {
                color: "#000",
                fontStyle: "normal",
                fontFamily: "Montserrat-Regular",
            },
            linkedinLogo:
                {
                    color: "#ff8501",
                    width: 21,
                    fontSize: 26,
                    fontWeight: "bolder",
                    cursor: "pointer",
                    [theme.breakpoints.only("xs")]:
                        {
                            fontSize: 18,
                        },
                },

        }
    ));

export const GlimpseOfLifeAtITinker = makeStyles((theme) =>
    (
        {
            GlimpseOfLifeAtITinker:
                {
                    [theme.breakpoints.only("xs")]:
                        {
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "column",
                        }
                },
            title: {
                color: "#11246f",
                fontSize: 30,
                textAlign: "center",
                fontWeight: "bold",
                margin: "139px 0 82px 0",
                fontFamily: 'Montserrat-Bold',
                [theme.breakpoints.only("xs")]:
                    {
                        fontSize: 18,
                        textAlign: "center",
                        margin: "100px 0 40px 0",
                    },
                [theme.breakpoints.between("sm", 'md')]:
                    {
                        margin: "170px 0 73px 0",
                    },
            },
            GlimpseOfLifeAtITinkerImageBlock: {
                marginInlineStart: 120,
                marginInlineEnd: 150,
                [theme.breakpoints.between("sm", 'md')]:
                    {
                        marginInline: "5%",
                    },
                [theme.breakpoints.only("xs")]:
                    {
                        marginInline: "auto",
                    },
            },
            GlimpseOfLifeAtITinkerImageBlock1:
                {
                    display: "flex",
                    flexDirection: "row",
                    //  height: 378,
                    [theme.breakpoints.only("xs")]:
                        {
                            display: "block",
                        },
                },
            GlimpseOfLifeAtITinkerImage1:
                {
                    [theme.breakpoints.up("md")]:
                        {
                            width: "68%",
                            marginInlineStart: -9,
                            marginRight: -2,
                        },
                    [theme.breakpoints.between("sm", 'md')]:
                        {
                            width: "68%",
                            marginRight: -2,
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            width: 338,
                            height: 200,
                        },
                },
            mobileViewFlexBlock:
                {
                    display: "flex",
                    columnGap: "3px",
                },
            GlimpseOfLifeAtITinkerImage2_3: {
                [theme.breakpoints.up("md")]:
                    {
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 6,
                        marginTop: 7,
                        width: "33%",
                    },
                [theme.breakpoints.between("sm", 'md')]:
                    {
                        rowGap: 4,
                        width: "33%",
                        marginTop: 2,
                        display: "flex",
                        flexDirection: "column",
                    },
                [theme.breakpoints.only("xs")]:
                    {
                        width: 168,
                        height: 85,
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "3%",
                    },
            },
            GlimpseOfLifeAtITinkerImage2:
                {
                    [theme.breakpoints.only("xs")]:
                        {
                            width: 168,
                            height: 85,
                        },
                },
            GlimpseOfLifeAtITinkerImageBlock2:
                {
                    [theme.breakpoints.up("sm")]:
                        {
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            marginTop: -5,
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            display: "block",
                        },
                },
            GlimpseOfLifeAtITinkerImage4_5: {
                [theme.breakpoints.up("sm")]:
                    {
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 6,
                        marginRight: -4,
                        width: "33%",
                    },
                [theme.breakpoints.only("xs")]:
                    {
                        width: 166,
                        height: 200,
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "1%",
                    },
            },
            GlimpseOfLifeAtITinkerImage5:
                {
                    [theme.breakpoints.only("xs")]:
                        {
                            height: 81,
                        },
                },
            GlimpseOfLifeAtITinkerImage6:
                {
                    [theme.breakpoints.up("md")]:
                        {
                            width: "68%",
                            marginTop: -6,
                        },
                    [theme.breakpoints.between("sm", 'md')]:
                        {
                            width: "69%",
                            marginTop: "-4px",
                        },
                    [theme.breakpoints.only("xs")]:
                        {
                            width: 340,
                            height: 200,
                            marginTop: "-37px",
                        },
                },
        }
    ));

export const BroughtToYouByStyles = makeStyles((theme) =>
    (
        {
            numberMain: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "##f6f9ff;",
                marginTop: "100px",
                paddingTop: "100px",
                [theme.breakpoints.only('xs')]: {
                    paddingTop: "28px",
                },
            },

            Heading: {
                fontSize: "30px",
                fontWeight: "bold",
                fontFamily: 'Montserrat-Bold',
                color: "#11246f",
                marginBottom: "10.5%",
                display: "flex",
                [theme.breakpoints.only('xs')]: {
                    fontSize: "18px",
                    marginBottom: "16.5%",
                    marginLeft: "5%",
                },

            },
            IconArrow: {
                width: "40px",
                height: "40px",
                position: "relative",
                bottom: "22px",
                right: "9px",
            },
            companyImageLaptop: {
                width: "92%",
                [theme.breakpoints.only('sm')]: {
                    display: "none",
                },
                [theme.breakpoints.only('xs')]: {
                    display: "none",
                },
            },
            companyImageIpad: {
                width: "90%",
                display: "none",
                [theme.breakpoints.only('sm')]: {
                    display: "block",
                },
                [theme.breakpoints.only('xs')]: {
                    display: "none",
                },
            },
            companyImageMobile: {
                width: "90%",
                display: "none",
                [theme.breakpoints.only('sm')]: {
                    display: "none",
                },
                [theme.breakpoints.only('xs')]: {
                    display: "block",
                    height: "223px",
                },
            },


        }


    ));

export const FromTheNationsWorldWide = makeStyles((theme) =>
    (
        {
            FromTheNationWorldWideBlock:
                {
                    backgroundColor: "#f6f9ff",
                },
            FromTheNationsWorldWideHeading:
                {
                    color: "#11246f",
                    fontSize: 25,
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: 80,
                    paddingTop: 180,
                    fontFamily: "Montserrat-Bold",
                    [theme.breakpoints.between('sm', 'md')]:
                        {
                            marginTop: "23%",
                            marginBottom: 80,
                            paddingTop: 0,
                        },
                    [theme.breakpoints.only('xs')]:
                        {
                            textAlign: "center",
                            fontSize: 18,
                            paddingTop: 110,
                            marginBottom: "58px",
                        },
                },
            NationsFlagImage:
                {
                    height: 341,
                    width: "100%",
                    [theme.breakpoints.between('sm', 'md')]:
                        {
                            width: "100%",
                            height: "auto",
                        },
                    [theme.breakpoints.only('xs')]:
                        {
                            width: "143%",
                            height: 180,
                        },
                },
        }));