import {makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
    main: {
        textAlign: 'left',
        paddingTop: '5%',
        paddingLeft: '5%',
        paddingBottom: '5%',
        paddingRight: '5%',
        backgroundColor: `#FFF5ED`,
        '& h3': {
            fontFamily: 'OpenSansBold',
            fontSize: 50,
        },
        '& body1': {
            fontFamily: 'Poppins',
            fontSize: 36,
        },
        '& subtitle1': {
            fontFamily: 'OpenSansBold',
            fontSize: 40,

        },
    },
    // background: {backgroundColor: "white", borderRadius: 20, padding: "30px", margin: "5px"}
}));
