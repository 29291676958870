import React from 'react';
import {AboutBannerStyles as useStyles} from './styles';
import heroImageWeb from "../../assets/AboutUs/HeroImg_Web.png";
import heroImageIpad from "../../assets/AboutUs/HeroImg_ipad.png";
import heroImageMobile from "../../assets/AboutUs/HeroImg_Mobile.png";

const AboutBanner = () => {
    const classes = useStyles();

    return (
        <div className={classes.main}>
            <span className={classes.Heading}>About Us</span>
            <span className={classes.Info}>We, the Tinkerers, are on the path of skilling the next billion. Providing young minds all the tools and guidance required to excel their skills.</span>
            <div className={classes.Image}>
                <div className={classes.ImageWeb}>
                    <img className={classes.heroImageBlur} src={heroImageWeb}></img>
                    <img className={classes.heroImage} src={heroImageWeb}></img>
                </div>
                <div className={classes.ImageTab}>
                    <img className={classes.heroImageBlur} src={heroImageIpad}></img>
                    <img className={classes.heroImage} src={heroImageIpad}></img>
                </div>
                <div className={classes.ImageMobile}>
                    <img className={classes.heroImageBlur} src={heroImageMobile}></img>
                    <img className={classes.heroImage} src={heroImageMobile}></img>
                </div>
            </div>
        </div>
    );
};
AboutBanner.propTypes = {};
export default AboutBanner;
