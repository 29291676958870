import {makeStyles} from '@material-ui/core';

export const styles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 10,
        margin: "5px",
        [theme.breakpoints.up('lg')]: {
            width: "unset",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: "unset",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },

    btn: {
        backgroundColor: theme.palette.primary.main,
        height: 50,
        width: '92%',
        color: theme.palette.other.white,
        marginTop: 22,
        marginLeft: 22,
        fontSize: 17,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    loginAsset: {
        display: "block",
        margin: "auto",
        [theme.breakpoints.up('lg')]: {
            height: "200px",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: "150px",
        },
        [theme.breakpoints.down('xs')]: {
            width: "80%",
        },
    },
    labelStyle: {
        display: "block",
        margin: "20px 0px 90px",
        fontSize: "14px",
        textAlign: "center",
    },
    phoneInputStyle: {
        width: "fit-content", margin: "auto",
        "&.react-tel-input .form-control:focus": {
            borderColor: "#bdbdbd",
            width: "364px",
            boxShadow: "0px 0px 0px 1px #bdbdbd",
        },
        '& .flag-dropdown ': {
            display:"hide",
            backgroundColor: "unset", borderRight: "unset",
        },
        '& .form-control': {
            padding: "23px 14px 23px 58px",
            width: "364px",
            [theme.breakpoints.down('xs')]: {width: "100%",},
        },
        [theme.breakpoints.down('xs')]: {width: "90%",},
    },
    timerStyle: {
        textAlign: "center",
    },
    mobileNoStyle: {fontWeight: "600",},
    resend_style: {
        color: "#ff8300"
    },
    not_received_resend_style: {display: "block"},
    timerBtnStyle: {
        border: "unset", background: "unset",
    },
    otpInput: {
        margin: "auto",
        [theme.breakpoints.down('sm')]: {margin: "10px auto",},
        width: "fit-content",
        '& div': {
            margin: "10px",
            [theme.breakpoints.down('sm')]: {
                margin: "5px",
            },
            '& input': {
                height: "40px",
                width: "40px !important",
                [theme.breakpoints.down('xs')]: {width: "30px !important",},
                [theme.breakpoints.between('sm', 'md')]: {height: "50px", width: "50px !important",},
                borderRadius: "5px",
                border: "1px solid #bdbdbd",
            },
        },
    },
    outerDivCondition: {
        textAlign: "center",
    },
    loginPopup: {
        width: "900px",
        margin: "0px 0px 0px",
        padding: "0 0 0 1px",
        borderRadius: "7px",
        display: "flex",
        flexFlow: "row",

    },
    image: {
        width: "400px",
        height: "400px",
    },
    form: {
        width: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        height: "388px",
        margin: "20px 20px 20px 20px",

    },
    login: {
        width: "100%",
        height: "29px",
        flexGrow: "0",
        marginBottom:"40px",
        fontFamily: "Montserrat",
        fontSize: "24px",
        fontWeight: "600",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#000",
    },
    horizontalLine: {
        display: "inline",
        marginTop:"-20px"
    },

    EllipseSolid: {
        width: "27px",
        height: "27px",
        flexGrow: "0",
        display: "inline-block",
        borderRadius: "50%",
        border: "solid 1.2px #f48c06",
    },
    EllipseBlockSolid:{
        width: "27px",
        height: "27px",
        flexGrow: "0",
        backgroundColor:"#f48c06",
        display: "inline-block",
        borderRadius: "50%",
        border: "solid 1.2px #f48c06",
    },
    EllipseDotted: {
        width: "27px",
        height: "27px",
        borderRadius: "50%",
        display: "inline-block",
        border: "1.2px dotted #f48c06",
    },


    dotted: {
        border: "1px dotted #302d2d",
        width: "350px",
        display: "inline-block",
        marginBottom: "14px",
        borderStyle: "none none dotted",
        color: "#fff",
        backgroundColor: "#d9d7d7",
    },
    solidLine: {
        border: "1px solid #f48c06",
        width: "350px",
        display: "inline-block",
        marginBottom: "14px",
        borderStyle: "none none solid",
        color: "#fff",
        backgroundColor: "#f48c06",
    },
    Text: {
        marginBottom: "90px",
        marginTop: "20px",
        fontSize:"14px",
    },
    mobile: {
        width: "fit-content"
    },


}));


// assign classnames to sign in with google button
export const assignStyleToSignInBtn = (isValidMobileNumber) => {
    const style = makeStyles(theme => ({
        signBtn: {
            boxShadow: "0 2px 6px 0 #d8d8d8",
            justifyContent: "center",
            border: "none",
            borderRadius: "8px",
            margin: "20px auto 10px",
            display: "block",
            color: "white",
            cursor: "pointer",
            [theme.breakpoints.up('lg')]: {
                width: "80%", height: "40px",
                background: isValidMobileNumber ? "#fd9964" : "gray",
            },
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: "28px",
                width: "25vw",
                height: "50px",
                margin: "10px auto",
                background: "#fd9964",
                [theme.breakpoints.only('md')]: {margin: "20px auto",},
            },
            [theme.breakpoints.down('xs')]: {
                width: "40%",
                height: "40px",
                background: "#fd9964",
            },
        },
    }));

    return (style().signBtn);
}