import React from 'react';
import Proptypes from 'prop-types';
import {withWidth} from '@material-ui/core';
import {styles as useStyles} from './styles';
import {motion} from 'framer-motion/dist/framer-motion'

const PrimaryButton = ({
                           text,
                           preIcon,
                           postIcon,
                           children,
                           btnClass,
                           id,
                           onClick = () => {
                           },
                       }) => {
    const classes = useStyles();

    return (
        <motion.button
            whileHover={{scale: 1.06}}
            whileTap={{scale: 1}}
            variant="contained"
            className={btnClass || classes.btnMain}
            onClick={onClick}
            style={{cursor: 'pointer'}}
        >
            {preIcon && children}
            <div id={id} className={classes.buttonDiv}>
                &nbsp;{text}&nbsp;
            </div>
            {postIcon && children}
        </motion.button>
    );
};
PrimaryButton.propTypes = {
    text: Proptypes.string.isRequired,
    id: Proptypes.string,
    preIcon: Proptypes.bool,
    postIcon: Proptypes.bool,
    btnClass: Proptypes.string,
    onClick: Proptypes.func,
};
export default withWidth()(PrimaryButton);
