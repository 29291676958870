import MainApp from './MainApp';
import {theme} from './theme';
import {ThemeProvider, withStyles} from '@material-ui/core/styles';

const styles = () => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em', height: '0em',
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#ff9964',
            outline: '1px solid #ff9964',
        },
    },
});

const schemaData =
    {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "iTinker",
        "url": "https://www.itinker.io",
        "logo": "https://www.itinker.io/public/android-chrome-512x512.png",
        "description": "iTinker is a Germany based tech-ed company working on making the school children future ready by giving them hands-on learning and coding experience on future technologies like AI, 3D Printing, AR/VR, App Development etc.",
        "telephone": "+91 9915111040",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Chandigarh, India",
            "postalCode": "160029",
            "streetAddress": "Arth Parkash Building Opposite Tribune Colony, Sector 29D "
        },
        "email": "hello@itinker.io",
        "sameAs": [
            "https://www.youtube.com/channel/UCeSYbzo-rPZ4Quavwwc3bsA",
            "https://www.facebook.com/itinker.io",
            "https://www.instagram.com/itinker.io/",
            "https://www.linkedin.com/company/itinker",
            "https://twitter.com/iTinker_io",
            "https://play.google.com/store/apps/details?id=com.itinker_app",
            "https://apps.apple.com/in/app/itinker/id1528019507"
        ],
        "knowsAbout": ["education", "future ready", "technology"]
    }

const App = () => {
    return (
        <>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(schemaData)}}
            />
            <ThemeProvider theme={theme}>
                <MainApp/>
            </ThemeProvider>
        </>
    );
};

export default withStyles(styles)(App);