import {makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
    main: {
        textAlign: 'left',
        paddingTop: '5%',
        paddingLeft: '5%',
        paddingBottom: '5%',
        paddingRight: '5%',
        backgroundColor:  "#FFF5ED",
        // backgroundImage: `linear-gradient(119deg, ${theme.palette.primary.gradientColor}, ${theme.palette.primary.main})`,
        '& h3': {
            fontFamily: 'OpenSansSemiBold',
            fontSize: 48,
        },
        '& body1': {
            fontFamily: 'Poppins',
            fontSize: 36,
        },
        '& subtitle1': {
            fontFamily: 'OpenSansBold',
            fontSize: 40,
        },
        '& sub1': {
            fontFamily: 'OpenSansBold',
            fontSize: 55,
        },
    },
    // background: {backgroundColor: "#FFF5ED", borderRadius: 20, padding: "30px", margin: "5px"}
}));
