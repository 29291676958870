import {Carousel} from '3d-react-carousal';
import Icon from "../../assets/YellowPlayIcon.png";
import {InternshipProgramsStyles as useStyles} from "./styles";


const InternshipPrograms = (props) => {
    const classes = useStyles();
    return (
        <div
            className={props.Data.reverse ? ` ${classes.InternshipMain} ${classes.InternshipMainReverse}` : classes.InternshipMain}>
            <div className={classes.paragraph}>
                <div
                    className={props.Data.reverse ? classes.InternshipProgramsTextReverse : classes.InternshipProgramsText}>
                    <div className={classes.InternshipTitle}>{props.Data.heading}</div>
                    <div
                        className={props.Data.reverse ? ` ${classes.InternshipText} ${classes.InternshipTextReverse}` : classes.InternshipText}>
                        <span style={{display: "block"}}>{props.Data.Info1}</span>
                        <span>{props.Data.Info2}</span>
                    </div>
                </div>
                <div className={classes.PlayButton}>
                    <a href={`${props.Data.playListLink}`} target="_blank"
                       rel="noreferrer">
                        <img className={classes.Icon} src={Icon}></img>
                    </a>
                    <a href={`${props.Data.playListLink}`} target="_blank"
                       rel="noreferrer">
                        <span className={classes.iTinkerSummerText}>
                            {props.Data.LinkName}
                        </span>
                    </a>
                </div>
            </div>
            <div className={classes.InternshipProgramsVideo}>
                <Carousel slides={props.Data.slides} autoplay={true} interval={2000} arrows={true}/>
            </div>
        </div>
    )
}
export default InternshipPrograms;