export const Constants = ({
    //API CONSTANTS
    sendOTP: "/sendloginotp",
    login: "/login",
    checkAuth: '/checkauth',
    checkUser: '/checkuser',
    addUser: '/adduser',
    getUserData: '/getuserdata',
    updateUserData: '/updateuser',
    getCoursesList: "/getcourseproducts",
    createOrder: "/createorder",
    updateOrderStatus: "/updateorderstatus",
    getProductByReadableID: "/getproductbyreadableid",
    addCustomerLead: "/addcustomerlead",
    getGameData: "/getgame",
    getAchievements: "/getachievements",
    leaderBoard: "/leaderboard",
    getUserWalletTransactions: "/getwallettransactions",
    addWalletTransaction: "/addwallettransaction",
    isInviteCodeValid: "/isinvitecodevalid",
    getMiscData: "/getmiscdata",
    getUidByUsername: '/getuidbyusername',
    addInviteCodeUsage: "/addinvitecodeusage",
    addTechnovationLead: "/addtechnovationlead",
    ipJson: "/json",
    listUsersWithIds: "/listuserswithids",
    //User Data
    getUserGame: "/getusergame",
    addNewUserData: "/addnewuserdata",
    getUserOrders: "/getorders",
    getProducts: "/getproducts",

    //Website Static Data
    getBlogs: "/getblogs",
    getTeamMembers: "/getteammembers",
    getCompetitions: "/getcompetitions",
    getDownloadPageReviews: "/getdownloadpagereviews",
    getCoursePageFeatures: "/getcoursepagefeatures",
    getJobFunds: "/getjobfunds",
    getInternships: "/getinternships",
    getHomePageScores: "/gethomepagescores",
    getVideos: "/getvideos",
    getHomePageReviews: '/gethomepagereviews',

    //courses Data
    getStudentClassrooms: "/getstudentclassrooms",
    getStudentClassCourses: "/getstudentclasscourses",
    getTeacherClassCourses: "/getteacherclasscourses",
    getTutorCourseDetails: "/gettutorcoursedetails",
    getTutorSessionsDetails: "/gettutorsessionsdetails",
    getAnonTutorSessionsDetails: "/getanontutorsessionsdetails",
    getMultipleSessionStatuses: "/getmultiplesessionstatuses",
    getFrsAssessments: "/getdetaileduserassessments",
    getUserSessionActivities: "/getusersessionactivities",
    getUserCourseSessionActivities: "/getusercoursesessionactivities",
    getCourseActivities: "/getcourseactivities",
    addSessionActivity: "/addsessionactivity",
    updateSessionActivity: "/updatesessionactivity",
    getTeacherDateMeetings: "/getteacherdatemeetings",
    addMeeting: "/addmeeting",
    updateSessionStatus: "/updatesessionstatus",
    getSessionStatus: "/getsessionstatus",


    login_server: 'https://login.itinkerserver.com',
    otp_token_key: 'JUDBF84542jkjbdfHIEFn4jefbh845nH',
    frs_data_server: 'https://frs.itinkerserver.com',
    website_server: 'https://web.itinkerserver.com',
    app_server: 'https://prod.itinkerserver.com',
    domain: '.itinker.io',
    secure: process.env.NODE_ENV === 'production',
    image_cdn: "https://tinkernxt.imgix.net",
    ip_api_server: 'https://ipapi.co/json/',
    //LOCAL STORAGE
    auth_token: "auth_token",
    user_game_data: "user_game_data",
    points_types: "points_types",
    triggers: "triggers",
    achievements: "achievements",
    ranks: "ranks",


    //Constants
    //roles
    student: "student",
    teacher: "teacher",

    //Schools Data
    getSchool: "/getschool"
});