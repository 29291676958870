import {styles as useStyles} from './styles';
import {Grid, Typography, withWidth} from '@material-ui/core';
import PropTypes from 'prop-types';
import {SCREEN_WIDTHS, widthValues} from "../../../utils/common";
import {motion} from 'framer-motion/dist/framer-motion'
import {useRef} from "react";
import useIsVisible from "../../Home/is-visible";
import Imgix from "react-imgix";


const LeftPhoneSection = ({title, text, imageSrc, history, width}) => {
    const classes = useStyles();
    const elemRef = useRef();
    const isVisible = useIsVisible(elemRef);
    const isSmallDevice = (width === SCREEN_WIDTHS.XS || width === SCREEN_WIDTHS.SM || width === SCREEN_WIDTHS.MD);

    return (
        <div ref={elemRef}>
            {isVisible ?
                <div className={classes.main}>
                    {
                        isSmallDevice ?
                            <>
                                    <div>
                                        <Imgix src={imageSrc} alt="phone" className={classes.phone}/>
                                    </div>
                                <div>
                                    <Typography className={classes.mainText}>{title}</Typography>
                                    <Typography className={classes.descText}>{text}</Typography>
                                </div>
                            </>
                            :
                            <>
                                <div className={classes.section}>
                                    <Grid container>
                                        <Grid item sm={5} xs={12} className={classes.left}>
                                            <motion.div ref={elemRef} animate={{x: 15}}
                                                        transition={{slidesOnRight: "slidesOnRight", duration: 3}}>
                                                <div>
                                                    <Imgix src={imageSrc} alt="phone" className={classes.phone}/>
                                                </div>
                                            </motion.div>
                                        </Grid>
                                        <Grid item sm={7} xs={12}>
                                            <motion.div ref={elemRef} animate={{x: -15}}
                                                        transition={{slidesOnLeft: "slidesOnLeft", duration: 3}}>
                                                <div>
                                                    <Typography className={classes.mainText}>{title}</Typography>
                                                    <Typography className={classes.descText}>{text}</Typography>
                                                </div>
                                            </motion.div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                    }
                </div>
                : <></>}
        </div>
    );
};
LeftPhoneSection.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    width: PropTypes.oneOf(widthValues),
};
export default withWidth()(LeftPhoneSection);
