import React, {useContext, useEffect} from 'react';
import {Grid, Typography, withWidth} from '@material-ui/core';
import {styles} from './styles';
import {Constants} from "../../utils/Constants";
import {AuthContext} from "../../utils/AuthContext";
import Cookies from "universal-cookie";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {FooterWebView} from "./FooterWebView";

const Footer = (params) => {
    const classes = styles();
    let auth = useContext(AuthContext);

    useEffect(() => {
    });

    const handleLogout = () => {
        let cookies = new Cookies();
        cookies.remove(Constants.auth_token, {
            path: '/',
            secure: Constants.secure,
            domain: Constants.domain
        });
        auth.setUser(null);
        window.location.reload();
    };

    return (
        <div className={classes.main}>
            <FooterWebView handleLogout={handleLogout}/>
        </div>


    )

};
Footer.propTypes = {
    width: PropTypes.oneOf(widthValues),
};
export default withWidth()(Footer);

