import {makeStyles} from '@material-ui/core';

export const styles = makeStyles((theme) => ({
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxHeight:"90vh"
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: 30,
        '& h5': {
            color: theme.palette.secondary.headFontColor,
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                fontSize: "20px",
                marginTop: "20px",
            },
        },
        '& h6': {
            [theme.breakpoints.down('xs')]: {
                fontSize: "12px",
            },
        },
    },
    subTitle: {padding: '0px 86px', fontSize: 16},
    textField: {
        width: '90%',
        backgroundColor: theme.palette.other.lightestGrey,
        borderRadius: 10,
        marginBottom: "10px !important",
        padding: "8px !important"
    },
    label: {
        color: theme.palette.secondary.headFontColor,
        fontSize: 14,
    },
    btn: {
        backgroundColor: theme.palette.primary.main,
        height: 50,
        width: '92%',
        color: theme.palette.other.white,
        marginTop: 20,
        borderRadius: 10,
        fontSize: 17,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            width: "80%", display: "block", margin: "auto",
        },
    },
    dialog: {
        padding: '20px',
        borderRadius: 30,
    },
    asset: {
        width: "250px", display: "block", margin: "auto",
        [theme.breakpoints.down('xs')]: {
            width: "80%",
        },
    },
    fieldGrid: {margin: "auto !important",},
    terms: {
        textAlign: "left",
        marginTop: "10px",
        display: "block",
        fontSize: "10px", color: "#7e7e7e",
    },
}));
