import React, {useState} from 'react';
import {Button, Typography} from '@material-ui/core';
import {styles as useStyles} from './styles';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import BookAppointmentModal from '../BookAppointmentModal/BookAppointmentModal';
import playBtn from "../../../assets/playbutton.png";
import triangle from "../../../assets/triangle.png";
import {useMediaQuery, useTheme} from "@mui/material";

const GetStarted = () => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const mediaTheme = useTheme();
    const showText = useMediaQuery(mediaTheme.breakpoints.between('sm', '970'));


    const handleClick = () => {
        setOpen(true);
    };

    return (
        <div className={classes.main}>
            <div className={classes.bgImg}>
                <div className={classes.container}>
                    <div className={classes.leftContainer}>
                        <img src={triangle} className={classes.triangle}/>
                        {
                            showText
                                ?
                                <Typography className={classes.title}>
                                    Build a Personalised<br/>
                                    <span className={classes.successPathText}> Success Path </span> for your Child
                                    <br/>
                                </Typography>
                                :
                                <Typography className={classes.title}>
                                    Build a Personalised<br/>
                                    <span className={classes.successPathText}> Success Path </span> for your <br/>
                                    Child
                                    <br/>
                                </Typography>
                        }

                        <Typography className={classes.title2}>
                            We Empower You To Learn What You Love
                        </Typography>
                        <div className={classes.flexBtn}>
                            <PrimaryButton
                                id="getStarted_btn"
                                text="Join Us"
                                postIcon
                                onClick={() => (window.open('https://frs.itinker.io', "_blank"))}>
                            </PrimaryButton>
                            <a href={`https://www.youtube.com/playlist?list=PL2wiBgLb7UUlxHfDIoRUUNVd6dzEq5ER5`}
                               target="_blank"
                               rel="noreferrer">
                                <img className={classes.playBtn} src={playBtn}/>
                            </a>

                            <a href={`https://www.youtube.com/playlist?list=PL2wiBgLb7UUlxHfDIoRUUNVd6dzEq5ER5`}
                               target="_blank"
                               rel="noreferrer">
                                <Typography className={classes.lifeAtText}>
                                    Life at iTinker
                                </Typography>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {open && <BookAppointmentModal open setOpen={setOpen} source={"appointment"}/>}
        </div>
    );
};
GetStarted.propTypes = {};
export default GetStarted;
