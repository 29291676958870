import {makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
    btnMain: {
        backgroundColor: "#f48c06",
        width:  166,
        height: '52px',
        borderRadius: 7,
        border: "none",
        color: " #fff",
        textTransform: 'capitalize',
        fontFamily:'Montserrat-Medium',
        fontSize: 18,
        fontWeight: 600,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 5,
            height: 41,
            width: 141,
            alignItems: 'center',

        },
        [theme.breakpoints.between('sm', '970')]: {
            fontSize: 14,
            width: 158,
            height: 45,
            padding: '0 0 0 0',
            fontWeight: 600,
            marginRight: '7px'
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.fontColor,
            border: "none"
        },
    },
    buttonDiv: {
        display: "inline-block",
        [theme.breakpoints.down('sm')]:{
            fontSize: 15,
            height: 18,
            fontWeight: 600,
            color: '#fff'
        }
    },

}));
