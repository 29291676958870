import React, {useEffect, useState} from 'react';
import {Typography, withStyles, withWidth} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import {theme} from '../../../theme';
import Slider from 'react-slick';
import styles from '../Reviews/styles'
import CustomArrow from './CustomArrow';
import {SCREEN_WIDTHS, widthValues} from '../../../utils/common';
import PropTypes from "prop-types";
import {Api} from "../../../services/Api";
import {Constants} from "../../../utils/Constants";
import {DownloadPageReviewModelFromJson} from "../../../models/WebsiteStaticDataModels";
import {GetImageDownloadUrl} from "../../../services/firebase";
import Imgix from "react-imgix";

const StyledRating = withStyles({
    iconFilled: {
        color: theme.palette.primary.main,
    },
})(Rating);

// This function is to render reviews component of download app page
function Reviews({width}) {
    const [reviews, setReviews] = useState([]);
    const [active, setActive] = useState(0);
    let [slider1, setSlider1] = useState();
    let [slider2, setSlider2] = useState();
    const classes = styles();

    async function downloadStudentsImages(data) {
        return await GetImageDownloadUrl(data.picture);
    }


    // This function is to get download reviews data from firebase
    useEffect(() => {
        Api(Constants.getDownloadPageReviews, {}, "").then((response) => {
            if (response !== null) {
                response.forEach(async (review) => {
                    let newReview = DownloadPageReviewModelFromJson(review);
                    newReview.picture = await downloadStudentsImages(newReview);
                    setReviews(oldReviews => [...oldReviews, newReview])
                })
            }
        })
    }, [])

    const afterChange = (event) => {
        setActive(event);
    }

    // This is to render data
    return (
        <>
            <div className={classes.main}>
                <div className={classes.section}>
                    <div className={classes.topHead}>
                        <Typography variant="h4">What our students say...</Typography>
                    </div>
                    <div className={classes.sliderSection}>
                        <Slider
                            className="center"
                            infinite={true}
                            speed={500}
                            slidesToShow={width === SCREEN_WIDTHS.XS || width === SCREEN_WIDTHS.SM ? 1 : 3}
                            slidesToScroll={3}
                            asNavFor={slider1}
                            ref={(slider) => setSlider2(slider)}
                            arrows={false}
                        >
                            {reviews.map((review) =>
                                <div className={classes.panel}>
                                    <div className={classes.mainDiv}>
                                        <div className={classes.content}>
                                            <div className={classes.innerContent}>
                                                <div className={classes.flex}>
                                                    <img
                                                        src={review.picture}
                                                        alt="quotes"
                                                        className={classes.quotes}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography variant="h5" className={classes.sliderText}>
                                                        {review.name}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        className={classes.sliderSubText}
                                                    >{review.designation}
                                                    </Typography>
                                                </div>
                                                <Typography className={classes.text}>
                                                    {review.review}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
                <div className={classes.subNav}>
                    <Slider
                        asNavFor={slider2}
                        ref={(slider) => setSlider1(slider)}
                        infinite={true}
                        slidesToShow={3}
                        nextArrow={<CustomArrow/>}
                        prevArrow={<CustomArrow direction="left"/>}
                        afterChange={afterChange}
                        autoplay
                    >
                        {[0, 1, 2, 3, 4, 5].map((item) => {
                            return (
                                <div className={classes.panel1} key={item}>
                                    {active === item ? (
                                        <Imgix src="/itinker-assets/Courses/Reviews/dot1.png" alt="dot"/>
                                    ) : (
                                        <Imgix src="/itinker-assets/Courses/Reviews/dot2.png" alt="dot"/>
                                    )}
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </>
    );
}

Reviews.propTypes = {
    width: PropTypes.oneOf(widthValues),
};
export default withWidth()(Reviews);