import React, {useEffect, useState} from 'react';
import {useLocation, withRouter} from 'react-router-dom';
import {styles} from "./styles";
import {FilledInput, FormControl, InputLabel, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {addLead} from "../../services/lead";
import InputFields from "./InputFields";
import Imgix from "react-imgix";
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function ContactUs() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const classes = styles();
    const location = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const [todo, setTodo] = useState('');
    const [comments, setComments] = useState('');

    useEffect(() => {
        let path = location.pathname.split('/');
        let id = path[path.length - 1].toString();
        setComments(id);
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // @ts-ignore
        document.querySelector('body').scrollTo(0, 0)
    }, [])


    // handle the submit of contact form
    const handleSubmit = async () => {
        if (name && email && phone) {
            let source = "web";
            setError('');
            await addLead(name, email, phone, source, comments);
            setName('')
            setEmail('')
            setPhone('')
            setTodo('')
            alert("Successfully Submitted!");
        } else {
            setError('Required fields cannot be empty.');
        }
    };


    return (
        <div className={classes.outerDiv}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div className={classes.userInfoSideDiv}>
                    {isSubmitted ?
                        <SubmittedUI/>
                        :
                        <div className={classes.outerBookDiv}>
                            <div className={classes.headingSubHeadContainer}>
                                <div align={"center"} className={classes.heading}>Get in Touch</div>
                                <div align={"center"} className={classes.subHeading}>Follow us on our social media
                                    platforms to get
                                    latest updates from team itinker
                                </div>
                            </div>
                            <div className={classes.contactAndUserInfoBoxParent}>
                                <div className={classes.contactInfoParent}>
                                    <div className={classes.contactInfoText}>Contact Information</div>
                                    <div className={classes.contactInfoBox}>
                                        <div className={classes.subDiv}>
                                            <CallIcon className={classes.icon}/><span
                                            className={classes.contact}>+91-991 5111 040</span>
                                        </div>
                                        <div className={classes.subDiv}>
                                            <MailIcon className={classes.icon}/><span
                                            className={classes.contact}>support@itinker.io</span>
                                        </div>
                                        <div className={classes.subDiv}>
                                            <LocationOnIcon className={classes.icon}/><span className={classes.contact}>Chandigarh, India</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.userInfoDiv}>
                                    <div className={classes.namePhone}>
                                        <InputFields field={name} setField={setName} label={'Student Name'}
                                                     filledInputStyle={classes.filledInputStyle}/>
                                        <PhoneInput phone={phone} setPhone={setPhone}
                                                    filledInputStyle={classes.filledInputStyle}/>
                                    </div>
                                    <EmailInput email={email} setEmail={setEmail}
                                                filledInputStyle={classes.filledInputStyle}/>
                                    <ToDoInput todo={todo} setTodo={setTodo}
                                               filledInputStyle={classes.filledInputStyle}/>
                                    {error !== '' ?
                                        <Typography className={classes.error}>{error}</Typography>
                                        :
                                        <Typography style={{display: "none"}}>{}</Typography>
                                    }
                                    <button
                                        className={(name.length > 0 && email.length > 0 && phone.length > 0) ? classes.addToCart : classes.disableSubmitButton}
                                        onClick={handleSubmit} id={"contact_submit"}
                                        disabled={!(name.length > 0 && email.length > 0 && phone.length > 0)}>Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
    // render the UI to e shown to the user after successfully submission of contact form
};

export default withRouter(ContactUs);


function SubmittedUI() {
    const classes = styles();
    const history = useHistory();

    // handle the button on click event of browse more button
    function handleBrowseMore() {
        history.push('/courses');
    }

    return (
        <div>
            <span className={classes.thankYou}>Thank You!</span>
            <span className={classes.information}>
                    Your query has been submitted successfully, our executive will reach out to you soon.
                </span>
            <div className={classes.contactOuterDiv}>
                <Imgix src="/itinker-assets/ContactUs/mail.png" className={classes.mail} alt="mail"/>
                <span>support@itinker.io</span>
                <Imgix src="/itinker-assets/ContactUs/phone.png" className={classes.phone} alt="phone"/>
                <span>(+91) 991 511 1040</span>
            </div>
            <div className={classes.buttonParent}>
                <button id={"browsemore"} className={classes.applyPromoButton} onClick={handleBrowseMore}>
                    Browse More
                </button>
            </div>
        </div>
    )
}


//EMAIL ID input from the user
const EmailInput = ({email, setEmail, filledInputStyle}) => {
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    return (
        <FormControl fullWidth={true} variant="filled" className={filledInputStyle}>
            <InputLabel htmlFor="component-filled">Email Address</InputLabel>
            <FilledInput disableUnderline={true} id="component-filled"
                         className={filledInputStyle} value={email} type={'email'}
                         onChange={(e) => handleEmail(e)}/>
        </FormControl>
    );
}


//Name input from the user
const PhoneInput = ({phone, setPhone, filledInputStyle}) => {
    return (
        <FormControl fullWidth={true} variant="filled" className={filledInputStyle}>
            <InputLabel htmlFor="component-filled">Mobile Number</InputLabel>
            <FilledInput id="component-filled" disableUnderline={true}
                         className={filledInputStyle} value={phone}
                         onChange={(e) => setPhone(e.target.value)}/>
        </FormControl>
    );
}

const ToDoInput = ({todo, setTodo, filledInputStyle}) => {
    return (
        <FormControl fullWidth={true} size={"medium"} aria-multiline={true} variant="filled"
                     className={filledInputStyle}>
            <InputLabel htmlFor="component-filled">Add a Note</InputLabel>
            <FilledInput id="component-filled" disableUnderline={true}
                         className={filledInputStyle} value={todo}
                         multiline={true}
                         minRows={3}
                         onChange={(e) => setTodo(e.target.value)}
            />
        </FormControl>
    );
}