import {makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
    main: {
        height: '100%',
        textAlign: 'center',
        display: 'block',
        marginBottom: '50px',
        backgroundColor: "#FFFFF",
        '& h3': {
            fontFamily: 'Milkshake',
            fontSize: 72,
        },
        '& h5': {
            fontFamily: 'Milkshake',
            fontSize: 52,
        },
    },
    subText: {
        fontFamily: 'OpenSansRegular',
        fontSize: '17px',
        textAlign: 'center',
        marginTop: '0px',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '10%',
            fontSize: '17px',
            textAlign: 'center',
        },
        [theme.breakpoints.between('sm','md')]: {
            fontSize: '20px',
        },
    },
    mission: {
        position: 'relative',
        right: -10,
        height: 400,
        width: "auto",
        marginTop: '-65px',
        [theme.breakpoints.down('xs')]: {
            height: 300,
            marginTop: '-20'
        },
        [theme.breakpoints.between('sm','md')]: {
            height: 500,
            marginTop: '-20'
        },
    },
    btn: {
        marginLeft: '40%',
        marginTop: '20px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.fontColor,
        width: 300,
        height: 45,
        borderRadius: 8,
        fontSize: 15,
        alignItems: 'center',
        border: "none",
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.other.white,
        },
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            marginLeft: '17%',
            width: 300,
            height:42,
            fontSize: 16
        },
        [theme.breakpoints.between('sm','md')]: {
            width: 400,
            height: 55,
            fontSize: 20,
            marginLeft: '25%',

        },
    },
}));
