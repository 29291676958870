import {MakeADifferenceStyles as useStyles} from "./styles";
import Image from "../../assets/Jobs/careersweb.png";
import icon from "../../assets/Jobs/check icon.png";

function MakeADifference() {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.Heading}>
                <span className={classes.SubHeading}>Make A  <span
                    style={{color: "#ff8501"}}>Difference </span>
                    Everyday
                </span>
            </div>
            <div className={classes.content}>
                <div className={classes.Info}>
                    <div className={classes.About}>Aiming to have a positive impact on daily work is
                        crucial for the growth of any company and the following elements are instrumental in helping us achieve this goal.
                    </div>
                    <div className={classes.DoRight}>
                        {
                            Array.map((data) => (
                                <div className={classes.Card}>
                                    <img className={classes.Icon} src={data.image}/>
                                    <div className={classes.Title}>{data.title}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <img className={classes.Image} src={Image}/>
            </div>
        </div>
    )
}

const Array = [
    {
        image: icon,
        title: "Do Things Right",
    },
    {
        image: icon,
        title: "Take Initiative",
    },
    {
        image: icon,
        title: "Empower Each Other",
    },
    {
        image: icon,
        title: "Be a Team Player",
    },
]

export default MakeADifference;