import React, {useContext, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Typography,
    withWidth,
    Button,
    Menu,
    MenuItem,
    Drawer,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import cross from "../../assets/Hamburger menu.png";
import {TabNavbar as useStyleNavbar} from './styles';
import MenuIcon from '@material-ui/icons/Menu';
import {styles as useStyles, menuTheme} from './styles';
import {ThemeProvider} from '@material-ui/core/styles'
import {SCREEN_WIDTHS} from '../../utils/common';
import ScrollToColor from '../ScrollToColor/ScrollToColor';
import {useHistory} from 'react-router-dom';
import UserLoginPopup from "../Authentication/UserLoginPopup";
import {Constants} from "../../utils/Constants";
import {AuthContext} from "../../utils/AuthContext";
import {UserProfileDropDown} from "./UserProfileDropDown";
import Cookies from "universal-cookie";
import Fade from "@material-ui/core/Fade";
import {TeacherMobileMenu} from "./MobileMenu";
import {StudentMobileMenu} from './MobileMenu';
import {TeacherTabMenu} from "./TabMenu";
import {StudentTabMenu} from './TabMenu';
import Imgix from "react-imgix";
import {Box, Divider, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

const ROUTES = {
    HOME: 1,
    SCHOOLS: 2,
    COURSES: 3,
    BE_A_TINKERER: 4,
    FRS: 5,
    ABOUT: 6,
    BLOG: 7,
    DOWNLOAD: 8,
    EDIT_DETAILS: 9,
    MYPROFILE: 10,
    MYCOURSES: 11,
    DASHBOARD: 12,
    INVITE: 13,
    HELPDESK: 14,
    PRIVACY: 15,
    INTERNSHIPS: 16,
    CONTACT_US: 17,
};

const Navbar = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEltab, setAnchorEltab] = useState(null);
    const [anchorSideBarEl, setAnchorSideBarEl] = useState(null);
    let history = useHistory();
    const [openPopup, setOpenPopup] = useState(false);
    let auth = useContext(AuthContext);
    let user = auth.user;
    const {width, profileImage} = props;
    const classes = useStyles();
    const TabClasses = useStyleNavbar();
    const isScreenDesktop = (width === SCREEN_WIDTHS.LG || width === SCREEN_WIDTHS.XL);
    const open = Boolean(anchorEl);
    const isMobileView = (width === SCREEN_WIDTHS.XS);
    const location = useLocation().pathname;

    // handle the click on navbar
    const handleClick = (type) => {
        switch (type) {
            case ROUTES.SCHOOLS:
                return history.push('/schools');
            case ROUTES.INTERNSHIPS:
                return history.push('/internships');
            case ROUTES.COURSES:
                return history.push('/courses');
            case ROUTES.BE_A_TINKERER:
                return history.push('/beatinkerer');
            case ROUTES.FRS:
                return (window.open('https://frs.itinker.io', "_blank"));
            case ROUTES.ABOUT:
                return history.push('/about');
            case ROUTES.BLOG:
                return history.push('/blogs');
            case ROUTES.DOWNLOAD:
                return history.push('/download');
            case ROUTES.EDIT_DETAILS:
                return history.push({
                    pathname: '/',
                    state: {screen: 'editProfile'},
                });
            case ROUTES.MYPROFILE:
                return history.push({
                    pathname: '/',
                    state: {screen: 'myProfile'},
                });
            case ROUTES.MYCOURSES:
                return history.push({
                    pathname: '/',
                    state: {screen: 'courses'},
                });
            case ROUTES.DASHBOARD:
                return history.push({
                    pathname: '/',
                    state: {screen: ''},
                });
            case ROUTES.INVITE:
                return history.push({
                    pathname: '/',
                    state: {screen: 'inviteFriends'},
                });
            case ROUTES.HELPDESK:
                return history.push({
                    pathname: '/',
                    state: {screen: 'helpDesk'},
                });
            case ROUTES.CONTACT_US:
                return history.push('/contact');
            case ROUTES.PRIVACY:
                return (window.open('https://www.itinker.io/privacy', "_blank"));
            default:
                return history.push('/');
        }
    };

    // mobile menu for logged in user
    const renderLoggedInMobileMenu = () => {

        const handleItemClick = (route) => {
            handleClick(route);
            handleClose();
        }

        return (
            <Drawer
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    user.role === Constants.teacher ?
                        <>
                            <TeacherMobileMenu/>
                        </>
                        :
                        <>
                            <StudentMobileMenu/>
                        </>
                }
            </Drawer>
        );
    };

    // tab menu for logged in user
    const renderLoggedInTabMenu = () => {

        const handleItemClick = (route) => {
            handleClick(route);
            handleClose();
        }

        return (
            <Drawer
                anchorEltab={anchorEltab}
                keepMounted
                open={Boolean(anchorEltab)}
                onClose={handleClose}
            >
                {
                    user.role === Constants.teacher ?
                        <>
                            <TeacherTabMenu/>
                        </>
                        :
                        <>
                            <StudentTabMenu/>
                        </>
                }
            </Drawer>
        );
    };


    // mobile menu for not logged in user
    const renderMainMobileMenu = (location) => {

        return (
            <>
                <Drawer className={TabClasses.main}
                    //from which side the drawer slides in
                        anchor="right"
                    //if open is true --> drawer is shown
                        open={open}
                    //function that is called when the drawer should close
                        onClose={handleClose}
                    //function that is called when the drawer should open
                        onOpen={Boolean(anchorEl)}
                >
                    {/* The inside of the drawer */}
                    <Box className={TabClasses.box}>
                        {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                        <div className={TabClasses.icons}>
                            <img src={cross} className={TabClasses.icon} onClick={handleClose}/>
                        </div>
                        <Box className={TabClasses.innerBox}>
                            <div className={TabClasses.items} onClick={() => handleClick(ROUTES.HOME)}>
                                <span onClick={handleClose}
                                      className={location == '/' ? TabClasses.isActiveText : TabClasses.text}>Home</span>
                            </div>
                            <Divider/>
                            <div className={TabClasses.items} onClick={() => handleClick(ROUTES.BE_A_TINKERER)}>
                                <span
                                    onClick={handleClose}
                                    className={location == '/beatinkerer' ? TabClasses.isActiveText : TabClasses.text}>Be a Tinkerer</span>
                            </div>
                            <Divider/>
                            <div className={TabClasses.items} onClick={() => handleClick(ROUTES.FRS)}>
                                <span
                                    onClick={handleClose}
                                    className={location == '/https://frs.itinker.io' ? TabClasses.isActiveText : TabClasses.text}>FRS</span>
                            </div>
                            <Divider/>
                            <div className={TabClasses.items} onClick={() => handleClick(ROUTES.ABOUT)}>
                                <span
                                    onClick={handleClose}
                                    className={location == '/about' ? TabClasses.isActiveText : TabClasses.text}>About Us</span>
                            </div>
                            <Divider/>
                            <div className={TabClasses.items} onClick={() => handleClick(ROUTES.CONTACT_US)}>
                                <span
                                    onClick={handleClose}
                                    className={location == '/contact' ? TabClasses.isActiveText : TabClasses.text}>CONTACT US</span>
                            </div>
                        </Box>
                    </Box>
                </Drawer>
            </>
        );
    };


    const handleMainMobileClick = (event) => {
        setAnchorEl(event.currentTarget);
        setAnchorEltab(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEltab(null);
    };

    function handleLogin() {
        setOpenPopup(!openPopup);
    }


    const loggedInDesktopMenu = (params) => {
        const open = Boolean(anchorEl);

        const NotificationMessage = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleProfileNameClose = () => {
            setAnchorEl(null);
        };

        return (
            <div className={classes.menu}>
                <Imgix className={classes.notifyIcon}
                       src="/itinker-assets/Dashboard/notifications.svg"
                       onClick={NotificationMessage}/>
                <Menu style={{top: "50px", left: 1100,}}
                      anchorEl={anchorEl}
                      open={open}
                      variant={'menu'}
                      onClick={handleProfileNameClose}
                      onClose={handleProfileNameClose}
                      TransitionComponent={Fade}>
                    <Typography>There are no notifications as for now</Typography>
                </Menu>
                <UserProfileDropDown
                    dropdownImg={profileImage ? profileImage : "https://tinkernxt.imgix.net/itinker-assets/Dashboard/Placeholders/altUserImg.png"}
                    handleClick={() => handleClick(ROUTES.EDIT_DETAILS)}
                    logOut={handleLogout}
                />
            </div>
        );
    }

    function RenderNavbarTitle() {
        return (
            <Typography
                className={classes.title}
                variant="h5"
                id="iTinker_home"
                onClick={handleHomeClick}
            >
                iTinker
            </Typography>
        )
    }


    const renderDesktopMenu = () => {
        return (
            <div className={classes.menu}>

                {/*<Button*/}
                {/*    id="nav_courses_btn"*/}
                {/*    className={classes.menuOption}*/}
                {/*    onClick={() => handleClick(ROUTES.COURSES)}*/}
                {/*>*/}
                {/*    Courses*/}
                {/*</Button>*/}

                <Button
                    id="nav_beatinkerer_btn"
                    className={classes.menuOption}

                    onClick={() => handleClick(ROUTES.BE_A_TINKERER)}
                >
                    Be a Tinkerer
                </Button>
                <Button
                    id="nav_frs_btn"
                    className={classes.menuOption}
                    onClick={() => handleClick(ROUTES.FRS)}
                >
                    FRS
                </Button>
                <Button
                    id="nav_about_btn"
                    className={classes.menuOption}
                    onClick={() => handleClick(ROUTES.ABOUT)}
                >
                    About Us
                </Button>
                <Button
                    id="nav_about_btn"
                    className={classes.menuOption}
                    onClick={() => handleClick(ROUTES.CONTACT_US)}
                >
                    Contact Us
                </Button>
                {/*<hr className={classes.hrLine} />*/}
                {/*<Button*/}
                {/*    id="nav_login_btn"*/}
                {/*    className={`${classes.menuOptionDownload} ${classes.downloadBtn}`}*/}
                {/*    variant="contained"*/}
                {/*    onClick={handleLogin}*/}
                {/*>*/}
                {/*    Login*/}
                {/*</Button>*/}
                {/*{openPopup &&*/}
                {/*<UserLoginPopup open={openPopup}*/}
                {/*                onLoginComplete={() => {*/}
                {/*                    window.location.reload();*/}
                {/*                }}*/}
                {/*                setOpen={setOpenPopup} source={"Navbar"}/>*/}
                {/*}*/}
            </div>
        );
    };

    const handleHomeClick = () => {
        return history.push('/');
    };

    const handleLogout = () => {
        let cookies = new Cookies();
        cookies.remove(Constants.auth_token, {
            path: '/',
            secure: Constants.secure,
            domain: Constants.domain
        });
        auth.setUser(null);
        window.location.reload();
    };

    function RenderMenuIconBtn({loggedInClass}) {

        return (
            <IconButton
                edge="start"
                className={` ${classes.menuButton} ${loggedInClass}`}
                color="default"
                aria-label="main nav"
                onClick={handleMainMobileClick}
            >
                <MenuIcon/>
            </IconButton>
        )
    }

    function RenderNavbarMenu({location}) {
        const open = Boolean(anchorSideBarEl);
        const NotificationMessage = (event) => {
            setAnchorSideBarEl(event.currentTarget);
        };

        const handleProfileNameClose = () => {
            setAnchorSideBarEl(null);
        };
        return (
            <>
                {isScreenDesktop ?
                    <>

                        <RenderNavbarTitle/>
                        {user ? loggedInDesktopMenu() : renderDesktopMenu()}
                    </>
                    :
                    user ?
                        <>
                            {
                                isMobileView ?
                                    <>
                                        <RenderMenuIconBtn loggedInClass={classes.loggedInMenuBtn}/>
                                        <RenderNavbarTitle/>
                                        <Imgix className={classes.notifyIcon}
                                               src="/itinker-assets/Dashboard/notifications.svg"
                                               onClick={NotificationMessage}
                                        />
                                        <Menu style={{top: "50px", left: "90px", marginTop: "-1px"}}
                                              anchorEl={anchorSideBarEl}
                                              open={open}
                                              variant={'menu'}
                                              onClick={handleProfileNameClose}
                                              onClose={handleProfileNameClose}
                                              TransitionComponent={Fade}>
                                            <Typography>There are no notifications as for now</Typography>
                                        </Menu>
                                        {anchorEl && renderLoggedInMobileMenu()}
                                    </>
                                    :
                                    <>
                                        <RenderMenuIconBtn loggedInClass={classes.loggedInMenuBtn}/>
                                        <RenderNavbarTitle/>
                                        <Imgix className={classes.notifyIcon}
                                               src="/itinker-assets/Dashboard/notifications.svg"
                                               onClick={NotificationMessage}
                                        />
                                        <Menu style={{top: "52px", left: "460px", marginTop: "-1px"}}
                                              anchorEl={anchorSideBarEl}
                                              open={open}
                                              variant={'menu'}
                                              onClick={handleProfileNameClose}
                                              onClose={handleProfileNameClose}
                                              TransitionComponent={Fade}>
                                            <Typography>There are no notifications as for now</Typography>
                                        </Menu>
                                        <UserProfileDropDown
                                            dropdownImg={profileImage ? profileImage : "https://tinkernxt.imgix.net/itinker-assets/Dashboard/Placeholders/altUserImg.png"}
                                            handleClick={() => handleClick(ROUTES.EDIT_DETAILS)}
                                            logOut={handleLogout}
                                        />
                                        {/*{anchorEltab && renderLoggedInTabMenu()}*/}
                                    </>
                            }
                        </>
                        :
                        <>
                            <RenderNavbarTitle/>
                            <RenderMenuIconBtn/>
                            {anchorEl && renderMainMobileMenu(location)}
                        </>
                }

            </>
        )
    }

    return (
        <ScrollToColor {...props}>
            <AppBar className={classes.mainAppbar}>
                <Toolbar className={classes.toolbar}>
                    <RenderNavbarMenu location={location}/>
                </Toolbar>
            </AppBar>
        </ScrollToColor>
    );
};
export default withWidth()(Navbar);
