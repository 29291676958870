import {makeStyles} from "@material-ui/core/styles";


const styles = makeStyles((theme) => ({
  main: {
    height: '100%',
    padding: '40px 100px 40px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px 40px 20px',
    },
  },
  panel: {
    width: '247px !important',
    margin: 30,
  },
  section: {
    margin: '20px 10px 30px 10px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  panel1: {
    width: '220px !important',
    marginLeft: 16,
    '& img': {
      width: 14,
    },

  },
  subNav: {
    margin: '0 auto',
    width: 150,
    [theme.breakpoints.down('xs')]: {
      marginTop: '-45%', 
      marginBottom: '15%'
    },
    [theme.breakpoints.between('sm','md')]: {
      marginTop: '-18%', 
      marginBottom: '3%'
    }
  },
  topHead: {
    textAlign: 'center',
    '& h4': {
      color: '#000000',
      fontFamily: 'OpenSansBold',
      fontSize: 38,
      [theme.breakpoints.down('sm')]: {
        fontSize:  25
      }
    },
  },
  panelImg: {
    width: 70,
    zIndex: 3,
  },
  sliderSection: {
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  sliderText: {
    fontSize: 23,
    color:'#2b2b2b',
    fontFamily: 'OpenSandRegular',
    fontWeight: 'bold',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  sliderSubText: {
    fontSize: 18,
    color: theme.palette.secondary.paraFontColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },
  text: {
    marginTop: 20,
    fontSize: 18,
    opacity: 0.7,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    }
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 530,
    
  },
  content: {
    position: 'relative',
    backgroundColor: theme.palette.other.white,
    zIndex: 2,
    width: 330,
    height: 500,
    borderRadius: 12,
    marginTop: '10%',
    boxShadow: '0 1.5px 20px 0 rgba(0, 0, 0, 0.27)',
    textAlign: 'center',
    marginLeft: 78,
    [theme.breakpoints.down('sm')]: {
      marginLeft: -2,
      height: 320
    },
    '& p': {
      textAlign: 'left',
    },
  },
  innerContent: {
    padding: 22,
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '34%',
    marginTop: '-25%',
    marginBottom: '5%',
  },
  quotes: {
    width: 100,
    height: 100,
    borderRadius: 55,
  },
  bottomSection: {
    backgroundColor: theme.palette.other.lightestGrey,
    display: 'flex',
    padding: 20,
    justifyContent: 'space-between',
  },
}));

export default styles;