export function BlogModel() {
    this.id = "";
    this.readableid = "";
    this.blogdescription = "";
    this.blogpointers = [];
    this.browsearticlespicture = "";
    this.description = "";
    this.name = "";
    this.picture = "";
}

/**
 * This function is used to create a blog model from the json
 * @param json: takes json as input
 * @returns {BlogModel}: return BlogModel with provided json
 * @constructor
 */
export function BlogModelFromJson(json) {
    let blog = new BlogModel();
    blog.id = json['id'];
    blog.readableid = json['readableid'];
    blog.blogdescription = json['blogdescription'];
    blog.blogpointers = json['blogpointers'];
    blog.browsearticlespicture = json['browsearticlespicture'];
    blog.description = json['description'];
    blog.name = json['name'];
    blog.picture = json['picture'];
    return blog;
}

export function TeamMemberModel() {
    this.id = "";
    this.name = "";
    this.designation = "";
    this.linkedin = "";
    this.description = "";
    this.picture = "";
    this.country = "";
}

/**
 * This function is used to create a team member model from the json
 * @param json: takes json as input
 * @returns {TeamMemberModel}: return TeamMemberModel with provided json
 * @constructor
 */
export function TeamMemberModelFromJson(json) {
    let teamMember = new TeamMemberModel();
    teamMember.id = json['id'];
    teamMember.name = json['name'];
    teamMember.designation = json['designation'];
    teamMember.linkedin = json['linkedin'];
    teamMember.description = json['description'];
    teamMember.picture = json['picture'];
    teamMember.country = json['country'];
    return teamMember;
}

export function CompetitionModel() {
    this.id = "";
    this.picture = "";
    this.data = {};
}

/**
 * This function is used to create a competition model from the json
 * @param json: takes json as input
 * @returns {CompetitionModel}: return CompetitionModel with provided json
 * @constructor
 */
export function CompetitionModelFromJson(json) {
    let competition = new CompetitionModel();
    competition.id = json['id'];
    competition.picture = json['picture'];
    competition.data = json['data'];
    return competition;
}


export function DownloadPageReviewModel() {
    this.id = "";
    this.name = "";
    this.designation = "";
    this.rating = 0;
    this.review = "";
}

/**
 * This function is used to create a download page review model from the json
 * @param json: takes json as input
 * @returns {DownloadPageReviewModel}: return DownloadPageReviewModel with provided json
 * @constructor
 */
export function DownloadPageReviewModelFromJson(json) {
    let review = new DownloadPageReviewModel();
    review.id = json['id'];
    review.name = json['name'];
    review.designation = json['designation'];
    review.rating = json['rating'];
    review.review = json['review'];
    review.picture = json['picture'];
    return review;
}

export function CoursePageFeatureModel() {
    this.id = "";
    this.image = "";
    this.text = "";
}

/**
 * This function is used to create a course page feature model from the json
 * @param json: takes json as input
 * @returns {CoursePageFeatureModel}: return CoursePageFeatureModel with provided json
 * @constructor
 */
export function CoursePageFeatureModelFromJson(json) {
    let feature = new CoursePageFeatureModel();
    feature.id = json['id'];
    feature.image = json['image'];
    feature.text = json['text'];
    return feature;
}

export function JobFundModel() {
    this.id = "";
    this.title = "";
    this.description = "";
    this.creationdate = "";
}

/**
 * This function is used to create a job fund model from the json
 * @param json: takes json as input
 * @returns {JobFundModel}: return JobFundModel with provided json
 * @constructor
 */
export function JobFundModelFromJson(json) {
    let fund = new JobFundModel();
    fund.id = json['id'];
    fund.title = json['title'];
    fund.description = json['description'];
    fund.creationdate = json['creationdate'];
    return fund;
}

export function InternshipModel() {
    this.id = "";
    this.title = "";
    this.description = "";
    this.creationdate = "";
    this.lastdate = "";
}

/**
 * This function is used to create a internship model from the json
 * @param json: takes json as input
 * @returns {InternshipModel}: return InternshipModel with provided json
 * @constructor
 */
export function InternshipModelFromJson(json) {
    let internship = new InternshipModel();
    internship.id = json['id'];
    internship.title = json['title'];
    internship.description = json['description'];
    internship.creationdate = json['creationdate'];
    internship.lastdate = json['lastdate'];
    return internship;
}

export function HomePageScoreModel() {
    this.id = "";
    this.score = "";
    this.subtitle = "";
    this.text = "";
}

/**
 * This function is used to create a home page score model from the json
 * @param json: takes json as input
 * @returns {HomePageScoreModel}: return HomePageScoreModel with provided json
 * @constructor
 */
export function HomePageScoreModelFromJson(json) {
    let score = new HomePageScoreModel();
    score.id = json['id'];
    score.score = json['score'];
    score.subtitle = json['subtitle'];
    score.text = json['text'];
    return score;
}

export function VideoModel() {
    this.id = "";
    this.source = "";
}

/**
 * This function is used to create a video model from the json
 * @param json: takes json as input
 * @returns {VideoModel}: return VideoModel with provided json
 * @constructor
 */
export function VideoModelFromJson(json) {
    let video = new VideoModel();
    video.id = json['id'];
    video.source = json['source'];
    return video;
}

export function HomePageReviewModel() {
    this.id = "";
    this.userimage = "";
    this.review = "";
}

/**
 * This function is used to create a home page review model from the json
 * @param json: takes json as input
 * @returns {HomePageReviewModel}: return HomePageReviewModel with provided json
 * @constructor
 */
export function HomePageReviewModelFromJson(json) {
    let review = new HomePageReviewModel();
    review.id = json['id'];
    review.userimage = json['userimage'];
    review.review = json['review'];
    return review;
}