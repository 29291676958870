import {whyChooseTinkerStyles as useStyles} from "./styles";
import bulb from '../../assets/BeATinkerer/blue.png';
import pc from '../../assets/BeATinkerer/pink 2.png';
import airplane from '../../assets/BeATinkerer/orange 2.png';

function WhyChooseITinker() {
    const classes = useStyles();
    return (
        <div className={classes.Main}>
            <div className={classes.Heading}>
                <div className={classes.SubHeading}>Why choose&nbsp;</div>
                <div  className={classes.Perk} style={{color: "#ff8501"}}>@iTinker?</div>
            </div>
            <div className={classes.Cards}>
                {
                    arrayOfWhyChoose.map((data) => (
                        <div className={classes.Card1}>
                            <div className={classes.Icons}><img className={classes.Icon} src={data.image}/></div>
                            <div className={classes.Title}>{data.title}</div>
                            <div className={classes.Line}></div>
                            <div className={classes.Info}> {data.info}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const arrayOfWhyChoose = [
    {
        image: bulb,
        title: "Internship Programs",
        info: "Internship programs that provide you corporate experience at a very young age."
    },
    {
        image: pc,
        title: "Workshops",
        info: "Skill development workshops focusing on future technologies to bring your ideas to life."
    },
    {
        image: airplane,
        title: "Competitions",
        info: "Several International and National competitions to help you showcase your acquired skills."
    },
]
export default WhyChooseITinker;

