import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useState} from "react";
import {UserProfileDropDown as useStyles} from "./styles";
import Imgix from "react-imgix";


//For user to choose whether to edit profile or logout
export const UserProfileDropDown = (params) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    //handles when the user click on the profile name on dashboard
    const handleProfileNameClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //handles when the user wants to close the menu opened
    const handleProfileNameClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>
            {/*shows the profile Image as button to open the menu*/}
                <img className={classes.userProfile}
                     id="user_profile_image"
                     src={params.dropdownImg}
                     onClick={handleProfileNameClick}
                     alt=""/>
            {/* menu having edit you details and logout options*/}
            <Menu style={{top:"50px", }}
                anchorEl={anchorEl}
                open={open}
                variant={'menu'}
                onClick={handleProfileNameClose}
                onClose={handleProfileNameClose}
                TransitionComponent={Fade}
            >
                <MenuItem
                    onClick={params.handleClick} >
                    <Imgix src="/itinker-assets/Dashboard/MyProfie.png" className={classes.icon}/>
                    Edit Profile
                </MenuItem>
                <MenuItem
                    onClick={params.logOut} >
                    <Imgix src="/itinker-assets/Dashboard/LogOut.png" className={classes.icon}/>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}
