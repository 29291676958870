import { makeStyles } from '@material-ui/core/styles';
export const styles = makeStyles((theme) => ({
  main: {
    height: '70vh',
    padding: '0px 40px 40px 40px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: '0px 40px 40px 0',
    },
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '36px 0px 0px 36px',
    marginTop: 58,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  leftSection: {
    // padding: '0px 40px 0px 40px',
    position: 'relative',
    zIndex: 2,
  },
  rightSection: {
    position: 'relative',
  },
  mainText: {
    fontSize: 45,
    color: theme.palette.secondary.headFontColor,
    fontFamily: 'OpenSansBold',
    paddingTop: 60,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '12%',
      fontSize: 15,
      textAlign: 'center',
      
    },
    [theme.breakpoints.between('sm','md')]: {
      fontSize: 21,
      textAlign: 'center',
      paddingTop: 20,
      marginLeft: '9%'
    }
  },

  descText: {
    fontSize: 23,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      padding: '10px 10px 10px 40px',
      textAlign: 'justify',
      marginRight: '-3%', 
      marginBottom: '37%', 
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 16,
      padding: '10px 10px 10px 20px',
      textAlign: 'center', 
      marginLeft: '3%', 
      marginBottom: '15%'
    },
  },
  phone: {
    width: 420,
    height: 345,
    zIndex: 3,
    position: 'relative',
    left: 108,
    [theme.breakpoints.down('xs')]: {
      left: 81,
      width: 220,
      height: 172,
      top: 52,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      top: -10,
      left: 230,
      width: 370, 
      height: 345
    },
  },
  blueCloud: {
    position: 'relative',
    left: 20,
    width: 381,
    bottom: 336,
    height: 307,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      left: 16,
      width: 354,
      bottom: 320,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 321,
    },
  },
  backMesh: {
    position: 'relative',
    bottom: 509,
    width: 170,
    left: 40,
    zIndex: 2,
  },

  rightPanel: {
    [theme.breakpoints.down('sm')]: {
      height: 460,
    },
  },
}));
