import React, {useEffect} from 'react';
import {styles as useStyles} from './styles';
import TogetherAtTinker from "../../components/Jobs/TogetheratTinker";
import BeingATinkerer from "../../components/Jobs/BeingATinkerer";
import LifeAtTinker from "../../components/Jobs/LifeAtTinker";
import MakeADifference from "../../components/Jobs/MakeADifference";
import ReadyToMake from "../../components/Jobs/ReadyToMake";

const Home = () => {
    const classes = useStyles();
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div className={classes.main}>
            <TogetherAtTinker/>
            <BeingATinkerer/>
            <LifeAtTinker/>
            <MakeADifference/>
            <ReadyToMake/>
        </div>
    );
};

export default Home;
