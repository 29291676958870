import React, {useEffect, Suspense} from 'react';
import GetStarted from '../../components/Home/GetStarted/GetStarted';
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import {Helmet} from 'react-helmet';
import {styles as useStyles} from './styles';

const BroughtToYouByAndTechnologies = React.lazy(() => import('../../components/Home/BroughtToYouByAndTechnologies/BroughtToYouByAndTechnologies'));
const WhatsInForYou = React.lazy(() => import('../../components/Home/WhatsInForYou/WhatsInForYou'));
const HorizontalSlider = React.lazy(() => import('../../components/Home/GetAllTechnical/HorizontalSlider'));
const LetTheNumbersAndIntel = React.lazy(() => import('../../components/Home/LetTheNumbersAndIntel/LetTheNumbersAndIntel'));
const WhatPeopleAreSayingAboutUs = React.lazy(() => import('../../components/Home/WhatPeopleAreSayingAboutUs/WhatPeopleAreSayingAboutUs'));

const Home = () => {
    const classes = useStyles();
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div className={classes.main}>
            <Helmet>
                <meta name="description"
                      content="iTinker India's Leading Tech-Ed Company where kids can learn Latest Future Technology like Artificial Intelligence, Block Coding and Many more from our Experts. "/>
                <title>iTinker - Skilling The Next Billion</title>
            </Helmet>
            <GetStarted/>
            <Suspense fallback={<LoadingComponent/>}>
                <BroughtToYouByAndTechnologies/>
                <WhatsInForYou/>
                <HorizontalSlider/>
                <LetTheNumbersAndIntel/>
                <WhatPeopleAreSayingAboutUs/>
            </Suspense>
        </div>
    );
};

export default Home;

function LoadingComponent() {
    return (
        <div>
            <p>Loading <CachedRoundedIcon/></p>
        </div>
    )
}