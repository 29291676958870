import {TogetherAtTinkerStyles as useStyles} from "./styles";
import heroImageWeb from "../../assets/Jobs/Web_careers.png";
import heroImageIpad from "../../assets/Jobs/iPad_Careers.png";
import heroImageMobile from "../../assets/Jobs/Mobile_Careers.png";
import {Button} from "@material-ui/core";

function TogetherAtTinker() {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.Heading}>Together at iTinker</div>
            <div className={classes.Info}>
               <span> We are on a mission to build a secure, open and transparent social learning ecosystem.</span> <span>Are you ready to
                be part of this success?</span>
            </div>
            <div>
                <a href="https://www.linkedin.com/company/itinker/" target="_blank" style={{textDecoration:"none"}}>
                    <Button className={classes.btn}>Join Us</Button>
                </a>
            </div>
            <div className={classes.Image}>
                <div className={classes.ImageWeb}>
                    <img className={classes.heroImageBlur} src={heroImageWeb}></img>
                    <img className={classes.heroImage} src={heroImageWeb}></img>
                </div>
                <div className={classes.ImageTab}>
                    <img className={classes.heroImageBlur} src={heroImageIpad}></img>
                    <img className={classes.heroImage} src={heroImageIpad}></img>
                </div>
                <div className={classes.ImageMobile}>
                    <img className={classes.heroImageBlur} src={heroImageMobile}></img>
                    <img className={classes.heroImage} src={heroImageMobile}></img>
                </div>
            </div>
        </div>
    )
}

export default TogetherAtTinker;