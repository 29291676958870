import React, {useContext, useState} from 'react';
import {MenuItem} from "@material-ui/core";
import {styles as useStyles} from "./styles";
import {useHistory} from "react-router-dom";
import Cookies from "universal-cookie";
import {Constants} from "../../utils/Constants";
import {AuthContext} from "../../utils/AuthContext";
import Imgix from "react-imgix";

const ROUTES = {
    HOME: 1,
    SCHOOLS: 2,
    COURSES: 3,
    TINKER_WORLD: 4,
    FRS: 5,
    ABOUT: 6,
    BLOG: 7,
    DOWNLOAD: 8,
    EDIT_DETAILS: 9,
    MYPROFILE: 10,
    MYCOURSES: 11,
    DASHBOARD: 12,
    INVITE: 13,
    HELPDESK: 14,
    PRIVACY: 15,
};

export function TeacherMobileMenu() {
    const classes = useStyles();
    let history = useHistory();
    let auth = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEltab, setAnchorEltab] = useState(null);

    const handleItemClick = (route) => {
        handleClick(route);
        handleClose();
    }

    const handleLogout = () => {
        let cookies = new Cookies();
        cookies.remove(Constants.auth_token, {
            path: '/',
            secure: Constants.secure,
            domain: Constants.domain
        });
        auth.setUser(null);
        window.location.reload();
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEltab(null);
    };

    const handleClick = (type) => {
        switch (type) {
            case ROUTES.SCHOOLS:
                return history.push('/schools');
            case ROUTES.COURSES:
                return history.push('/courses');
            case ROUTES.TINKER_WORLD:
                return history.push('/tinkerworld');
            case ROUTES.FRS:
                return (window.open('https://frs.itinker.io', "_blank"));
            case ROUTES.ABOUT:
                return history.push('/about');
            case ROUTES.BLOG:
                return history.push('/blogs');
            case ROUTES.DOWNLOAD:
                return history.push('/download');
            case ROUTES.EDIT_DETAILS:
                return history.push({
                    pathname: '/',
                    state: {screen: 'editProfile'},
                });
            case ROUTES.MYPROFILE:
                return history.push({
                    pathname: '/',
                    state: {screen: 'myProfile'},
                });
            case ROUTES.MYCOURSES:
                return history.push({
                    pathname: '/',
                    state: {screen: 'courses'},
                });
            case ROUTES.DASHBOARD:
                return history.push({
                    pathname: '/',
                    state: {screen: ''},
                });
            case ROUTES.INVITE:
                return history.push({
                    pathname: '/',
                    state: {screen: 'inviteFriends'},
                });
            case ROUTES.HELPDESK:
                return history.push({
                    pathname: '/',
                    state: {screen: 'helpDesk'},
                });
            case ROUTES.PRIVACY:
                return (window.open('https://www.itinker.io/privacy', "_blank"));
            default:
                return history.push('/');
        }
    };

    return(
        <div>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.DASHBOARD)}>
                <Imgix src="/itinker-assets/Dashboard/Home-Icon-Final.svg" className={classes.icon}/>
                Dashboard
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.MYCOURSES)}>
                <Imgix src="/itinker-assets/Dashboard/Courses-Icon-Final.svg" className={classes.icon}/>
                My Classroom
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.MYPROFILE)}>
                <Imgix src="/itinker-assets/Dashboard/Profile-Icon-Final.svg" className={classes.icon}/>
                My Profile
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.HELPDESK)}>
                <Imgix src="/itinker-assets/Dashboard/Phone-Icon-Final.svg" className={classes.icon}/>
                Help Desk
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.PRIVACY)}>
                <Imgix src="/itinker-assets/Dashboard/Policy-Icon-Final.svg" className={classes.icon}/>
                Privacy Policy
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={handleLogout}>
                <Imgix src="/itinker-assets/Dashboard/Policy-Icon-Final.svg" className={classes.icon}/>
                Logout
            </MenuItem>
        </div>
    )
}

export function StudentMobileMenu() {
    const classes = useStyles();
    let history = useHistory();
    let auth = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEltab, setAnchorEltab] = useState(null);

    const handleItemClick = (route) => {
        handleClick(route);
        handleClose();
    }

    const handleLogout = () => {
        let cookies = new Cookies();
        cookies.remove(Constants.auth_token, {
            path: '/',
            secure: Constants.secure,
            domain: Constants.domain
        });
        auth.setUser(null);
        window.location.reload();
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEltab(null);
    };

    const handleClick = (type) => {
        switch (type) {
            case ROUTES.SCHOOLS:
                return history.push('/schools');
            case ROUTES.COURSES:
                return history.push('/courses');
            case ROUTES.TINKER_WORLD:
                return history.push('/tinkerworld');
            case ROUTES.FRS:
                return (window.open('https://frs.itinker.io', "_blank"));
            case ROUTES.ABOUT:
                return history.push('/about');
            case ROUTES.BLOG:
                return history.push('/blogs');
            case ROUTES.DOWNLOAD:
                return history.push('/download');
            case ROUTES.EDIT_DETAILS:
                return history.push({
                    pathname: '/',
                    state: {screen: 'editProfile'},
                });
            case ROUTES.MYPROFILE:
                return history.push({
                    pathname: '/',
                    state: {screen: 'myProfile'},
                });
            case ROUTES.MYCOURSES:
                return history.push({
                    pathname: '/',
                    state: {screen: 'courses'},
                });
            case ROUTES.DASHBOARD:
                return history.push({
                    pathname: '/',
                    state: {screen: ''},
                });
            case ROUTES.INVITE:
                return history.push({
                    pathname: '/',
                    state: {screen: 'inviteFriends'},
                });
            case ROUTES.HELPDESK:
                return history.push({
                    pathname: '/',
                    state: {screen: 'helpDesk'},
                });
            case ROUTES.PRIVACY:
                return (window.open('https://www.itinker.io/privacy', "_blank"));
            default:
                return history.push('/');
        }
    };

    return(
        <div>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.DASHBOARD)}>
                <Imgix src="/itinker-assets/Dashboard/Home-Icon-Final.svg" className={classes.icon}/>
                Dashboard
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.MYCOURSES)}>
                <Imgix src="/itinker-assets/Dashboard/Courses-Icon-Final.svg" className={classes.icon}/>
                My Classroom
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.MYPROFILE)}>
                <Imgix src="/itinker-assets/Dashboard/Profile-Icon-Final.svg" className={classes.icon}/>
                My Profile
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.HELPDESK)}>
                <Imgix src="/itinker-assets/Dashboard/Phone-Icon-Final.svg" className={classes.icon}/>
                Help Desk
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={() => handleItemClick(ROUTES.PRIVACY)}>
                <Imgix src="/itinker-assets/Dashboard/Policy-Icon-Final.svg" className={classes.icon}/>
                Privacy Policy
            </MenuItem>
            <MenuItem className={classes.listItem}
                      onClick={handleLogout}>
                <Imgix src="/itinker-assets/Dashboard/Policy-Icon-Final.svg" className={classes.icon}/>
                Logout
            </MenuItem>
        </div>
    )
}