import {BecomeATinkererStyles as useStyles} from './styles';
import heroImageIpad from "../../assets/BeATinkerer/ipad_BAT.png";
import heroImageMobile from "../../assets/BeATinkerer/Mobile_BAT.png";
import heroImageWeb from "../../assets/BeATinkerer/webView.png";


/**
 * render the UI for 'be a tinkerer' screen main component
 * @returns {JSX.Element}
 * @constructor
 */
const BecomeATinkerer = () => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <span className={classes.Heading}>Become a Tinkerer!</span>
            <span className={classes.Info}>Explore opportunities that we provide and upgrade your skills.</span>
            <div className={classes.Image}>
                <div className={classes.ImageWeb}>
                    <img className={classes.heroImageBlur} src={heroImageWeb}></img>
                    <img className={classes.heroImage} src={heroImageWeb}></img>
                </div>
                <div className={classes.ImageTab}>
                    <img className={classes.heroImageBlur} src={heroImageIpad}></img>
                    <img className={classes.heroImage} src={heroImageIpad}></img>
                </div>
                <div className={classes.ImageMobile}>
                    <img className={classes.heroImageBlur} src={heroImageMobile}></img>
                    <img className={classes.heroImage} src={heroImageMobile}></img>
                </div>
            </div>
        </div>
    )
}
export default BecomeATinkerer;