import {OurValues as useStyles} from "./styles";
import OurValuesImage1 from '../../assets/ourValuesImage1.png';
import OurValuesImage2 from '../../assets/ourValuesImage2.png';
import OurValuesImage3 from '../../assets/ourValuesImage3.png';
import OurValuesImage4 from '../../assets/ourValuesImage4.png';

function OurValues() {
    const classes = useStyles();
    return (
        <div className={classes.OurValuesBackground}>
            <div className={classes.OurValues}>
                <div className={classes.OurValuesHeading}>
                    Our Values
                </div>
                <div className={classes.OurValuesDescription}>
                    Our values drive how we work together and how we show up for our young minds. These core values
                    shape what we aspire to be. They help us empower our people and make better decisions.
                </div>
            </div>
            <div className={classes.OurValuesImageSection}>
                {
                    arrayDataofOurValues.map((ourValuesData) => (
                        <OurValuesImageSection props={ourValuesData}/>
                    ))
                }
            </div>
        </div>
    )
}

const arrayDataofOurValues = [
    {
        id: 1,
        image: OurValuesImage1,
        heading: "Trust",
        description: "We trust one another to do the right thing, always.",
    },
    {
        id: 2,
        image: OurValuesImage2,
        heading: "Respect",
        description: "We respect one another's personal & professional choices.",
    },
    {
        id: 3,
        image: OurValuesImage3,
        heading: "Aspiration",
        description: "We aspire to change the education, for better.",
    },
    {
        id: 4,
        image: OurValuesImage4,
        heading: "Empowerment",
        description: "We aim to empower the future generation with the right skills.",
    }
]

const OurValuesImageSection = ({props}) => {
    const classes = useStyles();
    return (
        <div className={classes.OurValuesImageAndData}>
            <div>
                <img src={props.image} className={classes.OurValueImages}/>
            </div>
            <div className={classes.OurFourValues}>
                <div className={classes.FourValuesHeading}>
                    {props.heading}
                </div>
                <div className={classes.FourValuesDescription}>
                    {props.description}
                </div>
            </div>
        </div>
    )
}
export default OurValues;