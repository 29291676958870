import React, {useEffect, useState} from 'react';
import Navbar from './components/Navbar/Navbar';
import ScrollTop from './components/Navbar/ScrollTop';
import {Fab, Toolbar} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MainRouter from './router';
import Footer from './components/Footer/Footer';
import {SignInToNativeAppFirebase, SignInToWebsite} from "./services/firebase";
import {AuthContext} from './utils/AuthContext';
import {CheckUserAuth} from "./services/firebase";
import {UserModelFromJson} from "./models/UserModels";
import {Constants} from "./utils/Constants";
import Cookies from 'universal-cookie';
import {ImgixProvider} from "react-imgix";


const MainApp = () => {
    const cookies = new Cookies();
    const [currentPath, setCurrentPath] = useState('/');
    const [user, setUser] = useState(null);

    useEffect(() => {
        setCurrentPath(window.location.pathname);
        // SignInToWebsite().then(() => console.log("Firebase Loaded"));
        // SignInToNativeAppFirebase().then(() => console.log("Fb"));
        // CheckUserAuth().then(async (res) => {
        //     if (res !== "" && res !== null) {
        //         cookies.set(Constants.auth_token, res.token, {
        //             path: '/',
        //             secure: Constants.secure,
        //             domain: Constants.domain
        //         });
        //         let user = new UserModelFromJson(res.user);
        //         setUser(user);
        //     }
        // });
    }, []);

    return (
        <AuthContext.Provider value={{
            user: user,
            setUser: setUser,
        }}>
            <ImgixProvider domain="tinkernxt.imgix.net">
            <MainRouter>
                {currentPath!=='/404' && <Navbar/>}
                <Toolbar id="back-to-top-anchor"/>
                <ScrollTop>
                    <Fab color="secondary" size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpIcon/>
                    </Fab>
                </ScrollTop>
            </MainRouter>{' '}
            {/* not render the footer in the dashboard*/}
            {currentPath!=='/404' && !user && <Footer/>}
            </ImgixProvider>
        </AuthContext.Provider>
    );
};

export default MainApp;
