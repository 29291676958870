import { makeStyles } from '@material-ui/core/styles';
import {Constants} from "../../../utils/Constants";
export const styles = makeStyles((theme) => ({
  main: {
    height: '100vh',
    backgroundImage: `url(${Constants.image_cdn}/itinker-assets/Download/RectangleBg.jpg)`,
    backgroundSize: 'cover',
    padding: '20px 40px 0px 0',
    position: 'relative',
    bottom: 66,
    zIndex: 3,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: '60px 40px 92px 0',
    },
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '36px 0px 0px 36px',
    marginTop: 238,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '0px 0px 0px 0px',
    },
  },
  leftSection: {
    // padding: '0px 40px 0px 40px',
    position: 'relative',
    zIndex: 2,
  },
  rightSection: {
    position: 'relative',
  },
  mainText: {
    fontSize: 45,
    color: theme.palette.secondary.headFontColor,
    fontFamily: 'OpenSansBold',
    padding: '40px 0 0 0',

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 15,
      marginLeft: '18%'
    },
  
    [theme.breakpoints.between('sm','md')]: {
      fontSize: 21,
      textAlign: 'center',
      padding: '0 0 0 80px',
    }
  },

  descText: {
    fontSize: 23,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      padding: '10px 10px 10px 20px',
      textAlign: 'justify', 
      marginLeft: '8%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 16,
      padding: '10px 10px 10px 20px',
      textAlign: 'center', 
      marginLeft: '3%'
    },

  },
  phone: {
    width: 370,
    height: 345,
    zIndex: 3,
    position: 'relative',
    left: 102,
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: 220,
      height: 172,
      marginLeft: '-6%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      left: 238,
    },
  },
  yellowCloud: {
    position: 'relative',
    bottom: 364,
    left: 70,
    width: 400,
    height: 400,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      left: -24,
      width: 414,
      bottom: 363,
      height: 425,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 350,
    },
  },
  backMesh: {
    position: 'relative',
    bottom: 567,
    width: 170,
    left: 122,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      left: 57,
      width: 150,
      bottom: 545,
    },
  },
  rightPanel: {
    paddingLeft: 70,
  },
  left: {
    height: 400,
  },
}));
