import Imgix from "react-imgix";
const CustomArrow = (props) => {
  const { className, onClick, direction } = props;
  if (direction === 'left') {
    return (
      <Imgix
        className={className}
        onClick={onClick}
        src="itinker-assets/About/PeopleBehind/arrow-left.png"
        alt="arrow-left"
      />
    );
  }
  return (
    <Imgix
      src="/itinker-assets/About/PeopleBehind/arrow-right.png"
      alt="arrow-right"
      className={className}
      onClick={onClick}
    />
  );
};
export default CustomArrow;
