import {makeStyles} from '@material-ui/core/styles';

export const TogetherAtTinkerStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: "#fff2e1",
        height: "580px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "-69px"

    },
    Heading: {
        fontFamily: 'Montserrat-Bold',
        fontSize: " 52px",
        fontWeight: " bold",
        paddingTop: "217px",
        marginBottom: "14px",
        color: "#11246f",

    },
    Info: {
        fontFamily: 'Montserrat-Medium',
        fontSize: "24px",
        fontWeight: " 500",
        textAlign: "center",
        margin: "auto",
        width: "60%",
        marginBottom: "26px"

    },
    Image: {
        justifyContent: "center",
        marginTop: "50px",
        width: "100%",
        display: "flex",
    },
    heroImageBlur: {
        height: "478px",
        width: "75%",
        position: "absolute",
        zIndex: "-1",
        margin: "20px 0 0 29px",
        opacity: "0.5",
        borderRadius: "14px",
        filter: "blur(13px)",
    },
    heroImage: {
        display: "block",
        height: "478px",
        borderRadius: "14px",
        width: "100%",
    },

    ImageWeb: {
        [theme.breakpoints.only('sm')]: {
            display: "none",
        },
        [theme.breakpoints.only('xs')]: {
            display: "none",
        },
    },
    ImageTab: {
        display: "none",
        [theme.breakpoints.only('sm')]: {
            display: "block",
        },
        [theme.breakpoints.only('xs')]: {
            display: "none",
        },
    },
    ImageMobile: {
        display: "none",
        [theme.breakpoints.only('sm')]: {
            display: "none",
        },
        [theme.breakpoints.only('xs')]: {
            display: "block",
        },
    },
    btn: {
        width: "166px",
        height: "52px",
        textDecoration: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        color: "white",
        fontFamily: 'Montserrat-SemiBold',
        borderRadius: "5px",
        backgroundColor: '#f48c06',
        fontSize: "18px",
        fontWeight: "600",
    },

    [theme.breakpoints.only('sm')]: {
        Heading: {
            fontSize: "30px",
            paddingTop: "151px",
            marginBottom: "0px"
        },
        Info: {
            fontSize: "18px",
            width: "80%",
            margin: "2%",
        },
        heroImageBlur: {
            height: "416px",
            width: "656px",
        },
        heroImage: {
            height: "416px",
            width: "702px",
        },
    },
    [theme.breakpoints.only('xs')]: {
        main: {
            height: "353px"
        },
        Heading: {
            fontSize: "25px",
            paddingTop: "120px",
            marginBottom: "6px",
        },
        Info: {
            fontSize: "15px",
            width: "94%",
            margin: "2%",
            marginBottom: "25px",
        },
        heroImageBlur: {
            height: "220px",
            width: "286px",
        },
        heroImage: {
            height: "220px",
            width: "340px",
        },
    }
}))

export const BeingATinkererStyles = makeStyles((theme) => ({
    Main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "44%",
        marginBottom: "50px",
    },
    Heading: {
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: 'Montserrat-Bold',
        color: "#11246f",
        marginBottom: "5.5%",
        display: "flex",
    },
    Cards: {
        display: "flex",
        width: "85%",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
    },
    Card1: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "341.6px",
        height: "326.4px",
        marginTop: "2%",
        marginBottom: "10%",
        backgroundColor: "#fff",
        borderRadius: " 11.4px",
        boxShadow: "0 5.7px 34.2px 0 rgba(38, 45, 118, 0.08)",
    },
    Icons: {
        position: "relative",
        bottom: "44px",
    },
    Line: {
        width: "160px",
        height: "2px",
        flexGrow: "0",
        marginTop: "-12px",
        backgroundColor: "#fff2e1",
    },
    Title: {
        fontSize: "25px",
        marginBottom: "2.5%",
        fontWeight: "bold",
        fontFamily: 'Montserrat-Bold',
        color: "#11246f",
        position: "relative",
        bottom: "21px",
        width: "78%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "61px"
    },
    Info: {
        textAlign: "center",
        fontSize: "18px",
        fontWeight: "500",
        width: "83%",
        fontFamily: 'Montserrat-Medium',
        color: "#000",
        position: "relative",
        top: "33px",
    },
    Icon: {
        width: "76px",
        height: "76px",
    },
    IconArrow: {
        width: "40px",
        height: "40px",
        position: "relative",
        bottom: "16px",
        right: "9px",
    },
    [theme.breakpoints.only('sm')]: {
        Main: {
            marginTop: "47%"
        },
        Heading: {
            fontSize: "25px",
            marginBottom: "91px",
        },
        Cards: {
            width: "100%",
        },
        Card1: {
            width: "329.9px",
            height: "315.2px",
        },
        Icon: {
            width: "73.3px",
            height: "73.3px",
        },
        Title: {
            fontSize: "24.1px",
        },
        IconArrow: {
            bottom: "19px",
            right: "9px",

        },
    },
    [theme.breakpoints.only('xs')]: {
        Main: {
            marginTop: "76%"
        },
        Heading: {
            flexWrap: "wrap",
            width: "90%",
            fontSize: "18px",
            alignItems: "baseline",
            justifyContent: "center",
            marginBottom: "60px",
        },
        Cards: {
            width: "100%",
        },
        Card1: {
            width: "256px",
            height: "244.8px",
            marginBottom: "18%",
        },

        Icons: {
            bottom: "30px",
        },
        Icon: {
            width: "57px",
            height: "57px",
        },
        Title: {
            fontSize: "18px",
            width: "50%",
            margin: "0",
            marginBottom: "-11px"
        },
        Info: {
            fontSize: "13px",
        },
        Perk: {
            marginLeft: "4%",
        },
        Line: {
            width: "120px",
            height: "1.5px",
            margin: "0",
        },
        IconArrow: {
            bottom: "5px",
            right: "1px",
            width: "25px",
            height: "25px",


        },

    }
}))

export const LifeAtTinkerStyles = makeStyles((theme) => ({
    Main: {
        backgroundColor: "#fff2e1",
        height: "940px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "15%"
    },
    Heading: {
        marginTop: "89px",
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: 'Montserrat-Bold',
        color: "#11246f",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "5%"
    },
    IconArrow: {
        width: "60px",
        height: "60px",
        position: "relative",
        top: "16px",
        right: "122px",
    },
    ContentImage: {
        display: "flex",
        flexDirection: "row",
        width: "79.5%",
        alignItems: "flex-start",
    },
    Info: {
        fontFamily: 'Montserrat-Regular',
        fontWeight: 400,
        fontSize: "18px",
        width: "90%",
        lineHeight: "1.8",
    },
    playIcon: {
        zIndex: "1",
        position: "absolute",
        width: "50px",
        height: "50px",
        marginLeft: "250px",
        marginTop: "156px",
    },
    Content: {
        display: "flex",
        flexDirection: "column",
        height: "99%",
        justifyContent: "space-between",
    },
    Images: {
        display: "flex",
        flexDirection: "column",
        height: "102%",
        justifyContent: "space-between",
    },
    Image: {
        width: "574px",
        height: "295px"
    },
    LaptopImage: {
        width: "555px",
        height: "364px"
    },
    btn: {
        width: "330px",
        height: "52px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "7px",
        backgroundColor: '#f48c06',
        fontSize: "18px",
        color: "white",
        fontFamily: 'Montserrat-SemiBold',
    },
    [theme.breakpoints.only('sm')]: {
        Main: {
            height: "1120px",
        },
        Heading: {
            fontSize: "25px",
            marginTop: "73px",
            marginBottom: "10%",
        },
        ContentImage: {
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
        },
        Info: {
            fontSize: "18px",
            marginTop: "4%",
            marginBottom: "4%",
            width: "75%",
            textAlign: "center",
        },
        Content: {
            alignItems: "center",
        },
        LaptopImage: {
            width: "555px",
            height: "346px",
        },
        Images: {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: " 1fr",
            gridColumnGap: "12px",
            gridRowGap: "0px",
            marginTop: "10%",
        },
        IconArrow: {
            width: "45px",
            height: "45px",
            top: "16px",
            right: "94px",
        },
        Image: {
            width: "348px",
            height: "179px",
        },
    },
    [theme.breakpoints.only('xs')]: {
        Main: {
            height: "997px",
            marginBottom: "30%",
        },
        Heading: {
            fontSize: "18px",
            marginTop: "40px",
            marginBottom: "10%",
        },
        ContentImage: {
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
        },
        Info: {
            fontSize: "13px",
            marginTop: "4%",
            marginBottom: "8%",
            width: "90%",
            textAlign: "center",
        },
        Content: {
            alignItems: "center",
        },
        LaptopImage: {
            width: "340px",
            height: "214px",
        },
        Images: {
            display: "flex",
            marginTop: "17%",
        },
        IconArrow: {
            width: "40px",
            height: "40px",
            top: "12px",
            right: "68px",
        },
        Image: {
            width: "338px",
            height: "173.8px",
            marginBottom: "2%",
        },
        playIcon: {
            marginTop: "78px",
            marginLeft: "147px",
        },

        btn: {
            width: "291px",
            height: "41px",
        }

    },

}))

export const MakeADifferenceStyles = makeStyles((theme) => ({
    main: {
        height: "750px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "0",
    },
    Heading: {
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: 'Montserrat-Bold',
        color: "#11246f",
        marginBottom: "70px",
    },
    content: {
        display: "flex",
        flexDirection: "row",
        width: "80%",
        alignItems: "flex-start",
    },
    About: {
        fontSize: "18px",
        fontFamily: 'Montserrat-Regular',
        color: "#000",
        fontWeight: "normal",
        lineHeight: '1.8',
        width: "90%",
        marginBottom: "8%",
    },
    Info: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    DoRight: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridTemplateRows: "repeat(2, 1fr)",
        gridColumnGap: "0px",
        gridRowGap: "50px",
        width: "90%",
    },
    Card: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "260px"
    },

    Title: {
        fontSize: "18px",
        fontWeight: "500",
        fontFamily: 'Montserrat-Medium',
        marginLeft: "8%",
        width: "56%"
    },
    Image: {
        width: "595px",
        height: "427px",
    },
    Icon: {
        width: "32px",
        height: "32px",
    },

    [theme.breakpoints.only('sm')]: {
        main: {
            marginTop: "25%",
            marginBottom: "50%",
        },
        Heading: {
            fontSize: "25px",
            marginBottom: "30px",
        },
        content: {
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
        },
        Info: {
            alignItems: "center",
            width: "100%",
            marginBottom: "80px",
        },
        DoRight: {
            width: "68%",
            gridColumnGap: "79px",
        },
        About: {
            fontSize: "18px",
            width: "75%",
            textAlign: "center",
            marginBottom: "15%",
        },
    },
    [theme.breakpoints.only('xs')]: {
        main: {
            marginBottom: "12%",
        },
        Heading: {
            fontSize: "18px",
            marginBottom: "50px"
        },
        content: {
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
        },
        Info: {
            alignItems: "center",
            width: "100%",
            marginBottom: "80px",
        },
        DoRight: {
            width: "72%",
            gridColumnGap: "0px",
        },
        About: {
            fontSize: "13px",
            width: "80%",
            textAlign: "center",
        },
        Title: {
            fontSize: "13px",
            width: "66%",
        },
        Image: {
            width: "340px",
            height: "220px",
        },
        Icon: {
            width: "24px",
            height: "24px",
        }, Card: {
            width: "111%"
        },

    }
}))

export const ReadyToMakeStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: "#f6f9ff",
        height: "347px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    Heading: {
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: 'Montserrat-Bold',
        color: "#11246f",
        marginTop: "43px",
    },
    Info: {
        fontSize: "18px",
        fontWeight: "normal",
        fontFamily: 'Montserrat-Regular',
        color: "#000",
        lineHeight: "1.8",
        width: "46%",
        textAlign: "center",
        marginTop: "12px",
        marginBottom: "54px",
    },
    btn: {
        width: "214px",
        height: "52px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "5px",
        backgroundColor: '#f48c06',
        fontWeight: "600",
        fontSize: "18px",
        color: "white",
        fontFamily: 'Montserrat-SemiBold',
    },
    Image: {
        width: "268px",
        height: "239px",
        position: "relative",
        bottom: "262px",
        left: "540px",


    },
    [theme.breakpoints.only('sm')]: {
        main: {
            height: "406px",
        },
        Heading: {
            fontSize: "25px",
            marginTop: "113px",
        },
        Info: {
            fontSize: "15px",
            width: "76%",
            marginTop: "28px",
        },
        Image: {
            width: "154px",
            height: "129px",
            left: "275px",
            bottom: "331px",
        },
    },
    [theme.breakpoints.only('xs')]: {
        main: {
            height: "284px",
        },
        Heading: {
            fontSize: "18px",
            marginTop: "64px",
        },
        Info: {
            fontSize: "13px",
            width: "90%",
        },
        Image: {
            width: "77px",
            height: "64px",
            left: "134px",
            bottom: "250px",
        },
    }
}))


