import {makeStyles} from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
    main:{
        minWidth:"375px",
    },
    mainBe: {
        width: "85%",
        margin: "auto",
        [theme.breakpoints.between('xs', 'sm')]: {
            width: "100%",
        }
    },
    subMain: {
        backgroundColor: "#fff2e1",
        padding: "30px 0",
        marginBottom:"59px",
    },
    whyInternship: {
        padding: "30px 8%",
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: "0",
            marginTop:'35px'
        }
    },

}))