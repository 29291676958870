/**
 * This is User Model to store the values of the user
 * @constructor
 */
export function UserModel() {
    this.uid = "";
    this.phone = "";
    this.countrycode = "";
    this.dob = "";
    this.webuser = false;
    this.appuser = false;
    this.webcreatedtimestamp = "";
    this.lastmodifiedtimestamp = "";
    this.username = "";
    this.email = "";
    this.lastname = "";
    this.firstname = "";
    this.usrimgurl = "";
    this.usrcoverimgurl = "";
    this.ipaddresses = {};
    this.apprefcode = "";
    this.role = "";
    this.status = "";
    this.currency = "";
    this.schoolid = "";
}


/**
 * This function is used to create a user model from the json
 * @param json: takes json as input
 * @returns {UserModel}: return UserModel with provided json
 * @constructor
 */
export function UserModelFromJson(json) {
    let user = new UserModel();
    user.uid = json['uid'];
    user.phone = json['phone'];
    user.countrycode = json['countrycode'];
    user.dob = json['dob'];
    user.webuser = json['webuser'];
    user.appuser = json['appuser'];
    user.webcreatedtimestamp = json['webcreatedtimestamp'];
    user.lastmodifiedtimestamp = json['lastmodifiedtimestamp'];
    user.username = json['username'];
    user.email = json['email'];
    user.lastname = json['lastname'];
    user.firstname = json['firstname'];
    user.usrimgurl = json['usrimgurl'];
    user.usrcoverimgurl = json['usrcoverimgurl'];
    user.ipaddresses = json['frsipddresses']
    user.apprefcode = json['apprefcode'];
    user.role = json['role'];
    user.status = json['status'];
    user.currency = json['currency'];
    user.schoolid = json['schoolid'];
    return user;
}


/**
 * Function to create the jsonified version of a user
 * @param user takes user model as a parameter to convert it to json
 * @returns {string} returns jsonified string of the user for the requests
 * @constructor
 */
export function UserModelToJson(user) {
    return JSON.stringify({
        "uid": user.uid,
        "phone": user.phone,
        "countrycode": user.countrycode,
        "dob": user.dob,
        "webuser": user.webuser,
        "webcreatedtimestamp": user.webcreatedtimestamp,
        "lastmodifiedtimestamp": user.lastmodifiedtimestamp,
        "username": user.username,
        "email": user.email,
        "lastname": user.lastname,
        "firstname": user.firstname,
        "usrimgurl": user.usrimgurl,
        "usrcoverimgurl": user.usrcoverimgurl,
        "frsipddresses": user.frsipddresses,
        "appuser": user.appuser,
        "apprefcode": user.apprefcode,
        "role": user.role,
        "status": user.status,
        "currency": user.currency,
        "schoolid":user.schoolid
    });
}


/**
 *
 * @constructor
 */
export function CustomerLeadModel() {
    this.status = "";
    this.phone = "";
    this.email = "";
    this.name = "";
    this.id = "";
    this.comments = "";
    this.source = "";
    this.assignedTo = "";
}

/**
 *
 * @param json
 * @returns {CustomerLeadModel}
 * @constructor
 */
export function CustomerLeadModelFromJson(json) {
    let lead = new CustomerLeadModel();
    lead.id = json['id'];
    lead.phone = json['phone'];
    lead.name = json['name'];
    lead.email = json['email'];
    lead.status = json['status'];
    lead.source = json['source'];
    lead.comments = json['comments'];
    lead.assignedTo = json['assignedto']
    return lead;
}

/**
 *
 * @param customer
 * @returns {string}
 * @constructor
 */
export function CustomerLeadModelToJson(customer) {
    return JSON.stringify({
        "id": customer.id,
        "phone": customer.phone,
        "email": customer.email,
        "name": customer.name,
        "status": customer.status,
        "source": customer.source,
        "comments": customer.comments,
        "assignedto": customer.assignedTo,
    });
}
export function UserGameModel() {
    this.uid = "";
    this.currentRankId = "";
    this.achievements = [];
    this.currentPoints = {};
}

export function UserGameModelFromJson(json) {
    let game = new UserGameModel();
    game.uid = json['uid'];
    game.currentRankId = json['currentrankid'];
    game.achievements = json['achievements'];
    game.currentPoints = json['currentpoints'];
    return game;
}

/**
 *
 * @constructor
 */
export function TechnovationLeadModel() {
    this.id = "";
    this.name = "";
    this.phone = "";
    this.email = "";
    this.age = 0;
    this.schoolName = "";
    this.schoolCity = "";
    this.timestamp = 0;
}

export function TechnovationLeadToJson(lead) {
    return JSON.stringify({
        "id": lead.id,
        "name": lead.name,
        "email": lead.email,
        "phone": lead.phone,
        "age": lead.age,
        "schoolname": lead.schoolName,
        "schoolcity": lead.schoolCity,
        "timestamp": lead.timestamp,
    });
}
