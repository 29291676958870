import {makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
    userInfoSideDiv: {
        width: "100%",
        display: "flex",
        marginBottom: "120px",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: 319,
        },
        [theme.breakpoints.between('sm', '970')]: {
            width: 704,
        }
    },
    userInfoDiv: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        gap: 20,
        width: "598px",
        borderRadius: "25px",
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: "relative",
            top: "50%",
        },
        [theme.breakpoints.between('sm', '970')]: {
            top: 0,
            width: "95%",
            marginLeft: '2.5%'
        }
    },
    filledInputStyle: {
        display: "flex",
        backgroundColor: "transparent",
        alignItems: "center",
        width: "100%",
        border: "solid 0.1px #000",
        flexDirection: "row",
        justifyContent: "flex-start",
        borderRadius: "6.2px",
        '& div': {
            width: "100%",
        },
        '&:hover': {
            backgroundColor: "white",
        },
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    addToCart: {
        backgroundColor: "#f48c06",
        color: theme.palette.other.white,
        borderRadius: 5,
        border: "none",
        fontSize: 18,
        width: "25%",
        height: 52,
        textTransform: 'capitalize',
        fontWeight: 'bold',
        display: "block",
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('sm')]: {
            width: 155,
            height: 41
        }
    },
    disableSubmitButton: {
        backgroundColor: "#b2b2b2",
        color: theme.palette.other.white,
        borderRadius: 5,
        border: "none",
        fontSize: 18,
        width: "25%",
        height: 52,
        textTransform: 'capitalize',
        fontWeight: 'bold',
        display: "block",
        [theme.breakpoints.down('sm')]: {
            width: 155,
            height: 41
        }
    },
    outerDiv: {
        backgroundImage: "linear-gradient(180deg, #fff3e4 50%, #fff 50%)",
        backgroundSize: "cover",
        height: 790,
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            backgroundImage: "linear-gradient(180deg, #fff3e4 25%, #fff 1%)",
            flexDirection: "column",
            justifyContent: 'flex-start',
            height: 1200
        },
        [theme.breakpoints.between('sm', '970')]: {
            width: "100%",
            height: 838
        },
    },
    SuccessfulPaymentBoy: {
        margin: "auto",
        paddingTop: "40px",
        height: "120px",
        display: "block",
    },
    outerBookDiv: {
        borderRadius: "20px",
        boxShadow: "0 2px 11.5px 0 rgba(0, 0, 0, 0.15)",
        backgroundColor: "#ffff",
        width: 1222.7,
        height: 600,
        padding: "15px",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            marginTop: '56px',
            position: 'relative',
            width: 319,
            margin: 'auto',
            height: 541,
            borderRadius: 3.2
        },
        [theme.breakpoints.down('330')]: {
            width: 260,
        },
        [theme.breakpoints.between('sm', '970')]: {
            width: 704,
            borderRadius: 12.2,
            boxShadow: "0 0 22.4px 0 rgba(0, 0, 0, 0.1)",
            paddingBlock: "10%",
        },
        marginTop: "10%"
    },
    subHeading: {
        marginTop: "1.5%",
        fontFamily: 'Montserrat-Regular',
        alignItems: "center",
        fontSize: "18px",
        lineHeight: 1.2,
        opacity: 0.6,
        color: " rgba(0, 0, 0)",
        marginBottom: "50px",
        [theme.breakpoints.down('sm')]: {
            width: 270,
            height: 10,
            fontSize: 13,
            fontWeight: 500,
            color: "#000000",
            fontFamily: 'Montserrat-Medium',
            textAlign: "center",
            marginBottom: "0",
            marginTop: "10px"
        },
        [theme.breakpoints.between('sm', '970')]: {
            width: 603,
            height: 18,
            fontSize: 15,
            textAlign: 'center',
            fontStyle: "normal",
            fontWeight: 'normal',
            marginBottom: "0",
        }

    },
    terms: {
        textAlign: "center",
        marginTop: "10px",
        display: "block",
        fontSize: "10px",
        color: "#7e7e7e",
    },
    error: {
        textAlign: "center",
        fontSize: "14",
        color: "red",
    },
    thankYou: {
        display: "block",
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.67)",
        fontSize: "36px",
        fontWeight: "bold",
        marginBottom: "10px",
        marginTop: "30px",

    },
    applyPromoButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.other.white,
        borderRadius: "25px",
        border: "none",
        fontSize: 15,
        width: "auto",
        textTransform: 'capitalize',
        fontWeight: 'bold',
        marginTop: "30px",
        height: "42px",
        paddingLeft: "20px",
        paddingRight: "20px",
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    buttonParent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    information: {
        display: "block",
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.62)",
        fontSize: "20px",
        fontWeight: "600",
        marginTop: "10px",
        marginBottom: "20px",

    },
    phone: {
        width: "15px",
        marginRight: "10px",
        marginLeft: "50px",
    },
    mail: {
        width: "15px",
        marginRight: "10px",
    },
    contactOuterDiv: {
        display: "block", textAlign: "center",
    },
    tripleCircle: {
        width: "0px",
        position: "relative",
        left: "15%",
        top: "35%",
    },
    asset2: {
        width: "90px",
        position: "absolute",
    },
    rightHalfCloud: {
        width: "70px",
        position: "absolute",
        top: "45%",
        [theme.breakpoints.down('xs')]: {
            zIndex: "-1",
        },
    },
    leftBottomAsset2: {
        width: "90px",
    },
    leftBottomtripleCircle: {
        width: "130px",
        position: "relative",
        bottom: "-60px",
        left: "20%",
        zIndex: "-1",
    },
    example: {
        width: "auto",
        height: "320px",
        marginLeft: "100px",
        marginTop: "200px",
        right: "0",
        [theme.breakpoints.between('sm', '970')]: {
            height: "0px",
            top: "60vh",
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
            zIndex: "-1",
        },
    },
    rightBottomAsset3: {
        width: "110px",
        float: "right",
        position: "relative",
        top: "70px",
    },
    topRightasset2: {
        width: "110px",
        position: "absolute",
        right: "0",
        top: "30vh",
    },
    topRightTripleCircle: {
        width: "130px",
        position: "absolute",
        right: "12%",
        top: "50vh",
    },
    leftHalfCloud: {
        width: "70px",
        right: "0",
        position: "absolute",
        top: "80vh",
        [theme.breakpoints.between('sm', '970')]: {
            top: "60vh",
        },
        [theme.breakpoints.down('xs')]: {
            zIndex: "-1",
        },
    },
    namePhone: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: 'center',
        gap: "22.4px",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            gap: 12
        },
        [theme.breakpoints.between('sm', '970')]: {
            flexDirection: 'row',
            alignItems: 'center'
        }
    },
    contactAndUserInfoBoxParent: {
        display: "flex",
        justifyContent: "space-around",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 0,
        },
        [theme.breakpoints.between('sm', '970')]: {}

    },
    contactInfoBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-start',
        [theme.breakpoints.between('sm', '970')]: {
            flexDirection: 'row',
            gap: 32,
            marginBottom: "4%",
        }
    },
    contactInfoParent: {
        display: "flex",
        marginInlineStart: '20px',
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            position: "relative",
            top: "30%",
            gap: 20
        },
        [theme.breakpoints.between('sm', '970')]: {
            top: '0%',
            marginInlineStart: 0,
        }
    },
    subDiv: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        fontSize: 15,
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 13,
        marginBlock: "2.5rem",
        [theme.breakpoints.down('sm')]: {
            marginBlock: '2rem',
            marginLeft: 17,
        }
    },
    icon: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.5rem !important",
        }
    },
    heading: {
        fontFamily: 'Montserrat-Bold',
        fontSize: 42,
        fontWeight: "bolder",
        letterSpacing: "normal",
        color: "#11246f",
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
            lineHeight: 1.2,
            width: 172,
            height: 30,
            fontWeight: "bold",
            color: "#11246f",
            marginBottom: "0",
        },
        [theme.breakpoints.between('sm', '970')]: {
            width: 207,
            height: 36,
            color: "#000",
            lineHeight: 1.2,
            fontSize: 30,
            fontWeight: 'bold',
            marginBottom: "0",
        }
    },
    contactInfoText: {
        fontFamily: 'Montserrat-SemiBold',
        fontSize: 23,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.between('sm', '970')]: {
            display: 'none',

        }
    },
    headingSubHeadContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            position: "relative",
            top: "25%"
        },
        [theme.breakpoints.between('sm', '970')]: {
            height: 68,
            position: 'static',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    contact: {
        letterSpacing: "normal",
        fontFamily: 'Montserrat-Regular',
        fontSize: "15px",
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        }
    }
}));
