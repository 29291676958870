import {BrowserRouter as Router, Switch, Route, useLocation, Redirect} from 'react-router-dom';
import Home from './pages/Home/Home';
import NoMatch from './pages/NoMatch/NoMatch';
import About from './pages/About/About';
import BeATinkerer from './pages/BeATinkerer/BeATinkerer';
import JobsMain from './pages/Jobs/Jobs';
import DownloadApp from './pages/DownloadApp/DownloadApp';
import Terms from './pages/Terms/Terms';
import ContactUs from "./pages/ContactUs/ContactUs";
import Privacy from "./pages/Privacy/Privacy";

const MainRouter = ({children}) => {
    // let auth = useContext(AuthContext);
    // let user = auth.user;
    // const [renderMobileScreen, setRenderMobileScreen] = useState('');


    // /**
    //  * Function to get the user dashboard, details and home page on behaviour depending on user login details.
    //  * @returns {JSX.Element}
    //  * @constructor
    //  */
    // function GetHome() {
    //     const location = useLocation();
    //     // if (user) {
    //     //     if (user.webuser) {
    //     //         if (location.state) {
    //     //             setRenderMobileScreen(location.state.screen);
    //     //         }
    //     //         return <DashboardMain
    //     //             renderMobileScreen={renderMobileScreen}
    //     //             setRenderMobileScreen={setRenderMobileScreen}/>
    //     //     } else {
    //     //         return <AddUserDetails/>
    //     //     }
    //     // } else {
    //         return <Home/>
    //     // }
    // }

    return (
        <Router>
            {children}
            <Switch>
                <Route exact path="/terms">
                    <Terms/>
                </Route>
                {/*<Route exact path="/internships">*/}
                {/*    <Internships/>*/}
                {/*</Route>*/}
                <Route exact path="/privacy">
                    <Privacy/>
                </Route>
                {/*<Route exact path="/tinkertimes">*/}
                {/*    <TinkerTimes/>*/}
                {/*</Route>*/}
                {/*<Route exact path="/schools">*/}
                {/*    <Schools/>*/}
                {/*</Route>*/}
                {/*<Route path="/blog/:id">*/}
                {/*    <BlogDetail/>*/}
                {/*</Route>*/}
                {/*<Route exact path="/blogs">*/}
                {/*    <Blog/>*/}
                {/*</Route>*/}
                <Route exact path="/download">
                    <DownloadApp/>
                </Route>
                <Route exact path="/careers">
                    <JobsMain/>
                </Route>
                {/*<Route exact path="/studentstory">*/}
                {/*    <StudentStory/>*/}
                {/*</Route>*/}
                <Route exact path="/beATinkerer">
                    <BeATinkerer/>
                </Route>
                {/*<Route exact path="/technology">*/}
                {/*    <Technology/>*/}
                {/*</Route>*/}
                {/*<Route path="/course/:readableid">`*/}
                {/*    <CourseDetail/>*/}
                {/*</Route>*/}
                {/*<Route path="/courses">*/}
                {/*    <Courses/>*/}
                {/*</Route>*/}
                {/*<Route exact path="/competitions">*/}
                {/*    <Competitions/>*/}
                {/*</Route>*/}
                <Route exact path="/about">
                    <About/>
                </Route>
                <Route path="/contact">
                    <ContactUs/>
                </Route>
                {/*<Route path="/technovation">*/}
                {/*    <Technovation/>*/}
                {/*</Route>*/}
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/home">
                    <Home/>
                </Route>
                <Route exact path="/404">
                    <NoMatch/>
                </Route>
                <Route>
                    <Redirect to="/404" />
                </Route>
            </Switch>
        </Router>
    );
};
export default MainRouter;
