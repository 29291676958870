import React, { useState } from 'react';
import { styles as useStyles } from './styles';
import { Typography } from '@material-ui/core';
import VideoModal from '../../AboutUs/VideoModal/VideoModal';
import AppVideoContainer from '../../Home/VideoContainer/AppVideoContainer';
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import Imgix from "react-imgix";
const ApplicationVideo = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.main}>
      <div className={classes.back}>
        <Imgix src="/itinker-assets/Download/ApplicationVideobg.jpg" alt="video" className={classes.videoBack} />
      </div>
      <div className={classes.overlay}>
        <div className={classes.blurSection}>
          <Typography className={classes.mainText}>
            ABOUT ITINKER MOBILE APP
          </Typography>

          <Typography className={classes.subText}>
            ITinker combines the best of industry leading platforms in E-learning,
            social media and networking. A curriculum rooted in the art of storytelling ,
            Combined with an unparalleled industry interface is sure to make your child
            future ready.
          </Typography>
        </div>
        {/*<div className={classes.blurSpace}/>*/}
        <div className={classes.playSection} onClick={() => setOpen(true)}>
          <div className={classes.flex}>
            <PrimaryButton
                text="Play Video"
                preIcon
                btnClass={classes.btn}
            />
          </div>
        </div>
      </div>
      {open && (
        <VideoModal open={open} setOpen={setOpen}>
          <AppVideoContainer />
        </VideoModal>
      )}
    </div>
  );
};
ApplicationVideo.propTypes = {};
export default ApplicationVideo;
