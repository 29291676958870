import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

import {styles as useStyles} from './styles';
import {Button, Grid, TextField, Typography} from '@material-ui/core';
import {addLead} from "../../../services/lead";
import Imgix from "react-imgix";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function BookAppointmentModal({open, setOpen, subheading, source, comments, heading}) {

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const handleBookAppointmentBtn = (name, email, phone, source, comments) => {
        addLead(name, email, phone, source, comments).then();
        setOpen(false)
    }

    function RenderHeading() {
        if (heading === undefined) {
            heading = "Book A FREE Session";
        }
        return (
            <Typography variant="h5">{heading}</Typography>
        )
    }

    function RenderSubHeading() {
        if (subheading === undefined) {
            subheading = "Book a 1:1 free and personalised session to make the best choice for your future";
        }
        return (
            <Typography variant="h6">{subheading}</Typography>
        )
    }

    return (
        <div>
            <Dialog
                classes={{
                    paper: classes.dialog,
                }}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                Width
                maxWidth={'sm'}
            >
                <DialogContent>
                    <div className={classes.main}>

                        <div>
                            <Imgix src="/itinker-assets/CheckOut/checkout.png" alt="asset" className={classes.asset}/>
                            <div className={classes.titleSection}>
                                <RenderHeading/>
                                <RenderSubHeading/>
                                <Typography className={classes.subTitle}>
                                    {/* Thank you deciding to buy our course, we wish you a very happy learning */}
                                </Typography>
                            </div>
                            <Grid container>
                                <Grid item sm={10} className={classes.fieldGrid}>
                                    <Typography className={classes.label}>Name</Typography>
                                    <TextField
                                        id="name"
                                        className={classes.textField}
                                        InputProps={{
                                            disableUnderline: true,
                                            autoComplete: false,
                                        }}
                                        InputLabelProps={{shrink: false}}
                                        onChange={(event) => setName(event.target.value)}
                                    />
                                </Grid>
                                {/*<Grid item sm={1}>*/}
                                {/*<Typography className={classes.label}>Age</Typography>*/}
                                {/*<TextField*/}
                                {/*  id="age"*/}
                                {/*  className={classes.textField}*/}
                                {/*  InputProps={{*/}
                                {/*    className: classes.input,*/}
                                {/*    disableUnderline: true,*/}
                                {/*    autoComplete: false,*/}
                                {/*  }}*/}
                                {/*  InputLabelProps={{ shrink: false }}*/}
                                {/*/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item sm={6}>*/}
                                {/*  <Typography className={classes.label}>Class</Typography>*/}
                                {/*  <TextField*/}
                                {/*    id="class"*/}
                                {/*    className={classes.textField}*/}
                                {/*    InputProps={{*/}
                                {/*      className: classes.input,*/}
                                {/*      disableUnderline: true,*/}
                                {/*    }}*/}
                                {/*    InputLabelProps={{ shrink: false }}*/}
                                {/*  />*/}
                                {/*</Grid>*/}
                                <Grid item sm={10} className={classes.fieldGrid}>
                                    <Typography className={classes.label}>Phone</Typography>
                                    <TextField
                                        id="phone"
                                        type="number"
                                        className={classes.textField}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        InputLabelProps={{shrink: false}}
                                        onChange={(event) => setPhone(event.target.value)}
                                    />
                                </Grid>

                                {/*<Grid item sm={1}>*/}
                                {/*</Grid>*/}
                                <Grid item sm={10} className={classes.fieldGrid}>
                                    <Typography className={classes.label}>Email</Typography>
                                    <TextField
                                        id="email"
                                        className={classes.textField}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        InputLabelProps={{shrink: false}}
                                        onChange={(event) => setEmail(event.target.value)}
                                    />

                                    {/*</Grid>*/}
                                    {/*<Grid item sm={6}>*/}
                                    {/*  <Typography className={classes.label}>City</Typography>*/}
                                    {/*  <TextField*/}
                                    {/*    id="city"*/}
                                    {/*    className={classes.textField}*/}
                                    {/*    InputProps={{*/}
                                    {/*      className: classes.input,*/}
                                    {/*      disableUnderline: true,*/}
                                    {/*    }}*/}
                                    {/*    InputLabelProps={{ shrink: false }}*/}
                                    {/*  />*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item sm={6}>*/}
                                    {/*  <Typography className={classes.label}>State</Typography>*/}
                                    {/*  <TextField*/}
                                    {/*    id="state"*/}
                                    {/*    className={classes.textField}*/}
                                    {/*    InputProps={{*/}
                                    {/*      className: classes.input,*/}
                                    {/*      disableUnderline: true,*/}
                                    {/*    }}*/}
                                    {/*    InputLabelProps={{ shrink: false }}*/}
                                    {/*  />*/}
                                    {/*</Grid>*/}
                                    <Grid item sm={10} className={classes.fieldGrid}>
                                        <Button className={classes.btn}
                                                onClick={() => handleBookAppointmentBtn(name, email, phone, source, comments)}>
                                            Book
                                        </Button>
                                        <Typography className={classes.terms}>Limited seats only. By clicking you agree to our <a href={"/terms"}>terms
                                            of
                                            use</a> & <a
                                            href={"/privacy"}>privacy policy</a></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>


                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
