import {LifeAtTinkerStyles as useStyles} from "./styles";
import arrow from "../../assets/Jobs/green_icon.png";
import laptopImage from "../../assets/Jobs/Laptop Image.png";
import internshipImageStudent from "../../assets/Jobs/Group 43.png";
import internshipImageTeacher from "../../assets/Jobs/Group 13317.png";
import playButton from "../../assets/YellowPlayIcon.png";
import ReactiveButton from "reactive-button";
import {useState} from "react";
import {Box, Modal, useMediaQuery, useTheme} from "@mui/material";
import {Button} from "@material-ui/core";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

function LifeAtTinker() {
    const classes = useStyles();
    const mediaTheme = useTheme();

    const iFrameSize = useMediaQuery(mediaTheme.breakpoints.between('xs', 'md'));

    const [open, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const handleOpen = (source) => {
        setOpen(true);
        setVideoUrl(source);
    };
    const handleClose = () => setOpen(false);

    return (

        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!iFrameSize && <iframe width="1060" height="615" src={videoUrl}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen>
                    </iframe>}
                    {iFrameSize && <iframe width="330" height="195" src={videoUrl}
                                           title="YouTube video player"
                                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                           allowFullScreen>
                    </iframe>}
                </Box>
            </Modal>
            <div className={classes.Main}>

                <div className={classes.Heading}>
                    <img className={classes.IconArrow} src={arrow}/>
                    <span className={classes.SubHeading}>Life at <span
                        style={{color: "#ff8501"}}> iTinker </span>
                </span>
                </div>
                <div className={classes.ContentImage}>
                    <div className={classes.Content}>
                        <div>
                            <img
                                className={classes.playIcon}
                                onClick={() => handleOpen("https://www.youtube.com/embed/DrV_8Rsyz64")}
                                src={playButton}/>
                            <img className={classes.LaptopImage} src={laptopImage}/>
                        </div>
                        <div className={classes.Info}>
                            Contributing to the development of innovative technologies that have the potential to
                            improve education and make learning more accessible, makes our work exciting and rewarding.
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/itinker/" target="_blank" style={{textDecoration:"none"}}>
                                <Button className={classes.btn}>BE A PART OF THE TEAM</Button>
                            </a>
                        </div>
                    </div>
                    <div className={classes.Images}>
                        <img className={classes.Image} src={internshipImageStudent}/>
                        <img className={classes.Image} src={internshipImageTeacher}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LifeAtTinker;