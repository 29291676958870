import {FromTheNationsWorldWide as useStyles} from './styles';
import NationsFlag from '../../assets/flags.png';
import TabletViewNationsFlag from '../../assets/ipadViewNationsFlag.png'
import MobileViewNationsFlag from '../../assets/MobileViewNationsFlag.png';
import BroughtToYouBy from '../AboutUs/BroughtToYouBy';
import {withWidth} from "@material-ui/core";
import {SCREEN_WIDTHS} from "../../utils/common";
import {useMediaQuery, useTheme} from "@mui/material";

function FromTheNationsWorldWide(props)
{
    const {width} = props;
    const isMobileView = (width === SCREEN_WIDTHS.XS);
    const mediaTheme = useTheme();
    const isTabletView = useMediaQuery(mediaTheme.breakpoints.between('sm', '970'));
    const classes= useStyles();
    return(
        <div className={classes.FromTheNationWorldWideBlock}>
            <BroughtToYouBy/>
            <div className={classes.FromTheNationsWorldWideHeading}>
                From the nations worldwide
            </div>
            { isMobileView?
                <img src={MobileViewNationsFlag} className={classes.NationsFlagImage} />
                :
                <>
                { isTabletView ?
                    <img src={TabletViewNationsFlag} className={classes.NationsFlagImage} />
                    :
                    <img src={NationsFlag} className={classes.NationsFlagImage} />
                }  </>
            }
        </div>
    )
}
export default withWidth()(FromTheNationsWorldWide);