import {ReadyToMakeStyles as useStyles} from "./styles";
import Image from "../../assets/Jobs/Abstract.png";
import {Button} from "@material-ui/core";


function ReadyToMake() {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.Heading}>Ready To Make An Impact ?</div>
            <div className={classes.Info}>
                We are on the lookout for those who will bring us closer to our vision,and make a difference.
            </div>
            <div>
                <a href="https://www.linkedin.com/company/itinker/" target="_blank" style={{textDecoration:"none"}}>
                    <Button className={classes.btn}>APPLY NOW</Button>
                </a>
            </div>
            <img className={classes.Image} src={Image}/>
        </div>
    )
}

export default ReadyToMake;