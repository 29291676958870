import React, {useEffect} from 'react';
import AboutBanner from '../../components/AboutUs/AboutBanner';
import YearlyData from '../../components/AboutUs/YearlyData'
import OurVision from '../../components/AboutUs/OurVision';
import OurValues from '../../components/AboutUs/OurValues';
import {styles as useStyles} from './styles';
import {Helmet} from "react-helmet";
import OurVisionImage3 from '../../assets/AboutUs/Rectangle 1108.png';
import OurVisionImage1 from '../../assets/AboutUs/Rectangle 1107.png';
import OurVisionImage2 from '../../assets/AboutUs/Rectangle 1106.png';
import OurMissionImage2 from '../../assets/AboutUs/OurMission.png';
import OurMissionImage1 from '../../assets/AboutUs/OurMission2.png';
import OurMissionImage3 from '../../assets/AboutUs/OurMission3.png';
import MeetTheTinkerers from '../../components/AboutUs/MeetTheTinkers';
import GlimpseOfLifeAtITinker from '../../components/AboutUs/GlimpseOfLifeAtITinker';
import FromTheNationsWorldWide from "../../components/AboutUs/FromTheNationWorldWide";

const Home = () => {
    const classes = useStyles();
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (

        <div className={classes.main}>
            <Helmet>
                <meta name="description"
                      content=" iTinker a step towards making The Next Billion future ready by teaching them the upcoming Technologies and Skills for future success."/>
                <title>About iTinker : Know Complete About iTinker and FRS AI based Application</title>
            </Helmet>
            <AboutBanner/>
            <YearlyData/>
            {
                arrayDataOfOurVisionAndMission.map((content) => {
                        if (content.heading === "Our Values")
                            return <OurValues/>
                        else if (content.heading === "Our Vision")
                            return <OurVision arrayDataOfOurVisionAndMission={content} extraStyles={classes.backgroundDiv}/>
                        else
                            return <OurVision arrayDataOfOurVisionAndMission={content} extraStyles={classes.backgroundMission}/>
                    }
                )}
            <MeetTheTinkerers/>
            <GlimpseOfLifeAtITinker/>
            <FromTheNationsWorldWide/>
        </div>
    );
};

const arrayDataOfOurVisionAndMission = [
    {
        colored: true,
        taViewRotated:false,
        heading: "Our Vision",
        description: " To make the Next Billion children future ready by teaching them the life skills necessary for future success, by exposing them to upcoming technological advancements and by onboarding them on a journey to build on their unique and special skills.",
        color: "orangeClass",
        OurVisionImage1:OurVisionImage1,
        OurVisionImage2:OurVisionImage2,
        OurVisionImage3:OurVisionImage3,
    },
    {
        colored: false,
        taViewRotated:true,
        heading: "Our Mission",
        description: "Open and Secure Eco System for all. To build a social learning ecosystem that is secure, open, transparent and easily adaptable to its environment.",
        color: "whiteClass",
        OurVisionImage1:OurMissionImage1,
        OurVisionImage2:OurMissionImage2,
        OurVisionImage3:OurMissionImage3,
    },
    {
        colored: true,
        taViewRotated:false,
        heading: "Our Values",
        description: "Open and Secure Eco System for all. To build a social learning ecosystem that is secure, open, transparent and easily adaptable to its environment.",
        color: "orangeClass"
    },
]

export default Home;
