import {FilledInput, FormControl, InputLabel} from "@material-ui/core";
import React from "react";


export default function InputFields(params){
    const {filledInputStyle,field,setField} = params;

    return (
        <FormControl  fullWidth={true} variant="filled"  className={filledInputStyle}>
            <InputLabel htmlFor="component-filled">Name</InputLabel>
            <FilledInput id="component-filled" disableUnderline={true}
                         className={filledInputStyle} defaultValue={''} value={field}
                         onChange={(e) => {
                             setField(e.target.value);
                         }}/>

        </FormControl>
    )
}