import {MeetTheTinkers as useStyles} from './styles';
import AshishImage from '../../assets/itinker web/ashish.png';
import SparshImage from '../../assets/itinker web/sparsh.png';
import AnshuImage from '../../assets/itinker web/anshu_web.png';
import DivyanshImage from '../../assets/itinker web/divyansh.png';
import AyushImage from '../../assets/itinker web/aayush.png';
import NitishImage from '../../assets/itinker web/nitish.png';
import MansiImage from '../../assets/itinker web/mansi.png';
import ThomasImage from '../../assets/itinker web/thomas_web.png';
import MahimaImage from '../../assets/itinker web/mahima.png';
import JahanviImage from '../../assets/itinker web/jahanvi.png';
import SanjeevImage from '../../assets/itinker web/sanjeev.png';
import JayImage from '../../assets/itinker web/jay.png';
import HardikImage from '../../assets/itinker web/hardik.png';
import BhawnaImage from '../../assets/itinker web/bhawna_web.png';
import SudeepImage from '../../assets/itinker web/sudeep.png';
import {useState} from "react";
import {withWidth} from "@material-ui/core";
import {SCREEN_WIDTHS} from "../../utils/common";

const MeetTheTinkerers = (props) => {
    const classes = useStyles();
    const {width} = props;
    const isMobileView = (width === SCREEN_WIDTHS.XS);
    const [hover, setHover] = useState(true);
    const handleOnMouseEnter = (index) => {
        setHover(index);
    }
    const handleOnMouseLeave = () => {
        setHover(false);
    }
    return (
        <div className={classes.meetTheTinkerers}>
            <div className={classes.Title}>
                <span className={classes.MeetThe}> Meet the </span>
                <span className={classes.Tinkers}> Tinkerers</span>
            </div>
            <div className={classes.CardStyling}>
                {
                    arrayDataOfMeetTheTinkers.map((imageData, index) =>
                        (
                            <>
                                {isMobileView ?
                                    <div onClick={() => handleOnMouseEnter(index)}>
                                        <MeetTheTinkerCard imageData={imageData} hover={hover} index={index}/>
                                    </div>
                                    :
                                    <div onMouseEnter={() => handleOnMouseEnter(index)}
                                         onMouseLeave={() => handleOnMouseLeave(index)}>
                                        <MeetTheTinkerCard imageData={imageData} hover={hover} index={index}/>
                                    </div>
                                }
                            </>
                        ))}
            </div>
        </div>
    )
}

export const arrayDataOfMeetTheTinkers = [
    {
        id: 1,
        image: AshishImage,
        bio: "Founder and the brain behind iTinker, Ashish is secretly a curious kid who is into Technologies and F1 Racing. He loves to read in his spare time.",
        name: "Ashish Jain",
        Linkedin: "in",
        link: 'https://www.linkedin.com/in/jainashish89/'
    },
    {
        id: 2,
        image: SparshImage,
        bio: " Co-founder who is an obsessive coder. Usually found behind his MacBook, coding until its Team Outing Day.",
        name: "Sparsh Jain",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/sparshjain/"
    },
    {
        id: 3,
        image: SudeepImage,
        bio: "In my current position as project manager, my team and I plan, carry out, oversee, control, and close out projects. On a daily basis, we complete projects on time, within the specified parameters, and with everyone happy.",
        name: "Sudeep Gulati",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/sudeep-gulati/"
    },
    {
        id: 4,
        image: BhawnaImage,
        bio: "I joined iTinker as a full stack developer intern and cut to two years later, I am now managing our single project tech team. I joined iTinker as a fresher and learnt from the greatest minds. From building websites to managing juniors and then to leading tech team, I have come a long way.",
        name: "Bhawna Kochar",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/bhawnakochhar/",
    },
    {
        id: 5,
        image: JahanviImage,
        bio: "I am the go-to person for designing cool stuff at iTinker! I got the opportunity to explore a wide range of responsibilities, from marketing to teaching technology to students, in our small but dynamic company.\n" +
            "Being able to work alongside such amazing minds and turn my designs into something that has a positive impact on people's lives just keeps pushing me to get better every single day.",
        name: "Jahanvi",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/jahanvi0002/"
    },
    {
        id: 6,
        image: ThomasImage,
        bio: " As a Full Stack Developer at iTinker for the last 8 months, I have come to realize that the most crucial skill I have developed is pushing myself out of my comfort zone and exploring my full potential. I enjoy being part of a team that strives to develop innovative solutions and continuously improve our products. As a developer, I am always looking for ways to expand my knowledge and contribute to iTinker's success. ",
        name: "Thomas Paul",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/thomaspaul1536/"
    },
    {
        id: 7,
        image: MansiImage,
        bio: " As a Full Stack Developer, my experience at iTinker has been nothing short of amazing. Despite only being here for a few months, I already feel like I've found a new place where I can comfortably learn, grow, and thrive every day. iTinker's supportive environment has allowed me to succeed and acquire valuable skills that enable me to end each day with a sense of accomplishment.",
        name: "Mansi Sisangiya",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/mansisisangiya/"
    },
    {
        id: 8,
        image: MahimaImage,
        bio: "I embarked on my journey with iTinker as a Full Stack Developer and have now been a part of the company for seven months. Throughout this period, I have gained significant knowledge in various technologies, making this a fantastic experience in the development of my career.",
        name: " Mahima Sharma",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/mahima-sharma-101741215/"
    },
    {
        id: 9,
        image: SanjeevImage,
        bio: " I'm currently working as a full-stack developer, and I must say, it's been quite an exciting journey. Interacting with some of the best professionals in the field has helped me learn everything I know today. I'm looking forward to continuing this journey and expand my skill set, contributing to my company’s success.",
        name: "Sanjeev Kumar",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/sanjeev-kumar-845127203/"
    },
    {
        id: 10,
        image: NitishImage,
        bio: " Hi, I'm Nitish Pratap Yadav, and I've always had a fascination for problem-solving since I was a child. This, coupled with my love for mathematics, led me to pursue a career in software engineering.\n" +
            "Currently, I work as a full stack developer at iTinker, where I get to contribute towards creating complete web solutions that aim to make the world a better place. It's a fulfilling experience to be able to use my skills to make a positive impact.",
        name: "Nitish Partap",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/nitish-pratap-yadav-166034195/"
    },
    {
        id: 11,
        image: AyushImage,
        bio: " With one year of experience and a strong foundation in both front-end and back-end development, I started my career at Itinker as a full stack developer. As a full stack developer, I keep myself up-to-date with the latest trends and technologies in the industry and enjoy working collaboratively with other team members to deliver high-quality software solutions.",
        name: "Ayush Mathur",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/ayush-mathur-658428197/"
    },
    {
        id: 12,
        image: DivyanshImage,
        bio: "I work as a full-stack developer at iTinker and have been with the company for four months now. During this time, I have been exposed to various new technologies and have been able to accomplish things that I never thought possible. Coming from a tier-3 college, I had lost hope of pursuing a career in the IT industry. However, iTinker has helped me regain my confidence and restored my faith in my abilities. I am proud of the person I have become today.",
        name: "Divyansh Verma",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/divyansh-verma-1909141b2/"
    },
    {
        id: 13,
        image: AnshuImage,
        bio: "At iTinker, I joined as a full-stack developer intern and have had the opportunity to work on live projects in just 3 months. This experience has been nothing short of fantastic, and I have learned so much during my time here. Overall, I am grateful for the chance to develop my skills and grow as a professional at iTinker.",
        name: "Anshu Jain",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/anshu-jain-42035a208/"
    },
    {
        id: 14,
        image: HardikImage,
        bio: " I’m currently a full stack developer at iTinker, and I'm thrilled to be working on an open source project. I've always been eager to learn new things and solve complex problems, so working at iTinker has been a dream come true. I'm grateful for the opportunity to work with such a supportive group of people. Moving forward, I'm committed to continuing to grow and develop my skills.",
        name: "Hardik Garg",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/garghardik/"
    },
    {
        id: 15,
        image: JayImage,
        bio: "Thanks to iTinker, I was able to kickstart my career as a software developer, and since then, I have been continuously refining my skills and broadening my knowledge. With each passing day, I feel a sense of fulfillment knowing that iTinker has helped me learn something new and contribute towards solving different problems.",
        name: "Jay Kanara",
        Linkedin: "in",
        link: "https://www.linkedin.com/in/jaykanara/"
    },
]

export const MeetTheTinkerCard = (params) => {
    const classes = useStyles();
    const {imageData, hover, index} = params;
    return (
        <div className={classes.MeetTheTinkerCard}>
            <div className={hover === index ? classes.hoveredImageOfTinkersDiv : classes.ImagesOfTinkersDiv}>
                <img src={imageData.image}
                     className={hover === index ? classes.ImagesOfTinkersDivBlurred : classes.ImagesOfTinkers}/>
                <div className={hover === index ? classes.bioDataDisplayed : classes.bioDataNotDisplayed}>
                    {imageData.bio}
                </div>
            </div>
            <div className={hover === index ? classes.NameAndLinkedinHovered : classes.NameAndLinkedin}>
                <div className={classes.TinkersName}>
                    {imageData.name}
                </div>
                <a href={imageData.link} target="_blank" style={{textDecoration: 'none'}}>
                    <div className={classes.linkedinLogo}>
                        {imageData.Linkedin}
                    </div>
                </a>
            </div>
        </div>

    )
}
export default withWidth()(MeetTheTinkerers);