import {GlimpseOfLifeAtITinker as useStyles} from './styles';
import GlimpseOfLifeAtITinkerImage1 from '../../assets/GlimpseLifeAtiTinker1.png';
import GlimpseOfLifeAtITinkerImage2 from '../../assets/GlimpseLifeAtiTinker2.png';
import GlimpseOfLifeAtITinkerImage3 from '../../assets/GlimpseLifeAtiTinker3.png';
import GlimpseOfLifeAtITinkerImage4 from '../../assets/GlimpseLifeAtiTinker4.png';
import GlimpseOfLifeAtITinkerImage5 from '../../assets/GlimpseLifeAtiTinker5.png';
import GlimpseOfLifeAtITinkerImage6 from '../../assets/GlimpseLifeAtiTinker6.png';
import GlimpseOfLifeMobileViewImage1 from '../../assets/GlimpseOfLifeMobileViewImage1.png';
import GlimpseOfLifeMobileViewImage5 from '../../assets/GlimpseOfLifeMobileViewImage6.png';
import {withWidth} from "@material-ui/core";
import {SCREEN_WIDTHS} from "../../utils/common";

function GlimpseOfLifeAtITinker(props) {
    const classes = useStyles();
    const {width} = props;
    const isMobileView = (width === SCREEN_WIDTHS.XS);
    return (
        <div className={classes.GlimpseOfLifeAtITinker}>
            <div className={classes.title}>
                Glimpses of Life at iTinker
            </div>
            {isMobileView ?
                <div className={classes.GlimpseOfLifeAtITinkerImageBlock}>
                    <div className={classes.GlimpseOfLifeAtITinkerImageBlock1}>
                        <img src={GlimpseOfLifeMobileViewImage1} className={classes.GlimpseOfLifeAtITinkerImage1}/>
                        <div className={classes.mobileViewFlexBlock}>
                            <div className={classes.GlimpseOfLifeAtITinkerImage2_3}>
                                <img src={GlimpseOfLifeAtITinkerImage2}
                                     className={classes.GlimpseOfLifeAtITinkerImage2}/>
                                <img src={GlimpseOfLifeAtITinkerImage3}
                                     className={classes.GlimpseOfLifeAtITinkerImage3}/>
                            </div>
                            <div className={classes.GlimpseOfLifeAtITinkerImageBlock2}>
                                <div className={classes.GlimpseOfLifeAtITinkerImage4_5}>
                                    <img src={GlimpseOfLifeAtITinkerImage4}
                                         className={classes.GlimpseOfLifeAtITinkerImage4}/>
                                    <img src={GlimpseOfLifeMobileViewImage5}
                                         className={classes.GlimpseOfLifeAtITinkerImage5}/>
                                </div>
                            </div>
                        </div>
                        <img src={GlimpseOfLifeAtITinkerImage6} className={classes.GlimpseOfLifeAtITinkerImage6}/>
                    </div>
                </div>

                :

                <div className={classes.GlimpseOfLifeAtITinkerImageBlock}>
                    <div className={classes.GlimpseOfLifeAtITinkerImageBlock1}>
                        <img src={GlimpseOfLifeAtITinkerImage1} className={classes.GlimpseOfLifeAtITinkerImage1}/>
                        <div className={classes.GlimpseOfLifeAtITinkerImage2_3}>
                            <img src={GlimpseOfLifeAtITinkerImage2}
                                 className={classes.GlimpseOfLifeAtITinkerImage2}/>
                            <img src={GlimpseOfLifeAtITinkerImage3}
                                 className={classes.GlimpseOfLifeAtITinkerImage3}/>
                        </div>
                    </div>
                    <div className={classes.GlimpseOfLifeAtITinkerImageBlock2}>
                        <div className={classes.GlimpseOfLifeAtITinkerImage4_5}>
                            <img src={GlimpseOfLifeAtITinkerImage4}
                                 className={classes.GlimpseOfLifeAtITinkerImage4}/>
                            <img src={GlimpseOfLifeAtITinkerImage5}
                                 className={classes.GlimpseOfLifeAtITinkerImage5}/>
                        </div>
                        <img src={GlimpseOfLifeAtITinkerImage6} className={classes.GlimpseOfLifeAtITinkerImage6}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default withWidth()(GlimpseOfLifeAtITinker);